import { Box, CardMedia, Grid, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProjectWithProducts } from "../appState/productItems";

import { BitsAppBar } from "../bitsAppBar/AppBar";
import { GridProductCards } from "../bitsCardData/GridProductCards";
import { Footer } from "../bitsFooter/Footer";

import { BitsToolbar } from "../bitsToolbars/BitsToolbar";

import {
  Checkbox,
  FormControl,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import * as React from "react";
import { useState } from "react";

import { ALL_SEARCH_TYPES } from "../appState/appData/bitsCategoryTypes/multiType";

export function SearchLayout() {
  const { searchTerm } = useParams();
  const [searchFilters, setSearchFilters] = useState([]);

  console.log("sd", searchFilters);

  const filteredProducts = useRecoilValue(getProjectWithProducts)
    .map(({ products }) => products)
    .flat()
    .filter((productInfo) => {
      const searchItem = searchTerm.toLowerCase();

      return (
        productInfo?.title?.toLowerCase().includes(searchItem) ||
        productInfo?.postedBy?.toLowerCase().includes(searchTerm)

        // productInfo?.categoryId?.includes(searchTerm)
        // .toLowerCase()
        // .includes(searchTerm.toLowerCase())
      );
    })
    .filter((searchedProduct) => {
      if (!searchFilters.length) {
        return true;
      }

      return searchFilters.some(({ type, label }) => {
        return searchedProduct[type]?.toLowerCase() === label.toLowerCase();
      });
    });

  const handleSearchChange = ({ type, label }) => {
    setSearchFilters(
      !searchFilters.map((item) => item.label).includes(label)
        ? [...searchFilters, { type, label }]
        : searchFilters.filter((item) => item.label !== label)
    );
  };

  return (
    <>
      <Box>
        <BitsAppBar />

        <Box pt={6}>
          <CardMedia
            component="img"
            image="/images/backgrounds/14.png"
            title="Welcome!"
          />
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <BitsToolbar />
          </Box>
        </Box>

        <Typography
          sx={{
            fontWeight: "bold",
            fontFamily: " 'Poppins', sans-serif",
            color: "#197982",
            textAlign: "center",
            py: 4,
          }}
        >{`Viewing results for ${searchTerm}`}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: " 'Poppins', sans-serif",

            pl: 3,
          }}
        >
          Narrow your search
        </Typography>
        <Grid container spacing={1} columns={16}>
          <Grid item xs={16} sm={5} md={4}>
            <List component="div" disablePadding>
              {/* <ListItem disablePadding> */}
              <FormControl component="fieldset" variant="standard" fullWidth>
                {ALL_SEARCH_TYPES.map(({ label, type }) => (
                  <FormGroup key={label}>
                    <ListItem disablePadding>
                      <ListItemButton
                        value={label}
                        key={label}
                        name={label}
                        onClick={() => handleSearchChange({ label, type })}
                      >
                        <ListItemIcon>
                          <Checkbox
                            name={label}
                            checked={searchFilters
                              .map((item) => item.label)
                              .includes(label)}
                          />
                        </ListItemIcon>

                        <ListItemText
                          primary={label}
                          primaryTypographyProps={{
                            color: "primary",
                            variant: "button",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </FormGroup>
                ))}
              </FormControl>
            </List>
          </Grid>
          <Grid item xs={12} sm={11} md={12}>
            <Grid container item xs={12} sm={12} md={12}>
              <GridProductCards productInfo={filteredProducts} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
