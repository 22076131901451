import {
  Checkbox,
  FormControl,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export function CheckboxGroup({
  options = [],
  selectedValues = [],
  setValues = () => {},
}) {
  const handleChange = (type) => {
    setValues(
      !selectedValues.includes(type)
        ? [...selectedValues, type]
        : selectedValues.filter((item) => item !== type)
    );
  };

  return (
    <List component="div" disablePadding>
      <FormControl component="fieldset" variant="standard" fullWidth>
        {options.map((label) => (
          <FormGroup key={label}>
            <ListItem disablePadding>
              <ListItemButton
                value={label}
                key={label}
                name={label}
                onClick={() => handleChange(label)}
              >
                <ListItemIcon>
                  <Checkbox
                    name={label}
                    onChange={() => handleChange(label)}
                    checked={selectedValues.includes(label)}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    color: "primary",
                    variant: "button",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </FormGroup>
        ))}
      </FormControl>
    </List>
  );
}
