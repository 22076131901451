import { atom, selector, selectorFamily } from "recoil";
import { bitsData } from "./appData/tastyBits/categoryList";
import {
  appProjects,
  productSkillTypesFilters,
  projectCategoryFilters,
  projectCategoryTypesFilters,
} from "./appProject";
import { localStorageEffect } from "./localStorage/localStorageEffect";
import { getUser } from "./user";

export const productItemsWithPostedByUserId = atom({
  key: "productItemsWithPostedByUserId",
  default: [],
  effects_UNSTABLE: [localStorageEffect("productItems", bitsData)],
});

export const productItems = selector({
  key: "productItems",
  get: ({ get }) =>
    get(productItemsWithPostedByUserId).map((product) => ({
      ...product,
      postedByUserId: product.postedBy,
      postedBy: get(getUser(product.postedBy))?.displayName,
    })),
});

export const getProduct = selectorFamily({
  key: "getProduct",
  get:
    (productId) =>
    ({ get }) => {
      return get(productItems).find((item) => item.productId === productId);
    },
});

export const getProductsForProject = selectorFamily({
  key: "getProductsForProject",
  get:
    (projectId) =>
    ({ get }) =>
      get(productItems).filter((item) => item.projectId === projectId),
});

export const getProjectWithProducts = selector({
  key: "getProjectWithProducts",
  get: ({ get }) =>
    get(appProjects).map((project) => ({
      ...project,
      products: get(getProductsForProject(project.projectId)),
    })),
});

export const getProductsWithTag = selectorFamily({
  key: "getProductsWithTag",
  get:
    (tag) =>
    ({ get }) =>
      get(productItems)
        .filter((item) => item.tags?.includes(tag))
        .sort(() => Math.random() - 0.5),
});

// export const getProductFavorites = atomFamily({
//   key: "getProductFavorites",
//   default: [],
//   get: (productId) => () => [],
// });

// export const getProductBookmarks = atomFamily({
//   key: "getProductBookmarks",
//   default: [],
//   get: (productId) => () => [],
// });

// export const getBookmarkedProjectts = selector({
//   key: "getBookmarkedProjectts",
//   get: ({ get }) => {
//     const allUsers = get(currentUser);

//     return get(bookmarks).map((bookmark) => {
//       return {
//         ...bookmark,
//         products: allUsers.filter((item) =>
//           item.sitesAppliedFor.includes(site.siteId)
//         ),
//       };
//     });
//   },
// });

// export const getAllProjectsWithProducts = selector({
//   key: "getAllProjectsWithProducts",
//   get: ({ get }) =>
//     get(appProjects).map((project) => ({
//       ...project,
//       products: get(getProductsForProject(project.projectId)),
//     })),
// });

// export const getProductsByPoster = selectorFamily({
//   key: "getProductsByPoster",
//   get:
//     (projectId) =>
//     ({ get }) => {
//       const projects = get(getProductsForProject(projectId));

//       const projectPosted = get(getProduct(projectId));
// if (

// )
//     }
// });

export const selectedProduct = atom({
  key: "selectedProduct",
  default: undefined,
});

export const getFilteredProductsForProject = selectorFamily({
  key: "getFilteredProductsForProject",
  get:
    (projectId) =>
    ({ get }) => {
      const categoryFilters = get(projectCategoryFilters(projectId));
      const skillsFilters = get(productSkillTypesFilters(projectId));
      const categoryTypeFilters = get(projectCategoryTypesFilters(projectId));
      const allProjectProducts = get(getProductsForProject(projectId));

      if (
        !categoryFilters.length &&
        !categoryTypeFilters.length &&
        !skillsFilters.length
      ) {
        return allProjectProducts;
      }

      return allProjectProducts.filter((item) => {
        if (
          categoryFilters.length &&
          !categoryFilters.includes(item.categoryId)
        ) {
          return false;
        }

        if (skillsFilters.length && !skillsFilters.includes(item.skillTypeId)) {
          return false;
        }

        if (
          categoryTypeFilters.length &&
          !categoryTypeFilters.some((categoryType) =>
            item.categoryTypes.includes(categoryType)
          )
        ) {
          return false;
        }

        return true;
      });
    },
});

// export const shopItem = atom({
//   key: "shopItem",
//   default: [],
//   // default: [],
//   // effects_UNSTABLE: [localStorageEffect("ratings", ratingData)],
// });

// export const getShopItemsForProduct = selectorFamily({
//   key: "getShopItemsForProduct",
//   get:
//     (productId) =>
//     ({ get }) =>
//       get(shopItem).filter((item) => item?.productId === productId),
// });
