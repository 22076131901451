import { usersData } from "./users";

export const rateOptionsData = [1, 2, 3, 4, 5];

export const ratingsData = [
  {
    // ratingId: "1",
    userId: usersData[0],
    rating: rateOptionsData[0],
    productId: "48",
  },
  {
    // ratingId: "2",
    userId: usersData[1],
    rating: rateOptionsData[1],
    productId: "48",
  },
  {
    // ratingId: "4",
    userId: usersData[1],
    rating: rateOptionsData[2],
    productId: "48",
  },
  {
    // ratingId: "3",
    userId: usersData[0],
    rating: rateOptionsData[1],
    productId: "228",
  },
  {
    // ratingId: "5",
    userId: usersData[1],
    rating: rateOptionsData[3],
    productId: "48",
  },
  {
    // ratingId: "6",
    userId: usersData[2],
    rating: rateOptionsData[4],
    productId: "228",
  },
];
