import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useState } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function ProjectCollapse({ label, children }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            color: "primary",
            variant: "button",
          }}
        />
        {open ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
}
