import { AppBar, Link, Stack, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import loveLogo from "../../lovelogo8.png";
import {
  NoShowSmall,
  ShowSmall,
  StyledAppBarColor,
  StyledCenterStack,
  StyledLogoText,
} from "../AppStyles";
import { ShittyBox } from "../search/AppBarSearch";
import { AppBarCategoryButton } from "./CategoryDrawer";
import { AppBarSearchButton } from "./SearchDrawer";

export const BitsAppBar = () => {
  return (
    <div>
      <NoShowSmall>
        <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "#f6efe9d9" }}>
          <Toolbar>
            <Box flexGrow={1}>
              <Link href={`/`} sx={{ textDecoration: "none" }}>
                <Stack direction="row" gap={1} ml={1}>
                  <img src={loveLogo} alt="logo" height={40} width={40} />
                  <StyledLogoText
                    variant="h4"
                    sx={{ minWidth: "max-content", pl: 3 }}
                  >
                    Lovebug Bits
                  </StyledLogoText>
                </Stack>
              </Link>
            </Box>

            <ShittyBox />
          </Toolbar>
        </AppBar>
      </NoShowSmall>

      <ShowSmall>
        <StyledAppBarColor position="fixed">
          <Toolbar>
            <Box flexGrow={1}>
              <Stack direction="row" gap={1}>
                <Box sx={{ alignSelf: "center" }}>
                  <img src={loveLogo} alt="logo" height={28} width={28} />
                </Box>

                <Link href={`/`} sx={{ textDecoration: "none" }}>
                  <StyledLogoText variant="h4">Lovebug Bits</StyledLogoText>
                </Link>
              </Stack>
            </Box>

            <StyledCenterStack direction="row">
              <AppBarCategoryButton />

              <AppBarSearchButton />
            </StyledCenterStack>
          </Toolbar>
        </StyledAppBarColor>
      </ShowSmall>
    </div>
  );
};
