import { Breadcrumbs, Link, Typography } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { getProject } from "../appState/appProject";

export function Breadcrumb() {
  const { projectId } = useParams();

  const project = useRecoilValue(getProject(projectId));

  return (
    <Box role="presentation" pl={4} py={1}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ChevronRightIcon color="secondary" sx={{ fontSize: "xx-large" }} />
        }
        fontSize="medium"
        variant="button"
        pl={1}
      >
        <Link href="/">Home</Link>

        <Typography
          fontSize="smaller"
          color="primary"
          sx={{ fontStyle: "italic" }}
        >
          {project.projectId}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
}
