import { atom, selector, selectorFamily } from "recoil";
import { usersData } from "./appData/users";
import { getId, updateState } from "./helpers/dataHelpers";
import { localStorageEffect } from "./localStorage/localStorageEffect";

export const users = atom({
  key: "users",
  default: [],
  effects_UNSTABLE: [localStorageEffect("users", usersData)],
});

export const currentUserIDState = atom({
  key: "CurrentUserID",
  default: 1,
});

export const currentUser = selector({
  key: "currentUser",
  get: ({ get }) => {
    return usersData[get(currentUserIDState)];
  },
});

export const getUser = selectorFamily({
  key: "getUser",
  get:
    (userId) =>
    ({ get }) => {
      return get(users).find((item) => item.userId === userId);
    },
});

export const getUserMap = selector({
  key: "getUserMap",
  get: ({ get }) => new Map(get(users).map((item) => [item.userId, item])),
});

// export const userSavedProjects = selectorFamily({
//   key: "userSavedProjects",
//   get:
//     (productId) =>
//     ({ get }) => {
//       const savedBookmarks = get(getProductBookmarks(productId));
//       const savedFavorites = get(getProductFavorites(productId));
//       const allSavedProducts = get()
// });

// export const getSitesWithApplicants = selector({
//   key: "_getSitesWithApplicants",
//   get: ({ get }) => {
//     const allApplicants = get(getApplicantsWithName);

//     return get(sites).map((site) => {
//       return {
//         ...site,
//         applicants: allApplicants.filter((item) =>
//           item.sitesAppliedFor.includes(site.siteId)
//         ),
//       };
//     });
//   },
// });



export const getUserFormData = selectorFamily({
  key: "getUserFormData",
  get:
    (userId) =>
    ({ get }) =>
      get(getUserMap).get(userId) || {
        userId: getId(),
        userName: "",
        displayName: "",
        bio: "",
        // bookmarks: [],
        // favorites: [],
        // bookmarkedProducts: [...bookmarkData.map((item) => item.value)],
        // products: [],
        // ratingsGiven: [...productRatingData.map((item) => item.value)]

        // ageGroup: [get(voterAgeGroup)[0].value],
        // location: [get(voterLocation)[0].value],
        // dateJoined: Date.now(),
      },
  set:
    () =>
    ({ get, set }, newItem) => {
      console.log("new", newItem);
      const newState = updateState(
        get(users),
        (item) => item.userId === newItem.userId,
        newItem,
        false
      );

      set(users, newState);
    },
});
// export const userBio = selector({
//   key: "userBio",
//   get: ({ get }) => {
//     return currentUser[get(currentUserIDState)];
//   },
// })
