import {
  ACCESSORIES,
  ANIMALS,
  FLOWER,
  INTERIOR,
  MANDALA,
  PETS,
  WEARABLE,
} from "./multiType";

// is stencil
export const CARDS = "Cards";
export const FURNITURE = "Furniture";
export const WALLSTENCIL = "Wall Stencil";

// is paint
export const WATERCOLOR = "Watercolor";
export const ACRYLIC = "Acrylic";
export const OIL = "Oil";
export const STILL = "Still Life";
export const ILLUSTRATE = "Illustration";
export const LANDSCAPE = "Landscape";
export const MIXMEDIA = "Mixed Media";
export const SPRAYPAINT = "spray paint";

// is yarn
// export const JEWELRY = "Jewelry"; in overlap
export const POM = "Pom";

// is wood
export const WOODWORKING = "Woodworking Projects";

// is candle
export const JARS = "Jar";

export const SOY = "Soy Wax";
export const HOLDER = "Candle Holder";

export const MELTED = "Melted";
export const GLASS = "Glass";
export const KEYS = "Keychain";
export const STRING = "String";
export const PERLER = "Perler";
export const GARLAND = "Garland";
export const INSECT = "Insect";
export const PONY = "Pony";
export const SEED = "Seed";

// is stationary
export const SCRAPBOOK = "Scrapbooking";
export const LETTER = "Calligraphy & Lettering";
export const BOOKMARK = "Bookmarks";

export const HANGING = "Hanging";
export const STAMPS = "Stamps";

//is body
export const BODY = "Bath & Body";

//Craft Values
export const STATIONARY = "Stationary";
export const STENCIL = "Stencil";
export const PAINTING = "Painting";
export const YARN = "Yarn";
export const WOOD = "Wood";
export const BEADING = "Beading";
export const CLAY = "Clay";
export const CANDLES = "Candles";
export const CRICUT = "Cricut";
export const BATH = "Bath & Body";
export const CANDLESOAP = "Candles & Soaps";
export const KITCHEN = "Kitchen";
export const GARDEN = "Garden";
export const HOME = "Home";
export const PAPER = "Paper";
export const FABRIC = "Fabric";
export const OTHER = "Other";
export const FRAGRANCE = "Fragrance";
export const KIDS = "Kids";

export const IS_STENCIL_VALUES = [FURNITURE, SPRAYPAINT, WALLSTENCIL, MANDALA];

export const IS_PAINTING_VALUES = [
  ACRYLIC,
  ANIMALS,
  LANDSCAPE,
  PETS,
  MIXMEDIA,
  OIL,
  STILL,
  WATERCOLOR,
];

export const IS_WOOD_VALUES = [
  FLOWER,
  WOODWORKING,
  INTERIOR,
  FURNITURE,
  // WALLDECOR,
];

export const IS_CLAY_VALUES = [
  INTERIOR,
  ACCESSORIES,
  // JEWELRY
];

export const IS_CANDLES_VALUES = [SOY, HOLDER, JARS];

export const IS_CRICUT_VALUES = [
  CARDS,
  STATIONARY,
  BOOKMARK,
  WEARABLE,
  ACCESSORIES,
  INTERIOR,
  HOME,
];

export const IS_BEADING_VALUES = [
  PERLER,
  STRING,
  GARLAND,
  INSECT,
  PONY,
  // BRACE,
  MELTED,
  GLASS,
  SEED,
  KEYS,
];

export const IS_STATIONARY_VALUES = [
  ANIMALS,
  FLOWER,
  ILLUSTRATE,
  LANDSCAPE,
  MIXMEDIA,
  CARDS,
  SCRAPBOOK,
  WATERCOLOR,
];

export const IS_CRAFTYARN_VALUES = [POM];

export const ALL_CRAFT_VALUES = [
  //0
PAINTING,
//1
BEADING,
//2
CRICUT,
//3
CANDLESOAP,
//4
CLAY,
//5
PAPER,
//6
WOOD,
//7
FABRIC,
//8
FLOWER,
//9
OTHER

];

export const CRAFT_CATEGORY_TYPES = [
//0
HOME,
//1
ACCESSORIES,
//2
KITCHEN,
//3
STATIONARY,
//4
FRAGRANCE,
//5
KIDS
  
];

export const ALL_CRAFT_VALUE_TYPES = [
  ...IS_STENCIL_VALUES,
  ...IS_PAINTING_VALUES,
  ...IS_CRAFTYARN_VALUES,
  ...IS_WOOD_VALUES,
  ...IS_CLAY_VALUES,
  ...IS_CANDLES_VALUES,
  ...IS_CRICUT_VALUES,
  ...IS_BEADING_VALUES,
  ...IS_STATIONARY_VALUES,
];
