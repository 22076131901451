import { atom, atomFamily, selectorFamily } from "recoil";
import { appSections } from "./appData/bitsAppSections";
import { localStorageEffect } from "./localStorage/localStorageEffect";

export const appProjects = atom({
  key: "appProjects",
  default: [],
  effects_UNSTABLE: [localStorageEffect("appProjects", appSections)],
});

export const projectCategoryFilters = atomFamily({
  key: "projectCategoryFilters",
  default: [],
  get: (projectId) => () => [],
});

export const projectCategoryTypesFilters = atomFamily({
  key: "projectCategoryTypesFilters",
  default: [],
  get: (projectId) => () => [],
});
export const productSkillTypesFilters = atomFamily({
  key: "productSkillTypesFilters",
  default: [],
  get: (projectId) => () => [],
});

export const getProject = selectorFamily({
  key: "getProject",
  get:
    (projectId) =>
    ({ get }) =>
      get(appProjects).find((item) => item.projectId === projectId),
});

