// import { productItemExamples } from "./bitsProductItems";
// import { rateOptionsData } from "./ratings";

// export const locationData = [
//   { value: "a", label: "here" },
//   { value: "b", label: "there" },
//   { value: "c", label: "everywhere" },
//   { value: "d", label: "list of states" },
// ];

export const usersData = [
  {
    userId: 0,
    userName: "Tasty",
    email: "eeemail@google.com",
    // location: locationData[0].value,
    password: "pass",
    displayName: "Tasty",
    bio: "what im sayin",
    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],
    // bookmarkedProducts: [],
    // savedProducts: [
    // ...productItemExamples.map((item) => item.productId)
    // productItemExamples[3],
    // productItemExamples[8],
    // productItemExamples[10]
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],
    // productRatings: [
    //   { productId: "58", rating: rateOptionsData[3] },
    //   { productId: "178", rating: rateOptionsData[1] },
    //   { productId: "802", rating: rateOptionsData[2] },
    // ],

    //   subscribedCategories: [
    //     categoriesData[0].categoryId,
    //     categoriesData[4].categoryId,
    //   ],

    // votesCast: [...voteCastData.map((item) => item.value)],
  },

  {
    userId: 1,
    userName: "spruce",
    email: "dalover@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "sprucecrafts",
    bio: "At The Spruce, we offer practical, real-life tips and inspiration to help you make your best home. From decorating and gardening advice, to entertaining and home repair how-tos, The Spruce can show you how.",

    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],
    // bookmarkedProducts: [],
    // savedProducts: [
    // productItemExamples[3].productId,
    // productItemExamples[8].productId,
    // productItemExamples[10].productId
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],

    // productRatings: [
    //   { productId: "178", rating: rateOptionsData[3] },
    //   { productId: "82", rating: rateOptionsData[1] },
    //   { productId: "58", rating: rateOptionsData[2] },
    // ],
  },
  // {
  //   userId: 2,
  //   userName: "da",
  //   email: "dadabest@google.com",

    // location: locationData[0].value,
    // password: "pass",
    // displayName: "leogoes",
    // bio: "what im sayin",
    // bookmarkedProducts: [],
    // savedProducts: [
    // productItemExamples[3].productId,
    // productItemExamples[8].productId,
    // productItemExamples[10].productId
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],
    // productRatings: [
    //   { productId: "82", rating: rateOptionsData[3] },
    //   { productId: "58", rating: rateOptionsData[1] },
    //   { productId: "802", rating: rateOptionsData[4] },
    // ],

    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],
  // },
  // {
  //   userId: 3,
  //   userName: "leona",
  //   email: "lll@google.com",

    // location: locationData[0].value,
    // password: "pass",
    // displayName: "leogoesarecool",
    // bio: "what im sayin",
    // bookmarkedProducts: [],
    // savedProducts: [
    // productItemExamples[3].productId,
    // productItemExamples[8].productId,
    // productItemExamples[10].productId
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],

    // productRatings: [
    //   { productId: "178", rating: rateOptionsData[4] },
    //   { productId: "48", rating: rateOptionsData[2] },
    //   { productId: "38", rating: rateOptionsData[2] },
    // ],

    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],

    //   subscribedCategories: [
    //     categoriesData[0].categoryId,
    //     categoriesData[4].categoryId,
    //   ],

    // votesCast: [...voteCastData.map((item) => item.value)],
  // },
  {
    userId: 4,
    userName: "wilton",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "wilton",
    bio: "Wilton is extremely proud of its history and expertise in baking and decorating education. For nearly a century, we’ve enjoyed teaching the joys of cake decorating and confectionary art to thousands of student bakers from around the world. We’ve also had the profound opportunity to listen and learn from our students and dedicated fans along the way.",
    // bookmarkedProducts: [],
    // savedProducts: [
    // productItemExamples[3].productId,
    // productItemExamples[8].productId,
    // productItemExamples[10].productId
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],

    // productRatings: [
    //   { productId: "178", rating: rateOptionsData[4] },
    //   { productId: "48", rating: rateOptionsData[2] },
    //   { productId: "38", rating: rateOptionsData[2] },
    // ],

    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],

    //   subscribedCategories: [
    //     categoriesData[0].categoryId,
    //     categoriesData[4].categoryId,
    //   ],

    // votesCast: [...voteCastData.map((item) => item.value)],
  },
  {
    userId: 5,
    userName: "craftsbyAmanda",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Crafts by Amanda",
    bio: "Hi! I’m Amanda and I’m the founder and head designer of Crafts by Amanda. I have spent over 25 years creating projects to share,",
    // bookmarkedProducts: [],
    // savedProducts: [
    // productItemExamples[3].productId,
    // productItemExamples[8].productId,
    // productItemExamples[10].productId
    // {productId: "58"}, {productId: "178"}, {productId: "802"}
    // ],

    // productRatings: [
    //   { productId: "178", rating: rateOptionsData[4] },
    //   { productId: "48", rating: rateOptionsData[2] },
    //   { productId: "38", rating: rateOptionsData[2] },
    // ],

    // sellerRatings: [...ratingData?.map((item) => item.value)],
    // products: [],

    //   subscribedCategories: [
    //     categoriesData[0].categoryId,
    //     categoriesData[4].categoryId,
    //   ],

    // votesCast: [...voteCastData.map((item) => item.value)],
  },
  {
    userId: 7,
    userName: "happy hour",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Happy Hour Projects",
    bio: "I'm Adrianne, an artist, author, designer, and blogger in mid Michigan.  I have a compulsion for daily creativity... sometimes it's jewelry-making, paper crafting, metal stamping, mixing and baking, or giving new life to recycled items.",
  },
  {
    userId: 8,
    userName: "janespatisserie",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "janespatisserie",
    bio: "Hiya! I'm Jane Dunn. I'm a Food Writer, Photographer and Blogger from the South of England. My goal with this blog is to give you the fun, laughter, confidence and knowledge to bake and cook whatever you fancy!",
  },

  {
    userId: 9,
    userName: "purlsoho",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Purl Soho",
    bio: "We sell only the things we love, most especially our own brand of hand-knitting yarns, available only at Purl Soho. We use these very same supplies to design the hundreds of projects we regularly share.",
  },


  {
    userId: 10,
    userName: "feelingNifty",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Feeling Nifty",
    bio: "My name is Jasmine Dhillon and I am the owner and founder of Feeling Nifty. I’ve been painting with acrylics for 15+ years and my passion is to help beginners get the hang of the basics.",
  },

  // {
  //   userId: 11,
  //   userName: "dabbab",
  //   email: "lll@google.com",

  //   // location: locationData[0].value,
  //   password: "pass",
  //   displayName: "dabblesandbabbles",
  //   bio: "Dabbles & Babbles has become a go-to place for people to learn that anyone can be a maker – kids and adults alike.",
  // },
  {
    userId: 11,
    userName: "bakingbutterlylove",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "bakingbutterlylove",
    bio: " My goal is to inspire creativity through baking. I want to help you nail those basic baking techniques so you can feel confident that your next bake will be a masterpiece.",
  },

  
  {
    userId: 12,
    userName: "craftwarehouse",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Craft Warehouse",
    bio: "Craft Warehouse team members are talented, creative people who design many of the projects highlighted and have first-hand knowledge about the products. ",
  },

  {
    userId: 14,
    userName: "tastefullyfrugal",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Tastefully Frugal",
    bio: "I’m Sydney, the creator here at Tastefully Frugal. I’m a mom to two crazy little boys and one little princess and married to the best guy around. My goal is to help you do more with the money you have. From recipes to meal plans, crafts to organization tips and more!",
  },
  {
    userId: 15,
    userName: "papernstitchblog",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "Paper & Stitch",
    bio: "Hey! I’m Brittni Mehlhoff – the founding editor / art director / stylist of Paper & Stitch. I started this blog back in 2008 to catalog my inspirations and it unexpectedly became my full-time job. ",
  },
  {
    userId: 16,
    userName: "chelsweets",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "chelsweets",
    bio: "Baking sparked this joy inside me that I couldn't get enough of. It's hard to explain, but I think anyone out there who loves baking knows what I mean",
  },

  {
    userId: 17,
    userName: "sustainmycrafthabit",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "sustainmycrafthabit",
    bio: "We're Sonja and Jane, sisters with a craft habit, here to help you find simple ways to be creative  ",
  },
  {
    userId: 18,
    userName: "lollyjane",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "lollyjane",
    bio: "We’re Kristi and Kelli, stay at home moms turned full time home decor bloggers from Phoenix, Arizona.",
  },
 
  {
    userId: 20,
    userName: "acouplecooks",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "acouplecooks",
    bio: "Sonja and Alex Overhiser: Husband and wife. Expert home cooks. Authors of recipes you'll want to make again and again.",
  },
  {
    userId: 21,
    userName: "minimalistbaker",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "minimalistbaker",
    bio: "At Minimalist Baker we share simple recipes that require 10 ingredients or less, 1 bowl, or 30 minutes or less to prepare.",
  },
  {
    userId: 22,
    userName: "realhousemoms",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "realhousemoms",
    bio: "I’m mom to two great boys and married to my best friend! I spend my time developing recipes that my family and friends will love and then I share them with you! I’m committed to getting families back to the dinner table one simple recipe at a time!",
  },
  {
    userId: 23,
    userName: "spicysouthernkitchen",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "spicysouthernkitchen",
    bio: "My name is Christin and trying new foods and experimenting in the kitchen are my greatest passions.",
  },

  {
    userId: 25,
    userName: "flamingotoes",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "flamingotoes",
    bio: "I hope you have fun making projects and maybe trying something new. Which is totally my favorite thing when it comes to crafting and creating.",
  },

  {
    userId: 24,
    userName: "cakewhiz",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "cakewhiz",
    bio: "Abeer believes that baking is not only about creating something delicious but it’s also about sharing it with others and creating memories with loved ones.",
  },
  // {
  //   userId: 26,
  //   userName: "ravelme",
  //   email: "lll@google.com",

  //   // location: locationData[0].value,
  //   password: "pass",
  //   displayName: "ravelme",
  //   bio: "what im sayin",
  // },
  {
    userId: 27,
    userName: "crochetml",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "crochet me lovely",
    bio: "My name is Taylor. I’m 34 years old from Austin, Texas. I started my blog to talk about all of my crochet patterns and projects.",
  },
  {
    userId: 28,
    userName: "mooglyblog",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "moogly",
    bio: "Moogly is world-famous for fresh. modern, and approachable crochet designs - as well as a little knitting and crafting here and there. On Moogly, you'll find several new free patterns every month, top quality video tutorials, giveaways, and more!",
  },
  // {
  //   userId: 29,
  //   userName: "allfreeknitting",
  //   email: "lll@google.com",

  //   // location: locationData[0].value,
  //   password: "pass",
  //   displayName: "allfreeknitting",
  //   bio: "AllFreeKnitting is dedicated to the best free knitting patterns, tutorials, tips and articles on knitting. From easy knitted afghan patterns to complex lace knitting patterns, we find and deliver the best free knitting patterns from all over the web",
  // },
  {
    userId: 30,
    userName: "imknitting",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "imknitting",
    bio: "Easy Knitting Patterns for Beginners. All Free Knitting and Crochet Patterns. Video Tutorials for Knitting Techniques",
  },
  {
    userId: 31,
    userName: "crochet",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "crochet",
    bio: "what im sayin",
  },
  {
    userId: 32,
    userName: "attic24",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "attic24",
    bio: " My name is Lucy and I'm a happily married Mum with three children. I have a passion for crochet and colour and love to share my creative journey.",
  },
  {
    userId: 33,
    userName: "allrecipies",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "allrecipies",
    bio: " ",
  },
  {
    userId: 34,
    userName: "lovecrafts",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "lovecrafts",
    bio: " ",
  },
  {
    userId: 35,
    userName: "hobbylobby",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "hobby lobby",
    bio: " ",
  },
  {
    userId: 36,
    userName: "thecraftedlife",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "the crafted life",
    bio: "And I'm adding color to my home and life one project at a time! Follow along for DIYS and other ways to bring joy into each and every day.",
  },
  {
    userId: 37,
    userName: "creativeculinary",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "creativeculinary",
    bio: "I love having friends over, enjoying their company and sharing food and drink together. Part of the fun is the preparation, whether it be planning and cooking the meal, decorating my home or making sure my garden is groomed during the summer and I love doing it all.",
  },
  {
    userId: 38,
    userName: "sugarhero",
    email: "lll@google.com",

    // location: locationData[0].value,
    password: "pass",
    displayName: "sugarhero",
    bio: "Hi, I'm Elizabeth--a trained pastry chef, cookbook author, video instructor, and your new Baking BFF! I'm going to teach you everything you need to know to be a sugar hero.",
  },

  
];
