import { Drawer, IconButton } from "@mui/material";
import { Box } from "@mui/system";

export function AppBarDrawers({
  selectedValue = [],
  setValue = () => {},
  icon,
  children,
}) {
  return (
    <>
      <IconButton onClick={() => setValue(true)}>{icon}</IconButton>

      <Drawer anchor="top" open={selectedValue} onClose={() => setValue(false)}>
        <Box role="presentation" 
        >
          {children}
        </Box>
      </Drawer>
    </>
  );
}
