import { Card, CardHeader, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { getProjectWithProducts } from "../../appState/productItems";
import { ShowLg, ShowOnlySmall, ShowOnlyXsMd } from "../../AppStyles";
import { StyledHomeImgBox } from "../HomeStyles";
import { TrendingImages } from "./TrendingImages";

export function TrendingGridCards() {
  const projects = useRecoilValue(getProjectWithProducts);

  return (
    <Box>
      {/* <StyledHomeCategoriesDivider variant="middle" sx={{ pb: 1, pt: 5, color:"text.secondary" }}>
        Trending
      </StyledHomeCategoriesDivider> */}

      <Grid container spacing={4} padding={3} alignItems="center" justifyContent="center">
        {projects.map(({ label, projectId, products }) => (
          <Grid item xs={11} sm={11} md={4} lg={4} xl={4} key={projectId}>
            <Card>
              <CardHeader
                title={`Trending in ${label}`}
                sx={{ alignSelf: "center" }}
              />
              <Box sx={{ height: 450, overflowY: "scroll" }} key={projectId}>
                <ShowLg>
                  <TrendingImages options={products} cols={2} />
                </ShowLg>

                <ShowOnlySmall>
                  <StyledHomeImgBox>
                    <TrendingImages options={products} cols={3} />
                  </StyledHomeImgBox>
                </ShowOnlySmall>

                <ShowOnlyXsMd>
                  <TrendingImages options={products} cols={1} />
                </ShowOnlyXsMd>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
