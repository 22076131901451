import HeadphonesIcon from '@mui/icons-material/Headphones';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import "./ResizableContainer.css";
import { Button } from "@mui/material";

const ResponsiveComponent = ({ containerWidth, containerHeight }) => {
  const renderButtons = (isSmall) => {
    if (isSmall) {
      return (
        <div>
          <Button variant="outlined">
            <HeadphonesIcon />
          </Button>

          <Button variant="contained">
            <HeadsetOffIcon />
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button variant="outlined" startIcon={<HeadphonesIcon />}>
            Listen
          </Button>
          <Button variant="contained" endIcon={<HeadsetOffIcon />}>
            Calculate
          </Button>
        </div>
      );
    }
  };

  const isSmallScreen = containerWidth <= 600;

  return (
    <div className="responsive-content">{renderButtons(isSmallScreen)}</div>
  );
};

export default ResponsiveComponent;