import { Chip, Stack, Typography } from "@mui/material";

export function ChipCategoryItem({
  options = [],
  selectedValues = [],
  setValues = () => {},
  isFilled,
}) {
  return (
    !!options.length && (
      <Stack sx={{ alignItems: "center", flexWrap: "wrap" }} direction="row">
        <Typography color="primary" pr={2}>
          {isFilled ? "Exploring:" : "Topic:"}
        </Typography>
        {options.map((name) => (
          <Chip
            label={name}
            name={name}
            variant={isFilled ? "filled" : "outlined"}
            color="primary"
            size="small"
            sx={{ m: 1 }}
            key={name}
            onDelete={() =>
              setValues(selectedValues.filter((item) => item !== name))
            }
          />
        ))}
      </Stack>
    )
  );
}
