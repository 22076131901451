import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  projectCategoryFilters,
  projectCategoryTypesFilters,
} from "../appState/appProject";
import { ChipCategoryItem } from "./ChipCategoryItem";

export function ChipCategoryFilter({ projectIdToUse }) {
  const { projectId = projectIdToUse } = useParams();
  const [categoryFilters, setCategoryFilters] = useRecoilState(
    projectCategoryFilters(projectId)
  );

  return (
    <ChipCategoryItem
      isFilled
      options={categoryFilters}
      selectedValues={categoryFilters}
      setValues={setCategoryFilters}
    />
  );
}

export function ChipCategoryTypeFilter({ projectIdToUse }) {
  const { projectId = projectIdToUse } = useParams();
  const [categoryTypesFilters, setCategoryTypesFilters] = useRecoilState(
    projectCategoryTypesFilters(projectId)
  );

  return (
    <ChipCategoryItem
      options={categoryTypesFilters}
      selectedValues={categoryTypesFilters}
      setValues={setCategoryTypesFilters}
    />
  );
}
