//export const  = {link: "", label: "", iframe: };
//crochet
export const slip = {
  link: "https://youtu.be/pxoanUr8diw",
  label: "Crochet A Slip Stitch",
  iframe: {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/pxoanUr8diw",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const double = {
  link: "https://youtu.be/5xKssKskNzo",
  label: "Double Crochet Stitch",
  iframe: {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/5xKssKskNzo",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const sc2Tog = {
  link: "https://youtu.be/5a1irlGWy04",
  label: "Single Crochet Two Together",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/5a1irlGWy04",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const treble = {
  link: "https://youtu.be/-u1EUGyD5Mg",
  label: "The Treble Stitch",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/-u1EUGyD5Mg",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const round = {
  link: "https://youtu.be/0R-ogDIkTv4",
  label: "crochet in the round",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/0R-ogDIkTv4",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const scarf = {
  link: "https://youtu.be/cDFEL7BTWn4",
  label: "Everlasting Frost Cowl",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/cDFEL7BTWn4",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};


export const beads = {
  link: "https://youtu.be/vE3RdFCW_b8",
  label: "crochet with beads",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/vE3RdFCW_b8",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

//embroidery
export const eightStitchEmbroidery = {
  link: "https://youtu.be/3YU2c8L_DwY",
  label: "Embroidery Basics | Eight Essential Stitches ",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/3YU2c8L_DwY",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

//sewing

export const sewingBasics = {
  link: "https://youtu.be/Vi5qIE6Ii2g",
  label: "Sewing Basics",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/Vi5qIE6Ii2g",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const claspPurse = {
  link: "https://youtu.be/Bl9fxAeKjXc",
  label: "Clasp Purse",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/Bl9fxAeKjXc",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};



//crafts
export const bathBomb = {
  link: "https://youtu.be/wieE0wSVXOQ",
  label: "Make a bath bomb",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/wieE0wSVXOQ",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const paintRock = {
  link: "https://youtu.be/4KkHmdHCsNw",
  label: "Painting Rocks",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/4KkHmdHCsNw",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const paintRockIdeas = {
  link: "https://youtu.be/aG4ZxPcFxso",
  label: "Painting Rock Ideas",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/aG4ZxPcFxso",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const lavaBracelet = {
  link: "https://youtu.be/q9-_13tuSzA",
  label: "Lava Stone Bracelet",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/q9-_13tuSzA",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const woodSign = {
  link: "https://youtu.be/r-Pq8QpD4aw",
  label: "Welcome Sign",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/r-Pq8QpD4aw",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const moldSoap = {
  link: "https://youtu.be/sv0ciYs69bc",
  label: "Making Soap",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/sv0ciYs69bc",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const tieDyeShirt = {
  link: "https://youtu.be/dS3QsQWWFy8",
  label: "Tie Dye Shirts",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/dS3QsQWWFy8",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const paintGlass = {
  link: "https://youtu.be/5blQJONTJY0",
  label: "Painting Glass",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/5blQJONTJY0",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const topsyCake = {
  link: "https://youtu.be/zDlxCFUNFnA",
  label: "Topsy Turvy Cake",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/zDlxCFUNFnA",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

//knit
export const garterKnit = {
  link: "https://youtu.be/VeG4_nTyXGw",
  label: "Garter Stitch",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/VeG4_nTyXGw",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const purlKnit = {
  link: "https://youtu.be/tc9KxWjtu94",
  label: "Purl Stitch",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/tc9KxWjtu94",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

  export const paintLavFlower = {
    link: "https://youtu.be/cg2vYPvMAC0",
    label: "Paint Lavander flower",
    iframe: {
      width: "300",
      height: "215",
      src: "https://www.youtube.com/embed/cg2vYPvMAC0",
      title: "YouTube video player",
      frameborder: "0",
      allow:
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: true,
    },
  };
    export const paintGlassFrame = {
      link: "https://youtu.be/dzyWnUygIPo",
      label: "Painting on Glass",
      iframe: {
        width: "300",
        height: "215",
        src: "https://www.youtube.com/embed/dzyWnUygIPo",
        title: "YouTube video player",
        frameborder: "0",
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: true,
      },
    };

//baking
export const fondant = {
  link: "https://youtu.be/Up6_zovReb4",
  label: "How to Cover a Round Cake with Fondant",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/Up6_zovReb4",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const fondantCupcake = {
  link: "https://youtu.be/_JD4isjw39Y",
  label: "Easy Fondant Flower Cupcakes",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/_JD4isjw39Y",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};
export const buttercreamFilling = {
  link: "https://youtu.be/Cf9gfZDlz6I",
  label: "Making buttercream",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/Cf9gfZDlz6I",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const pipeBlossom = {
  link: "https://youtu.be/z2x-lA9mq2s",
  label: "Pipe Buttercream Cherry Blossoms",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/z2x-lA9mq2s",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

export const russianTip = {
  link: "https://youtu.be/EyUK0ft9gA8",
  label: "Russian Tips for Cake Decorating",
  iframe: {
    width: "300",
    height: "215",
    src: "https://www.youtube.com/embed/EyUK0ft9gA8",
    title: "YouTube video player",
    frameborder: "0",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: true,
  },
};

