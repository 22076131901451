import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Divider, Grid, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledHomeCategoriesDivider = styled(Divider)({
  fontWeight: "bold",
  fontSize: "x-large",
  fontFamily: "Poppins, san-serif",
  paddingBottom: 7,
  paddingTop: 7,
  textAlign: "center",
});

export const StyledCategoriesBox = styled(Box)({
  border: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  // margin: 2,
  // padding: 3,
  alignItems: "center",
});

export const StyledHomeImgGridItem = styled(Grid)({
  alignItems: "center",
  justifyContent: "center",
});

export const StyledCategoryGridItem = styled(Grid)({
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
});

export const StyledHomeColoredPaper = styled(Paper)({
  display: "flex",
  backgroundColor: "#f2e8e0b5",
  // backgroundColor: "#f2e8e0",

  alignItems: "center",
  flexDirection: "row",
});

export const StyledHomeImgBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
});

//components
export const CardButton = ({ projectId, label, title, variant }) => {
  return (
    // <Box display="flex" justifyContent="center">
    <Button
      fullWidth
      variant={variant}
      sx={{ fontSize: "large", my: 3 }}
      href={`/${projectId}`}
    >
      {`${title} ${label}`}
    </Button>
    // </Box>
  );
};

export const GoRightIconButton = ({ onClick }) => {
  return (
    <Box alignSelf="center" justifyContent="flex-end" display="flex">
      <IconButton onClick={onClick}>
        <ArrowBackIosIcon />
      </IconButton>
    </Box>
  );
};

export const GoLeftIconButton = ({ onClick }) => {
  return (
    <Box alignSelf="center">
      <IconButton onClick={onClick}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export const SmallScrollIconButtons = ({ onRightClick, onLeftClick }) => {
  return (
    <Box display="flex" justifyContent="center">
      <IconButton onClick={onRightClick}>
        <ArrowBackIosIcon />
      </IconButton>

      <IconButton onClick={onLeftClick}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};
