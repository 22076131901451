import { appSections } from "../bitsAppSections";
import {
  ALL_CRAFT_VALUES,
  CRAFT_CATEGORY_TYPES,
} from "../bitsCategoryTypes/craft";
import { ALL_SKILL_TYPES } from "../bitsCategoryTypes/multiType";

import { rateOptionsData } from "../ratings";
import {
  bathBomb,
  lavaBracelet,
  moldSoap,
  paintGlass,
  paintGlassFrame,
  paintLavFlower,
  paintRock,
  paintRockIdeas,
  tieDyeShirt,
  woodSign,
} from "../tutorials";

export const bitsCrafts = [
  
  
  // {
  //   projectId: appSections[0].projectId,

  //   categoryId: ALL_CRAFT_VALUES[1],
  //   categoryTypes: [CRAFT_CATEGORY_TYPES[4]],
  //   skillTypeId: ALL_SKILL_TYPES[2],
  //   productId: "11",
  //   link: "https://sustainmycrafthabit.com/making-a-card-with-cricut-joy/",
  //   img: "https://sustainmycrafthabit.com/wp-content/uploads/2020/06/Cricut-Joy-Thanks-a-Latte-Card-0079.jpg.webp",
  //   title: "Thanks a Latte Cards",
  //   postedBy: 17,
  //   description: "if it has desc goes here",

  //   imgSrc: [
  //     {
  //       original:
  //         "https://sustainmycrafthabit.com/wp-content/uploads/2020/06/Cricut-Joy-Thanks-a-Latte-Card-0079.jpg.webp",
  //       thumbnail:
  //         "https://sustainmycrafthabit.com/wp-content/uploads/2020/06/Cricut-Joy-Thanks-a-Latte-Card-0079.jpg.webp",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://sustainmycrafthabit.com/wp-content/uploads/2020/06/Cricut-Joy-Thanks-a-Latte-Card-2.jpg.webp",
  //       thumbnail:
  //         "https://sustainmycrafthabit.com/wp-content/uploads/2020/06/Cricut-Joy-Thanks-a-Latte-Card-2.jpg.webp",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original: "",
  //       thumbnail: "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   materials: [
  //     {
  //       section: "",
  //       materialInfo: [
  //         "Cricut Design Space Card Image #M1504A408",
  //         "Cricut Joy  machine",
  //         "Cricut Joy Card Mat",
  //         "Cricut Joy Insert Cards",
  //       ],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [""],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  //   tags: ["explore"],
  // },
//  {
//     projectId: appSections[0].projectId,

//     categoryId: ALL_CRAFT_VALUES[2],
//     categoryTypes: CRAFT_CATEGORY_TYPES[2],
//     productId: "16",
//     link: "https://craftsbyamanda.com/photo-holder-for-mom/",
//     img: "https://craftsbyamanda.com/wp-content/uploads/2021/03/Photo-Holder-for-Mom-Sq-400x400.jpg",
//     imgSrc: [
//       {
//         original:
//           "https://craftsbyamanda.com/wp-content/uploads/2021/03/Photo-Holder-for-Mom-Sq-400x400.jpg",
//         originalHeight: "320px",
//       },
//     ],
//     title: "Photo Holder for Mom",
//     postedBy: 5,
//     description: "if it has desc goes here",
//     materials: [
//       {
//         section: "",
//         materialInfo: [
//           "Pine rectangle plaque 14” wide x 11” high",
//           "“MOM” letters 4.75” high",
//           "Set of 3 metal binder photo clips",
//           "1” flat paintbrush",
//           "Fine tip paint brushes in assorted sizes and shapes",
//           "Wood glue",
//           "Picture hanging hardware",
//           "Acrylic paints",
//           "Sheen paint",
//         ],
//       },
//     ],
//     servings: "",
//     estTimeComplete: "",
//     directions: [],
//     tutorials: [],

//     rating: { rateOptions: rateOptionsData },
//     tags: ["featured", "latest"],
//   },
  // {
  //   projectId: appSections[0].projectId,

  //   categoryId: ALL_CRAFT_VALUES[2],
  //   categoryTypes: [CRAFT_CATEGORY_TYPES[3]],
  //   productId: "17",
  //   link: "https://craftsbyamanda.com/salt-dough-haunted-houses-2/",
  //   img: "https://craftsbyamanda.com/wp-content/uploads/2021/10/Salt-Dough-Haunted-Houses-RC-SQ-400x400.jpg",
  //   imgSrc: [
  //     {
  //       original:
  //         " https://craftsbyamanda.com/wp-content/uploads/2021/10/Salt-Dough-Haunted-Houses-RC-SQ-400x400.jpg",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "Salt Dough Haunted Houses",
  //   postedBy: 5,
  //   description: "if it has desc goes here",
  //   materials: [
  //     {
  //       section: "",
  //       materialInfo: [],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  //   tags: ["trending"],
  // },
//  {
//     projectId: appSections[0].projectId,

//     categoryId: ALL_CRAFT_VALUES[2],
//     categoryTypes: CRAFT_CATEGORY_TYPES[0],
//     productId: "21",
//     link: "https://craftsbyamanda.com/easter-egg-wreath/",
//     img: "https://craftsbyamanda.com/wp-content/uploads/2020/03/Easter-Egg-Wreath-7480-450x450.jpg",
//     imgSrc: [
//       {
//         original:
//           " https://craftsbyamanda.com/wp-content/uploads/2020/03/Easter-Egg-Wreath-7480-450x450.jpg ",
//         originalHeight: "320px",
//       },
//     ],
//     title: "DIY Easter Egg Wreath",
//     postedBy: 5,
//     description: "if it has desc goes here",
//     materials: [
//       {
//         section: "You will need",
//         materialInfo: [
//           "19 ” grapevine wreath",
//           "16-18 plastic eggs in assorted sizes",
//           "Chalky paints",
//           "Paintbrushes",
//           "Gold glitter decorative garland",
//           "Floral wire",
//           "Wire snippers",
//           "Hot glue gun with clear craft glue sticks",
//           "Decorative Bird optional",
//         ],
//       },
//     ],
//     servings: "",
//     estTimeComplete: "",
//     directions: [],
//     tutorials: [],

//     rating: { rateOptions: rateOptionsData },
//   },
// {
//     projectId: appSections[0].projectId,

//     categoryId: ALL_CRAFT_VALUES[7],
//     categoryTypes: CRAFT_CATEGORY_TYPES[1],
//     productId: "23",
//     link: "https://craftsbyamanda.com/fingerprint-flower-pot/",
//     img: "https://craftsbyamanda.com/wp-content/uploads/2020/04/fingerprint-flower-pot-SQ-400x400.jpg",
//     imgSrc: [
//       {
//         original:
//           "https://craftsbyamanda.com/wp-content/uploads/2020/04/fingerprint-flower-pot-SQ-400x400.jpg",
//         originalHeight: "320px",
//       },
//     ],
//     title: "Fingerprint Flower Pot",
//     postedBy: 5,
//     description: "if it has desc goes here",
//     materials: [
//       {
//         section: "Supplies",
//         materialInfo: [
//           "Assorted color acrylic paints (flowers)",
//           "White and yellow acrylic paint (pot)",
//           "Terra cotta pot with base dish",
//           "Green permanent marker or paint marker",
//         ],
//       },
//       {
//         section: "Tools",
//         materialInfo: [
//           " Fine grit sandpaper (if using plastic flower pot)",
//           "A paint palette",
//           "Paintbrushes",
//           "Newspaper or tablecloth to cover work surface",
//         ],
//       },
//       {
//         section: "Alternatives",
//         materialInfo: [
//           "If you have old plastic pots, use a fine grit sandpaper and gently scuff up the outside so the paint sticks to the pot. You can see our complete tips for painting on plastic here.",
//           "Paper cups work great too",
//         ],
//       },
//     ],
//     servings: "",
//     estTimeComplete: "45 mins",
//     directions: [],
//     tutorials: [],

//     rating: { rateOptions: rateOptionsData },
//     tags: ["explore"],
//   },
  // {
  //   projectId: appSections[0].projectId,

  //   categoryId: ALL_CRAFT_VALUES[2],
  //   categoryTypes: [CRAFT_CATEGORY_TYPES[0], CRAFT_CATEGORY_TYPES[1]],
  //   skillTypeId: ALL_SKILL_TYPES[0],
  //   productId: "25",
  //   link: "",
  //   img: "",

  //   imgSrc: [
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "",
  //   postedBy: 12,
  //   description: "",

  //   materials: [
  //     {
  //       section: "Supplies",
  //       materialInfo: [
  //         "",

  //       ],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [
  //     "",
  //   ],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  // },
  // {
  //   projectId: appSections[0].projectId,

  //   categoryId: ALL_CRAFT_VALUES[2],
  //   categoryTypes: [CRAFT_CATEGORY_TYPES[0], CRAFT_CATEGORY_TYPES[1]],
  //   skillTypeId: ALL_SKILL_TYPES[0],
  //   productId: "25",
  //   link: "",
  //   img: "",

  //   imgSrc: [
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "",
  //   postedBy: 12,
  //   description: "",

  //   materials: [
  //     {
  //       section: "Supplies",
  //       materialInfo: [
  //         "",

  //       ],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [
  //     "",
  //   ],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  // },
  // {
  //   projectId: appSections[0].projectId,

  //   categoryId: ALL_CRAFT_VALUES[2],
  //   categoryTypes: [CRAFT_CATEGORY_TYPES[0], CRAFT_CATEGORY_TYPES[1]],
  //   skillTypeId: ALL_SKILL_TYPES[0],
  //   productId: "25",
  //   link: "",
  //   img: "",

  //   imgSrc: [
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "",
  //       thumbnail:
  //         "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "",
  //   postedBy: 12,
  //   description: "",

  //   materials: [
  //     {
  //       section: "Supplies",
  //       materialInfo: [
  //         "",

  //       ],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [
  //     "",
  //   ],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  // },

{
  projectId:appSections[0].projectId,
  categoryId: ALL_CRAFT_VALUES[1],
  categoryTypes: [CRAFT_CATEGORY_TYPES[1],CRAFT_CATEGORY_TYPES[4]],
  productId:"03",
  skillTypeId:ALL_SKILL_TYPES[0],
  postedBy:7,
  title:"Lava Essential Oils Diffuser Bracelet",
  description:   "I LOVE natural stones and beads in my DIY jewelry.  Recently, I learned that the porous qualities of lava beads allow them to absorb essential oils and act as a natural diffuser – and I had to try it for myself!",
      link: "https://happyhourprojects.com/diy-lava-essential-oils-diffuser-bracelet/",
      img: "https://happyhourprojects.com/wp-content/uploads/2017/10/Oil-Diffuser-Lava-Bracelet-7.jpg ",
    imgSrc: [
        {
          original:
            "https://happyhourprojects.com/wp-content/uploads/2017/10/Oil-Diffuser-Lava-Bracelet-7.jpg",
          originalHeight: "320px",
        },
        {
          original:
            "https://happyhourprojects.com/wp-content/uploads/2017/10/Oil-Diffuser-Lava-Bracelet-1.jpg",
          originalHeight: "320px",
        },
      ],
      materials: [
        {
          section: "Things you will need",
          materialInfo: [
            "Lava beads",
            "Coordinating gemstone beads in the same size",
            "2 – 5mm spacer beads",
            "Stretch cord .8mm",
            "Super Glue (optional)",
            "Scissors",
            "Your favorite essential oil",
          ],
        },
      ],
     servings: "",
      estTimeComplete: "",
      directions: [],
      tutorials: [lavaBracelet],
   rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  
  },
  {
  
  projectId:appSections[0].projectId,
  categoryId:ALL_CRAFT_VALUES[3],
  categoryTypes:[CRAFT_CATEGORY_TYPES[0],CRAFT_CATEGORY_TYPES[4]],
  productId:"01",
  skillTypeId: ALL_SKILL_TYPES[0],
  postedBy:1,
  title:"Homemade Candles",
  description:"Making homemade candles",
  link:"https://www.thesprucecrafts.com/basic-candle-making-instructions-516753",
  img:"https://www.thesprucecrafts.com/thmb/393_oTciZOM46N3s6XUmqITtMEc=/941x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/candlesfinal-5bbe5f3946e0fb0026fec582.jpg",
    imgSrc: [
        {
   original: "https://www.thesprucecrafts.com/thmb/393_oTciZOM46N3s6XUmqITtMEc=/941x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/candlesfinal-5bbe5f3946e0fb0026fec582.jpg",
  originalHeight: "320px",
  },
  ],
      materials: [
        {
          section: "Equipment",
          materialInfo: ["Pot", "Hot plate or electric stove"],
        },
        {
          section: "Materials",
          materialInfo: [
            "Wax",
            "Wick",
            "Container",
            "Fragrance (optional)",
            "Color (optional)",
            "Skewers or Popsicle sticks",
          ],
        },
      ],
     servings: "",
      estTimeComplete: "",
      directions: [],
      tutorials: [],
   rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
  projectId:appSections[0].projectId,
  categoryId:ALL_CRAFT_VALUES[9],
  categoryTypes:[CRAFT_CATEGORY_TYPES[0],CRAFT_CATEGORY_TYPES[5]],
  productId:"02",
  skillTypeId:ALL_SKILL_TYPES[0],
  postedBy:5,
  title:"Bird Seed Ornaments",
  description:  "Bird seed ornaments are a fun and simple kid-friendly craft. You can make your ornament any shape you like, just be sure to cut a circle in the center so the birds can perch while they dine on bird seed.",
  link:"https://craftsbyamanda.com/birdseed-ornaments/",
  img:"https://craftsbyamanda.com/wp-content/uploads/2017/04/birdseed-ornament-photo-680680-400x400.jpg",
    imgSrc: [
        {
   original:"https://craftsbyamanda.com/wp-content/uploads/2017/04/birdseed-ornament-photo-680680-400x400.jpg",
  originalHeight: "320px",
        }
      ],
      materials: [
        {
          section: "",
          materialInfo: [
            "Thin cardboard (such as a cereal box)",
            "Template or large cookie cutters",
            "Scissors",
            "Peanut butter",
            "birdseed",
            "Small nail or tapestry needle",
            "Floral wire",
          ],
        },
      ],
     servings: "",
      estTimeComplete: "",
      directions: [],
      tutorials: [],
   rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
  projectId:appSections[0].projectId,
  categoryId:ALL_CRAFT_VALUES[0],
  categoryTypes:[CRAFT_CATEGORY_TYPES[0],CRAFT_CATEGORY_TYPES[5]],
  productId:"04",
  skillTypeId:ALL_SKILL_TYPES[0],
  postedBy:10,
  title:"How to Paint Lavender Flowers in Acrylics",
  description:"Paint lavender flowers with Q-tips",
    link: "https://feelingnifty.com/how-to-paint-lavender-acrylics/",
      img: "https://feelingnifty.com/wp-content/uploads/2019/06/easy-lavender-painting-with-cotton-swabs-acrylic-1-768x1152.jpg",
      imgSrc: [
        {
          original:
            "https://feelingnifty.com/wp-content/uploads/2019/06/how-to-paint-lavender-in-acrylics-easy-1024x683.jpg",
          originalHeight: "320px",
        },
        {
          original:
            "https://feelingnifty.com/wp-content/uploads/2019/06/How-to-Paint-Lavender-flower-in-Acrylics-1024x683.jpg",
          originalHeight: "320px",
        },
      ],
      materials: [
        {
          section: "Things you will need",
          materialInfo: [
            "DecoArt Acrylic Paints in African Violet, Lavender, Leaf Green, White, and Black",
  
            "Paint Brushes",
  
            "Square Canvas",
  
            "Wooden Skewer",
  
            "Cup of Water",
  
            "Paper towel and salt (for texture)",
  
            "Qtips (cotton swabs)",
  
            "Paper Plate or pallet to mix paint",
  
            "Spray bottle filled with water",
          ],
        },
      ],
     servings: "",
      estTimeComplete: "",
      directions: [],
      tutorials: [],
   rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },
  {
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:CRAFT_CATEGORY_TYPES[2],
productId:"05",
skillTypeId:ALL_SKILL_TYPES[1],
postedBy:5,
title:"Painting Wine Glasses",
description:"This how to paint wine glasses project makes a great Valentine's day gift packaged with a bottle of wine to keep, fill, and share with your own Valentine!",
link:"https://craftsbyamanda.com/how-to-paint-wine-glasses/",
 img: "https://craftsbyamanda.com/wp-content/uploads/2018/01/How-to-Paint-Wine-Glasses-Valentines-Day-v2.jpg ",

    imgSrc: [
      {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2018/01/How-to-Paint-Wine-Glasses-Valentines-Day-v2.jpg ",
        originalHeight: "320px",
      },
    ],
   materials: [
      {
        section: "Supplies",
        materialInfo: [
          "wine glasses",
          "rubbing alcohol or white vinegar",
          "wax paper",
          "blue painters tape",
          "heart cookie cutter about 2-inches",
          "marker",
          "acrylic enamel paints",
        ],
      },
      {
        section: "Tools",
        materialInfo: ["Scissors", "paintbrushes", "pencils with erasers"],
      },
    ],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials:  [paintGlass],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:CRAFT_CATEGORY_TYPES[0],
productId:"06",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:12,
title:"Paint a Glass Frame",
description:"Unique DIY Wall Art",
link:"https://craftwarehouse.com/this-unique-diy-wall-art-is-so-easy-and-looks-fantastic-see-4-ways-to-do-it/",
 img: "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/paint-on-glass-display.jpg",
    imgSrc: [
      {
        original:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/paint-on-glass-display.jpg",
        thumbnail:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/paint-on-glass-display.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/bees-on-glass-800x533.jpg",
        thumbnail:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/bees-on-glass-800x533.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/painted-flowers-tile-800x533.jpg",
        thumbnail:
          "https://f4n6j2s7.rocketcdn.me/wp-content/uploads/painted-flowers-tile-800x533.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
   materials: [
      {
        section: "Supplies",
        materialInfo: [
          "A Float Frame or an old frame that can be used as one (you could also try a regular frame and add a backing paper)",
          "Paint Pens",
          "A line drawing to copy – this could be a print-out or a coloring page book.",
        ],
      },
    ],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [paintGlassFrame],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:[CRAFT_CATEGORY_TYPES[0],CRAFT_CATEGORY_TYPES[5]],
productId:"07",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:7,
title:"Painted Rocks TIC TAC TOE",
description:"I want to share a fun painted rocks project with you today that’s a great little boredom-buster, and fun to make on the snowy days ahead.",
link:"https://happyhourprojects.com/painted-rocks-tic-tac-toe/",
img:"https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-1.1.jpg",
   imgSrc: [
      {
        original:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-1.1.jpg",
        thumbnail:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-1.1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-7.jpg",
        thumbnail:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-7.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-6.jpg",
        thumbnail:
          "https://happyhourprojects.com/wp-content/uploads/2019/11/Tic-Tac-Toe-6.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Supplies",
        materialInfo: [
          "10 Smooth Stones, approximately the same size",
          "Acrylic Paint",
          "Brush-on varnish (you can also use varnish spray)",
          "Size 10 Flat Paintbrush",
          "Size 0 Liner Paintbrush",
          "Stylus",
          "6×8 Burlap Drawstring Bag",
          "Permanent Marker",
        ],
      },
    ],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:CRAFT_CATEGORY_TYPES[0],
productId:"08",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:12,
title:"As Good as Gold Canvas Art",
description:"It’s such a simple project and yet it looks so sophisticated and dramatic.",
 link: "https://craftwarehouse.com/as-good-as-gold-canvas-art/",
    img: "https://craftwarehouse.com/wp-content/uploads/gold-canvas-1.jpg",
   imgSrc: [
      {
        original:
          "https://craftwarehouse.com/wp-content/uploads/gold-canvas-1.jpg",
        originalHeight: "320px",
      },
      {
        original: "",
        originalHeight: "320px",
      },
    ],
   materials: [
      {
        section: "Things you will need",
        materialInfo: [
          "2 White 12” x 16” Canvas",
          "DecoArt “Spun Gold” acrylic paint",
          "Acrylic paint colors of your choice",
          "Foam Brush",
        ],
      },
    ],
   servings: "",
    estTimeComplete: "",
    directions:  [
      "1. Paint your canvas with 2-3 layers of the Spun Gold acrylic paint over the whole canvas. Let dry between coats.  (Tip: You could use other metallic paints, but this particular one is less translucent, so it took less coasts to cover the canvas well).",
      "2. Brush on your colored paint, giving each canvas uneven strokes with each color.",
      "3. Allow to dry then give it more coats if needed."
    ],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
 tags: ["trending"],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[2],
categoryTypes:CRAFT_CATEGORY_TYPES[2],
productId:"09",
skillTypeId:ALL_SKILL_TYPES[1],
postedBy:14,
title:"Cricut Infusible Ink Pens on Mugs",
description: "Learn how to use Cricut Infusible Ink Pens and Markers on Mugs to create one of a kind designs! These mugs make perfect gifts and keepsakes.",
  link: "https://tastefullyfrugal.org/cricut-infusible-ink-pens-on-mugs/",
    img: "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2021/06/Cricut-Mug-Press-Mugs-14-scaled.jpg",
  imgSrc: [
      {
        original:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2021/06/Cricut-Mug-Press-Mugs-14-scaled.jpg",
        thumbnail:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2021/06/Cricut-Mug-Press-Mugs-14-scaled.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i2.wp.com/tastefullyfrugal.org/wp-content/uploads/2021/03/Cricut-Mug-Press-12-2048x1365.jpg",
        thumbnail:
          "https://i2.wp.com/tastefullyfrugal.org/wp-content/uploads/2021/03/Cricut-Mug-Press-12-2048x1365.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
],
   materials: [
      {
        section: "Supplies",
        materialInfo: [
          "Infusible Ink Pens and Markers",
          "Mug",
          "butcher paper",
          "Heat Resistant Tape",
        ],
      },
    ],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[5],
categoryTypes:CRAFT_CATEGORY_TYPES[3],
productId:"10",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:15,
title:"Dip Dye Watercolor Stationary",
description:"Make beautiful cards.",
 link: "https://www.papernstitchblog.com/dip-dyed-diy-watercolor-stationery-with-hand-lettered-greetings/",
    img: "https://s3.amazonaws.com/assets.papernstitchblog.com/wp-content/uploads/2015/01/dip-dyed-diy-blue-green-stationery-set-10.jpg",
    imgSrc: [
      {
        original:
          "https://s3.amazonaws.com/assets.papernstitchblog.com/wp-content/uploads/2015/01/cursive-writing-notecards-dip-dyed-with-blue.jpg",
        thumbnail:
          "https://s3.amazonaws.com/assets.papernstitchblog.com/wp-content/uploads/2015/01/cursive-writing-notecards-dip-dyed-with-blue.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://s3.amazonaws.com/assets.papernstitchblog.com/wp-content/uploads/2015/01/dip-dyed-diy-blue-green-stationery-set-10.jpg",
        thumbnail:
          "https://s3.amazonaws.com/assets.papernstitchblog.com/wp-content/uploads/2015/01/dip-dyed-diy-blue-green-stationery-set-10.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "You will need",
        materialInfo: [
          "Blank Notecards",
          "Watercolors",
          "Color Pen",
          "Water",
          "Bowl",
          "Paintbrush",
          "Paper Towels or a Rag",
          " Heavy Books (for stacking)",
        ],
      },
    ],
   servings: "",
    estTimeComplete: "",
     directions: [
      "1. Embrace your style. Love the way your hand writes rather than always trying for it to look like someone else’s.",

      "2. Be mindful. Being present while writing allows you to appreciate the subtleties of how each letter connects to the next, the spacing, how the scale of each letter compares to it’s neighbors. And as handwriting becomes a bit of a lost art, enjoying it is pretty special.",

      "3. Each pen, pencil, or nib allows your hand to move a little bit differently. Find the right tools for you.",
    ],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[7],
categoryTypes:CRAFT_CATEGORY_TYPES[5],
productId:"11",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:5,
title:"Rainbow Tie Dye Shirt",
description:"jjj",
link:"https://craftsbyamanda.com/rainbow-tie-dye-shirts/",
img:"https://craftsbyamanda.com/wp-content/uploads/2021/06/rainbow-tie-dye-shirts-SQ-RC-400x400.jpg",
  imgSrc: [
       {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2021/06/rainbow-tie-dye-shirts-SQ-RC-400x400.jpg",
        originalHeight: "320px",
      },
],
 materials: [
      {
section: "",
        materialInfo: [
   ],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [tieDyeShirt],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:CRAFT_CATEGORY_TYPES[3],
productId:"12",
skillTypeId:ALL_SKILL_TYPES[1],
postedBy:10,
title:"Hydro Flask Painting",
description:"Easy DIY Hydro Flask painting ideas and painting /sealing tips and tricks",
link:"https://feelingnifty.com/hydro-flask-painting-diy-tutorial/",
img: "https://feelingnifty.com/wp-content/uploads/2020/01/Painted-Hydroflask-Flowers-1024x683.jpg",
  imgSrc: [
        {
        original:
          "https://feelingnifty.com/wp-content/uploads/2020/01/Painted-Hydroflask-Flowers-1024x683.jpg",
        originalHeight: "320px",
      },
      {
        original:
          "https://feelingnifty.com/wp-content/uploads/2020/01/Hydro-Flask-Black-Painting-Flowers-768x1152.jpg",
        originalHeight: "320px",
      },
      {
        original:
          "https://feelingnifty.com/wp-content/uploads/2020/01/Hydroflask-painted-flowers-process-step-3.jpg.webp",
        originalHeight: "320px",
      },
],
 materials: [
     {
        section: "Things you will need",
        materialInfo: [
          " HydroFlask water bottle",
          " Liquitex acrylic paints in a variety of colors (or any acrylic paints)",
          "Paintbrushes (small and medium sizes)",
          " Pencil",
          "Dishwasher-safe Mod Podge",
        ],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials:  [paintLavFlower],
 rating: { rateOptions: rateOptionsData },
  tags: ["trending"],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:CRAFT_CATEGORY_TYPES[0],
productId:"13",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:18,
title:"Abstract Art Canvas",
description:"if it has desc goes here",
link:"https://lollyjane.com/diy-abstract-art-golden-touch/",
img:"https://lollyjane.com/wp-content/uploads/2015/03/30-14908-post/abstract-art-diy.jpg",
  imgSrc: [
     {
        original:
          "https://farm8.staticflickr.com/7287/16337474853_1a311b6bd9_z.jpg",
        originalHeight: "320px",
      },
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2015/03/30-14908-post/abstract-art-diy.jpg",
        originalHeight: "320px",
      },
],
 materials: [
      {
        section: "Things you will need",
        materialInfo: [
          "Butcher (or wrapping paper)",
          "Canvas (16×20 size)",
          "Acrylic paints",
          "large painters brush",
          "pieces of chipboard or cardboard for applying paint",
          "Gold Foil Flakes",
          "Elmer’s glue or metal leaf adhesive & sealer",
        ],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
  tags: [],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[6],
categoryTypes:CRAFT_CATEGORY_TYPES[0],
productId:"14",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:5,
title: "Sunflower Welcome Sign",
description:"if it has desc goes here",
link:"https://craftsbyamanda.com/sunflower-welcome-sign/",
img:"https://craftsbyamanda.com/wp-content/uploads/2021/08/sunflower-welcome-sign-RC-SQ-400x400.jpg",
  imgSrc: [
     {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2021/08/sunflower-welcome-sign-RC-SQ-400x400.jpg",
        originalHeight: "320px",
      },
],
 materials: [
      {
section: "",
        materialInfo: [
   ],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [woodSign],
 rating: { rateOptions: rateOptionsData },
 tags: ["trending"],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[0],
categoryTypes:[CRAFT_CATEGORY_TYPES[0],CRAFT_CATEGORY_TYPES[5]],
productId:"15",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:5,
title:"Ladybug Painted Rocks",
description:"if it has desc goes here",
link:"https://craftsbyamanda.com/ladybug-painted-rocks/",
img:"https://craftsbyamanda.com/wp-content/uploads/2016/06/ladybug-painted-rocks-680.jpg ",
  imgSrc: [
       {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2016/06/ladybug-painted-rocks-680.jpg",
        originalHeight: "320px",
      },
],
 materials: [
   {
        section: "Things you will need",
        materialInfo: [
          "Patio Paint in colors of your choice",
          "Smooth rocks, preferably oval or round in shape",
          "Paintbrush",
          "Toothpick",
          "Outdoor sealer, Outdoor Mod Podge or Patio Paint Clear Coat",
        ],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [paintRock, paintRockIdeas],
 rating: { rateOptions: rateOptionsData },
  tags: ["featured", "latest", "trending"],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[3],
categoryTypes:CRAFT_CATEGORY_TYPES[4],
productId:"16",
skillTypeId:ALL_SKILL_TYPES[1],
postedBy:5,
title:"Lavender Candles",
description:"if it has desc goes here",
link:"https://craftsbyamanda.com/lavender-candles/",
img:"https://craftsbyamanda.com/wp-content/uploads/2021/04/DIY-lavender-candles-SQ-400x400.jpg",
  imgSrc: [
  {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2021/04/DIY-lavender-candles-SQ-400x400.jpg",
        originalHeight: "320px",
      },
],
 materials: [
    {
        section: "Supplies",
        materialInfo: [
          "1 12 oz wide mouth mason jar",
          "1 8 oz wide mouth mason jar This jar should fit into the larger one with a small gap between surfaces.",
          "Dried lavender stems",
          "2 cups Candle wax white soy wax or white paraffin wax",
          "1 Candle wick with clip and sticker, medium size",
          "Wood skewer",
          "15 drops Lavender essential oil optional, add more for a stronger scent",
          "Twine to tie around the mouth of the mason jar, optional",
        ],
      },
   {
        section: "Tools",
        materialInfo: [" Double boiler", "Scissors"],
      },
],
   servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials:[paintLavFlower],
 rating: { rateOptions: rateOptionsData },
  tags: ["latest"],

},
{
projectId:appSections[0].projectId,
categoryId:ALL_CRAFT_VALUES[4],
categoryTypes:CRAFT_CATEGORY_TYPES[1],
productId:"17",
skillTypeId:ALL_SKILL_TYPES[0],
postedBy:36,
title:"DIY Clay Bead Necklace",
description:"I’ve been wanting to learn to make my own beads for a while (because hello, endless jewelry projects), so I finally took the plunge and picked up some Premo Clay to start experimenting with!",
link: "https://thecraftedlife.com/diy-clay-bead-necklace/",
    img: "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace-final4.jpg",
    imgSrc: [
      {
        original:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace-final4.jpg",
        thumbnail:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace-final4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace.jpg",
        thumbnail:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace-7.jpg",
        thumbnail:
          "https://thecraftedlife.com/wp-content/uploads/2014/05/diy_clay_bead_necklace-7.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
  materials: [
      {
        section: "Supplies",
        materialInfo: [
          "premo clay", "bead making tools", "wax paper", "leather cord", "oven",

        ],
      },
    ],
   servings: "",
    estTimeComplete: "1 hour",
    directions: [],
    tutorials: [],
 rating: { rateOptions: rateOptionsData },
  tags: ["trending"],

},
{
  projectId:appSections[0].projectId,
  categoryId:ALL_CRAFT_VALUES[4],
  categoryTypes:CRAFT_CATEGORY_TYPES[0],
  productId:"18",
  skillTypeId:ALL_SKILL_TYPES[1],
  postedBy:1,
  title:"Clay Cactus Ring Holder",
  description: "Display Your Rings in a Cute Way",
  link:"https://www.thesprucecrafts.com/diy-clay-cactus-ring-holder-4142024",
  img:"https://www.thesprucecrafts.com/thmb/shRMVts1wF5w5e5mtvCAVys3cSs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/clay-cactus-ring-dish-1-59382baa5f9b58d58acb9264.jpg",
      imgSrc: [
        {
          original:
            "https://www.thesprucecrafts.com/thmb/cAkkvqwofyHfLqa65VLtYd7s8wg=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-9-add-trinkets-59382d1a5f9b58d58aceb94a.jpg",
          thumbnail:
            "https://www.thesprucecrafts.com/thmb/cAkkvqwofyHfLqa65VLtYd7s8wg=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-9-add-trinkets-59382d1a5f9b58d58aceb94a.jpg",
          thumbnailHeight: "90px",
          originalHeight: "320px",
        },
        {
          original:
            "https://www.thesprucecrafts.com/thmb/XbOfun3mlYRCzxmuxtg5bi8wgg0=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-8-paint-59382d0b5f9b58d58ace9a86.jpg",
          thumbnail:
            "https://www.thesprucecrafts.com/thmb/XbOfun3mlYRCzxmuxtg5bi8wgg0=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-8-paint-59382d0b5f9b58d58ace9a86.jpg",
          thumbnailHeight: "90px",
          originalHeight: "320px",
        },
        {
          original:
            "https://www.thesprucecrafts.com/thmb/w_VRTYAbBCf4rgaaqQgVGXdBIss=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-7-assemble-dish-59382cff5f9b58d58ace8101.jpg",
          thumbnail:
            "https://www.thesprucecrafts.com/thmb/w_VRTYAbBCf4rgaaqQgVGXdBIss=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/step-7-assemble-dish-59382cff5f9b58d58ace8101.jpg",
          thumbnailHeight: "90px",
          originalHeight: "320px",
        },
      ],
   materials: [
        {
          section: "Tools",
          materialInfo: [
  "Oven",
  
  
          ],
        },
    {
            section: "Supplies",
            materialInfo: [
              "Oven bake clay",
    "Smooth water glass",
    "Small oven-safe bowl",
    "Baking sheet",
    "E-6000 glue",
    "Gold paint (optional)",
    "Paintbrush (optional)",
    
            ],
          },
      ],
     servings: "",
      estTimeComplete: "",
      directions: [],
      tutorials: [],
   rating: { rateOptions: rateOptionsData },
    tags: [],
  
  },
  {
    projectId:appSections[0].projectId,
    categoryId:ALL_CRAFT_VALUES[9],
    categoryTypes:CRAFT_CATEGORY_TYPES[4],
    productId:"19",
    skillTypeId:ALL_SKILL_TYPES[0],
    postedBy:5,
    title:"Lavender Bath Bombs",
    description:"Drop a lavender bath bomb into the bathtub to fizzle and dissipate, filling the room with the soothing and relaxing scent of lavender",
        link: "https://craftsbyamanda.com/lavender-bath-bombs/",
        img: "https://craftsbyamanda.com/wp-content/uploads/2021/02/lavender-bath-bombs-SQ-400x400.jpg",
      imgSrc: [
         {
            original:
              "https://craftsbyamanda.com/wp-content/uploads/2021/02/lavender-bath-bombs-SQ-400x400.jpg",
            originalHeight: "320px",
          },
    ],
       materials: [
          {
            section: "Supplies",
            materialInfo: [
              "1 cup baking soda",
              "1/2 cup citric acid",
              "1/2 cup Epsom salt",
              "1/2 cup cornstarch",
              "2 1/2 Tbsp coconut oil melted",
              "3/4 Tbsp water",
              "2 Tbsp lavender buds",
              "10 drops lavender essential oil",
              "8 drops purple liquid coloring *optional",
            ],
          },
          {
            section: "Tools",
            materialInfo: [
              "2-inch bath bomb mold",
              "Medium and Small Mixing Bowls",
            ],
          },
        ],
       servings: "",
        estTimeComplete: "",
        directions: [  "In case a bath bomb cracks while removing it from a mold, go back and repeat the molding process again. If needed, add a tiny bit of water to remix ingredients. If your Epsom salts already have a fragrance, you can skip adding the essential oils.",],
        tutorials: [bathBomb],
     rating: { rateOptions: rateOptionsData },
      tags: [],
    
    },
    {
      projectId:appSections[0].projectId,
      categoryId:ALL_CRAFT_VALUES[3],
      categoryTypes:CRAFT_CATEGORY_TYPES[4],
      productId:"20",
      skillTypeId:ALL_SKILL_TYPES[1],
      postedBy:5,
      title: "How to Make Lavender Soap",
      description:"if it has desc goes here",
        link: "https://craftsbyamanda.com/how-to-make-lavender-soap/",
          img: " https://craftsbyamanda.com/wp-content/uploads/2020/10/how-to-make-lavender-soap-SQ-400x400.jpg",
          imgSrc: [
            {
              original:
                " https://craftsbyamanda.com/wp-content/uploads/2020/10/how-to-make-lavender-soap-SQ-400x400.jpg",
              originalHeight: "320px",
            },
          ],
       materials: [
            {
      section: "",
              materialInfo: [
         ],
            },
      ],
         servings: "",
          estTimeComplete: "",
          directions: [],
          tutorials: [moldSoap],
       rating: { rateOptions: rateOptionsData },
        tags: ["explore"],
      
      }
  


];
