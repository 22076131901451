import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CardMedia,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { AppBarDrawers } from "./AppBarDrawers";

import { useState } from "react";
import { useRecoilValue } from "recoil";
import { appProjects } from "../appState/appProject";
import { StyledCategoryGridItem } from "../bitsHome/HomeStyles";
import {
  StyledCategoryListDrawer,
  StyledCenterStack,
  StyledDrawerLogoText,
} from "../AppStyles";

export function AppBarCategoryButton() {
  const projectInfo = useRecoilValue(appProjects);
  const [openCategory, setOpenCategory] = useState(false);

  return (
    <AppBarDrawers
      selectedValue={openCategory}
      setValue={setOpenCategory}
      icon={<AutoAwesomeMosaicIcon />}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledCenterStack direction="row" gap={1}>
          {/* <Avatar> */}
          <img src="/images/lovelogo.png" alt="us" height={25} width={25} />
          <StyledDrawerLogoText>Lovebug Bits</StyledDrawerLogoText>
        </StyledCenterStack>
      </div>

      <Grid container direction="row" justifyContent="space-between">
        <StyledCategoryGridItem item xs={6} sm={7}>
          {projectInfo.map((item) => (
            <StyledCategoryListDrawer key={item.projectId}>
              <ListItem disableGutters>
                <ListItemButton href={`/${item.projectId}`}>
                  <ListItemText primary={item.label} />
                  <ChevronRightIcon />
                </ListItemButton>
              </ListItem>
            </StyledCategoryListDrawer>
          ))}
        </StyledCategoryGridItem>
        <StyledCategoryGridItem item xs={6} sm={5}>
          <CardMedia
            component="img"
            image="/images/categories/categoriesDrawerImg.png"
            title="drawer"
            sx={{ objectFit: "contain" }}
            height={230}
          />
        </StyledCategoryGridItem>
      </Grid>
    </AppBarDrawers>
  );
}
