import { Breadcrumbs, Link, Typography } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { getProduct } from "../appState/productItems";

export function ProductBreadcrumb() {
  const { productId } = useParams();
  const product = useRecoilValue(getProduct(productId));

  return (
    <Box role="presentation" p={2} >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ChevronRightIcon
            color="primary"
            sx={{ fontSize: "large", color: "primary" }}
          />
        }
        pl={1}
        variant="button"
        fontSize="medium"
        // color="#1b4b7c"
      >
        <Link href="/">Home</Link>

        <Link href={`/${product.projectId}`}>{product.projectId}</Link>
        <Typography
          fontSize="small"
          color="primary"
          sx={{ fontStyle: "italic" }}
        >
          {product.categoryId}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
}
