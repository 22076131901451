import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../appState/productItems";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../App.css";

import { Ratings } from "../ratings/Ratings";
import { Share } from "../share/Share";
import {
  StyledProductViewImgBox,
  StyledProductViewListBox,
} from "./ProductStyles";

export function ProductView({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const product = useRecoilValue(getProduct(productId));

  return (
    <Grid
      container
      direction="row"
      px={1}
      display="flex"
      justifyContent="center"
      alignSelf="center"
      gap={10}
    >
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <StyledProductViewImgBox>
          <ImageGallery
            items={product?.imgSrc}
            showPlayButton={false}
            showNav={false}
            showFullscreenButton={false}
            slideOnThumbnailOver={true}
            thumbnailHeight={"90px"}
          />
        </StyledProductViewImgBox>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <StyledProductViewListBox>
          <ListItemText
            primary={product.title}
            secondaryTypographyProps={{ component: "div", fontWeight: "bold" }}
            secondary={
              <div>
                {`By: ${product.postedBy}`}
                <Stack direction="row" alignItems="center">
                  <Ratings productIdToUse={product?.productId} />

                  <Typography variant="caption" alignSelf="flex-end" pb={1}>
                    {/* {product?.ratings.length} */}
                    {product?.rating.length} ratings
                  </Typography>
                </Stack>
              </div>
            }
            // primary={capitalLetterFormatter({ value: product.title })}
            // secondary={capitalLetterFormatter({ value: product.sellerName })}
            primaryTypographyProps={{ fontSize: "x-large" }}
          />

          {/* <Typography
            variant="caption"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            {product?.postedBy}
          </Typography> */}

          {/* <FormLabel>Product Info</FormLabel> */}

          {/* <Typography variant="body1" gutterBottom>
            {product?.description}
          </Typography> */}

          {/* <Typography variant="subtitle1">{product.sellerId}</Typography> */}
          <Divider />

          <Stack direction="row" justifyContent="center" py={3} gap={5}>
            <Box
              display="flex"
              justifyContent="center"
              alignSelf="center"
              pt={1}
            >
              <Share productIdToUse={product.productId} />
            </Box>
            {/* 
            <Box >
              <BookmarkCheck productIdToUse={product.productId} />
            </Box>
            <Box >
              <FavoriteCheck productIdToUse={product.productId} />
            </Box> */}

            <Box
              display="flex"
              justifyContent="center"
              alignSelf="center"
              pt={1}
            >
              {!!product.link ? (
                <Button
                  href={product.link}
                  endIcon={<OpenInNewIcon />}
                  variant="contained"
                  target="_blank"
                >
                  Follow Link
                </Button>
              ) : null}
            </Box>
          </Stack>
          {/* </Stack> */}
          {/* <QtySelect productIdToUse={product.productId}/> */}
        </StyledProductViewListBox>
      </Grid>
    </Grid>
  );
}
