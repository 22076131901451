import { atom } from "recoil";

//search icon hide/show button
export const showMode = atom({
  key: "showMode",
  default: false,
});

// homecard feaatured
export const featuredMode = atom({
  key: "featuredMode",
  default: true,
});

// homecard drawer
export const productCardState = atom({
  key: "productCardState",
  default: undefined
});

//product comments form
export const productFormState = atom({
  key: "productFormState",
  default: undefined
});

//form close button
export const showCloseForm = atom({
  key: "showCloseForm",
  default: false,
});