import { appSections } from "../bitsAppSections";
import { ALL_FOOD_VALUES, FOOD_VALUE_TYPES } from "../bitsCategoryTypes/bake";

import { ALL_SKILL_TYPES } from "../bitsCategoryTypes/multiType";

import { rateOptionsData } from "../ratings";
import {
  buttercreamFilling,
  fondant,
  fondantCupcake,
  pipeBlossom,
  russianTip,
} from "../tutorials";

export const bitsBaking = [
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "100",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Cinnamon Roll Cheesecake",
    description:
      "Get ready for a dessert extravaganza with this cinnamon roll cheesecake!",
    link: "https://tasty.co/recipe/cinnamon-roll-cheesecake",
    img: "https://img.buzzfeed.com/tasty-app-user-assets-prod-us-east-1/recipes/858de8a1025d44a69641254e25bde14a.png?resize=300:*&output-format=jpg&output-quality=auto",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/tasty-app-user-assets-prod-us-east-1/recipes/858de8a1025d44a69641254e25bde14a.png?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "16 oz cream cheese, softened",
          "½ cup sugar",
          "½ cup sour cream",
          "1 teaspoon vanilla extract",
          "2 eggs",
          "½ cup butter, melted",
          "½ cup light brown sugar",
          "2 tablespoons cinnamon",
          "1 package refrigerated cinnamon rolls, with frosting",
        ],
      },
    ],
    servings: "8",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: ALL_FOOD_VALUES[1],
    productId: "101",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 5,
    title: "Chocolate Mug Cake",
    description:
      "Whether you're looking for a gift in a jar idea or just need a simple single serve dessert, this chocolate mug cake is a delicious solution!",
    link: "https://craftsbyamanda.com/chocolate-mug-cake-gift-printable-recipe/",
    img: " https://craftsbyamanda.com/wp-content/uploads/2021/11/chocolate-mug-cake-in-a-jar-SQ-640x640-2-400x400.jpeg",
    imgSrc: [
      {
        original:
          "https://craftsbyamanda.com/wp-content/uploads/2021/11/chocolate-mug-cake-in-a-jar-SQ-640x640-2-400x400.jpeg",
        thumbnail:
          "https://craftsbyamanda.com/wp-content/uploads/2021/11/chocolate-mug-cake-in-a-jar-SQ-640x640-2-400x400.jpeg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://amandascookin.com/wp-content/uploads/2021/03/chocolate-mug-cake-V02.jpg",
        thumbnail:
          "https://amandascookin.com/wp-content/uploads/2021/03/chocolate-mug-cake-V02.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://amandascookin.com/wp-content/uploads/2021/03/chocolate-mug-cake-V01.jpg",
        thumbnail:
          "https://amandascookin.com/wp-content/uploads/2021/03/chocolate-mug-cake-V01.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "4 tablespoons all-purpose flour",
          "2 tablespoons white sugar",
          "2 tablespoons brown sugar lightly packed",
          "2 tablespoons unsweetened cocoa powder",
          "1/2 teaspoon baking powder",
          "1/8 teaspoon salt",

          "4 tablespoons milk",
          "2 tablespoons canola oil or avocado oil",
          "1/2 teaspoon vanilla",
          "1 tablespoon chocolate chips for topping (optional)",
        ],
      },
      {
        section: "Toppings Ideas",
        materialInfo: [
          "Vanilla ice cream",
          "Nutella",
          "Peanut butter",
          "Caramel sauce",
          "Whipped cream",
        ],
      },
    ],
    servings: "Single mug",
    estTimeComplete: "7 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "102",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Classic Carrot Cake",
    description:
      "This carrot cake is moist, flavorful, and packed with sweet, carroty goodness. Topped with a rich cream cheese frosting, it's the perfect sweet treat for any occasion.",
    link: "https://tasty.co/recipe/carrot-cake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/205294.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/205294.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "3 cups carrot, hand-grated",
          "2 ½ cups all-purpose flour, plus 1 tablespoon, divided",
          " 2 teaspoons ground cinnamon",
          "¼ teaspoon allspice",
          "1 teaspoon kosher salt",
          "1 teaspoon baking powder",
          "½ teaspoon baking soda",
          " 1 cup light brown sugar, packed",
          "½ cup granulated sugar",
          "3 large eggs, room temperature",
          "1 cup vegetable oil",
          "1 cup raisin",
          "1 cup crushed pineapple, canned",
        ],
      },
      {
        section: "Icing",
        materialInfo: [
          "1 cup unsalted butter, (2 sticks) room temperature",
          "12 oz cream cheese, room temperature",
          "1 ½ teaspoons vanilla extract",
          "2 cups powdered sugar",
          "2 cups walnuts, crushed",
        ],
      },
    ],
    servings: "10",
    estTimeComplete: "1 hr 55 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "103",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "The Best Fudgy Brownies",

    description: "It's a classic brownie recipe. You can't go wrong. ",
    link: "https://tasty.co/recipe/the-best-fudgy-brownies",
    img: "https://img.buzzfeed.com/video-api-prod/assets/fafe8090b4f3434f80c33b6e4ce40e24/BFV21539_BestFudgyBrownies-ThumbB1080.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/fafe8090b4f3434f80c33b6e4ce40e24/BFV21539_BestFudgyBrownies-ThumbB1080.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "8 oz good-quality chocolate, semi-sweet",
          "12 tablespoons butter, melted",
          "1 ¼ cups sugar",
          "2 eggs",
          "2 teaspoons vanilla extract",
          "¾ cup all-purpose flour",
          "¼ cup cocoa powder",
          "1 teaspoon salt",
        ],
      },
    ],
    servings: "9",
    estTimeComplete: "45 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "104",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Chocolate Chip Cookies",

    description:
      "You can't go wrong with a classic, and these chocolate chip cookies are just that! Crispy on the edges and chewy in the middle, they are the perfect treat for any occasion",
    link: "https://tasty.co/recipe/chocolate-chip-cookies",
    img: "https://img.buzzfeed.com/video-api-prod/assets/93263b90bdc842b7b56760b657389baa/BFV12155_CookieDoughPrep-ThumbTextless1080.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/93263b90bdc842b7b56760b657389baa/BFV12155_CookieDoughPrep-ThumbTextless1080.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "2 cups butter, melted",
          "2 cups brown sugar, packed",
          "2 cups granulated sugar",
          "4 large eggs",
          "1 tablespoon vanilla extract",
          "5 cups all-purpose flour",
          "2 teaspoons baking powder",
          "4 cups chocolate chips",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "105",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "The Best One-Bowl Brownies",
    description:
      "The Best One-Bowl Brownies that are rich, fudgy, and oh-so-decadent",
    link: "https://tasty.co/recipe/the-best-one-bowl-brownies",

    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/177189.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/177189.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "1 cup unsalted butter, 2 sticks",
          "1 ½ cups semi-sweet chocolate chips, or dark chocolate chips, divided",
          "1 ½ cups granulated sugar",
          "¾ cup brown sugar",
          "1 tablespoon vanilla extract",
          "1 teaspoon salt",
          "3 large eggs",
          "1 ¼ cups all-purpose flour",
          "⅓ cup dark cocoa powder",
          "nonstick cooking spray, for greasing",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "107",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 23,
    title: "Banana Chocolate Chip Muffins",
    description:
      "These Banana Chocolate Chip Muffins are moist, but not too sweet and have plenty of chocolate chips.",
    link: "https://spicysouthernkitchen.com/banana-chocolate-chip-muffins/",
    img: "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins-a.jpg",

    imgSrc: [
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins-c.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins-c.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins-a.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Banana-Chocolate-Chip-Muffins-a.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "1/3 cup granulated sugar",
          "1/3 cup packed light brown sugar",
          "1 1/2 teaspoons baking powder",
          "1/4 teaspoon salt",
          "1 cup mashed ripe bananas, about 2 large",
          "1 large egg",
          "1/2 cup unsalted butter, melted",
          "1/4 cup milk",
          "1 teaspoon vanilla extract",
          "1 cup semisweet chocolate chips",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "35 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "108",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "3 Ingredient Peanut Butter Cookies",
    description:
      "Whip up these scrumptious 3-Ingredient Peanut Butter Cookies in a snap",
    link: "https://tasty.co/recipe/3-ingredient-peanut-butter-cookies",
    img: "https://img.buzzfeed.com/video-api-prod/assets/334c9eee7f37412fba5111e291dd6cb1/YT_Thumb.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/334c9eee7f37412fba5111e291dd6cb1/YT_Thumb.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: ["1 cup peanut butter", "½ cup sugar", "1 egg"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "109",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "How To Make Macarons",
    description:
      "Macarons may seem intimidating but with our easy-to-follow guide, they don’t have to be. And you can customize them!",
    link: "https://tasty.co/recipe/macarons",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/4cd892f13d8f42689a1044fae7a48afb/BFV37854_TheMostFoolProofMacaronsYoullEverMake_KP_121819_YT.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/4cd892f13d8f42689a1044fae7a48afb/BFV37854_TheMostFoolProofMacaronsYoullEverMake_KP_121819_YT.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Macarons",
        materialInfo: [
          "1 ¾ cups powdered sugar",
          "1 cup almond flour, finely ground",
          "1 teaspoon salt, divided",
          "3 egg whites, at room temperature",
          "¼ cup granulated sugar",
          "½ teaspoon vanilla extract",
          "2 drops pink gel food coloring",
        ],
      },
      {
        section: "Vanilla Buttercream",
        materialInfo: [
          "1 cup unsalted butter, 2 sticks, at room temperature",
          "3 cups powdered sugar",
          "1 teaspoon vanilla extract",
          "3 tablespoons heavy cream",
        ],
      },
    ],
    servings: "30",
    estTimeComplete: "1 hr 50 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "110",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "How To Make The Best Brownies",
    description:
      "Indulge in the rich, chocolatey goodness of these Ultimate Brownies that'll leave you clamoring for more!",
    link: "https://tasty.co/recipe/ultimate-brownies",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/166813.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/166813.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "2 ½ sticks unsalted butter, plus more, softened, for greasing",
          "8 oz good-quality semisweet chocolate, or bittersweet chocolate, 60-70% cacao, roughly chopped",
          "¾ cup unsweetened dutch process cocoa powder, divided",
          "1 tablespoon espresso powder",
          "2 cups granulated sugar",
          "½ cup dark brown sugar, packed",
          "2 teaspoons vanilla extract",
          "2 teaspoons kosher salt",
          "6 large eggs",
          "1 cup all-purpose flour",
          "flaky sea salt, for sprinkling",
        ],
      },
    ],
    servings: "24",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "111",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 0,
    title: "Boozy Cotton Candy Milkshake",
    description:
      "This cotton candy milkshake will make you feel like a kid in a candy store, with an added kick from the brandy or vodka!",
    link: "https://tasty.co/recipe/boozy-cotton-candy-milkshake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/226696.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/226696.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 2 oz white chocolate, melted",
          "1 tablespoon rainbow sprinkles",
          "1 cup cotton candy, plus more for garnish",
          "8 oz vanilla ice cream",
          "1 oz brandy",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [
      "Dip the rim of a milkshake glass in the melted white chocolate. Dip in the sprinkles, turning to coat, then set aside.",
      "In a blender, combine the cotton candy, ice cream, and brandy. Blend until thick and smooth.",
      "Pour the milkshake into the glass and garnish with more cotton candy. Serve immediately.",
      "Enjoy!",
    ],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[3],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "112",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Apple Pie From Scratch",

    description: "Nothing beats a classic apple pie made from scratch.",
    link: "https://tasty.co/recipe/apple-pie-from-scratch",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/2b422cd19f6c488fbe649da9739b5542/fb.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/2b422cd19f6c488fbe649da9739b5542/fb.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Pie Dough",
        materialInfo: [
          "2 ½ cups flour",
          "1 teaspoon salt",
          "1 ½ sticks butter, 1 1/2 sticks, cold, cubed",
          "8 tablespoons ice water, or as needed",
        ],
      },
      {
        section: "Filling",
        materialInfo: [
          "2 ½ lb granny smith apple, cored, sliced, peeled",
          "¾ cup sugar",
          "2 tablespoons flour",
          "½ teaspoon salt",
          "1 teaspoon cinnamon",
          "¼ teaspoon nutmeg",
          "½ lemon",
          "1 egg, beaten",
          "1 tablespoon sugar",
        ],
      },
      {
        section: "Topping",
        materialInfo: ["1 scoop vanilla ice cream"],
      },
    ],
    servings: "8",
    estTimeComplete: "1 hr",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "113",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 37,
    title: "A Classic Irish Coffee Cocktail",
    description:
      "Too good for just one holiday; this warm coffee with Irish Whiskey and whipped cream is perfect all winter long.",
    link: "https://creative-culinary.com/classic-irish-coffee-cocktail-recipe/",
    img: "https://creative-culinary.com/wp-content/uploads/irish-coffee-2.jpg",
    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/irish-coffee-2.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/irish-coffee-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/irish-coffee-1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/irish-coffee-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "3-4 ounces Irish whiskey to taste",
          "8 ounces fresh-brewed coffee",
          "2-4 brown sugar cubes to taste (or 2-4 tsp brown sugar)",
          "¼ cup whipping cream",
        ],
      },
      {
        section: "Garnish",
        materialInfo: ["Chocolate Shavings optional", "2 Tbsp Whipped Cream"],
      },
    ],
    servings: "2",
    estTimeComplete: "15 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "114",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 4,
    title: "Easy Homemade Marshmallow Fondant",
    description:
      "This easy fondant recipe uses ingredients that are probably already in your pantry. Marshmallows make this recipe softer than our standard fondant icing with a sweet taste that kids love.",
    link: "https://www.wilton.com/rolled-marshmallow-fondant/WLRECIP-242.html",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/1280x1280/products/8265/20500/jyccjcpgc1xvolg6i9wz__92560.1677143057.jpg?c=1",
    imgSrc: [
      {
        original:
          " https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8265/20500/jyccjcpgc1xvolg6i9wz__92560.1677143057.jpg?c=1",
        thumbnail:
          " https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8265/20500/jyccjcpgc1xvolg6i9wz__92560.1677143057.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },

      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8265/20501/ycifvvsm5lbaec5lvp7t__46216.1677143057.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8265/20501/ycifvvsm5lbaec5lvp7t__46216.1677143057.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://edge.disstg.commercecloud.salesforce.com/dw/image/v2/AAWA_STG/on/demandware.static/-/Sites-wilton-project-master/default/dw0f21f183/images/project/WLRECIP-242/marshmallow-fondant-4.jpg",
        thumbnail:
          "https://edge.disstg.commercecloud.salesforce.com/dw/image/v2/AAWA_STG/on/demandware.static/-/Sites-wilton-project-master/default/dw0f21f183/images/project/WLRECIP-242/marshmallow-fondant-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Tools",
        materialInfo: ["Rolling pin", "Microwave"],
      },
      {
        section: "Ingredients",
        materialInfo: [
          "1 package (16 ounces) white mini marshmallows",
          "2-5 tablespoons water",
          "2 pounds (about 8 cups) sifted powdered sugar (confectioners' sugar)",
          "1/2 cup solid vegetable shortening",
          "Icing colors (optional)",
        ],
      },
    ],
    servings: "2 lbs",
    estTimeComplete: "prep 20 min total 8hrs 40 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "115",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "The Best Chocolate Cake",

    description:
      "With moist layers of velvety chocolate cake and luscious chocolate buttercream frosting, it's a dessert that'll make you want to skip dinner and go straight to dessert.",
    link: "https://tasty.co/recipe/the-ultimate-chocolate-cake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/109778.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/109778.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cake",
        materialInfo: [
          "1 ½ cups flour",
          "1 cup dutch processed cocoa powder",
          "1 teaspoon salt",
          "1 ½ teaspoons baking soda",
          "½ teaspoon baking powder",
          "1 ½ cups stout",
          "1 tablespoon vanilla extract",
          "1 tablespoon espresso powder",
          "1 cup butter",
          "1 ½ cups sugar",
          "3 eggs",
          "½ cup mayonnaise",
          "4 oz dark chocolate, chopped",
        ],
      },
      {
        section: "Buttercream",
        materialInfo: [
          "1 ½ cups butter, softened",
          "1 tablespoon vanilla extract",
          "½ cup dutch processed cocoa powder",
          "5 cups powdered sugar",
          "5 tablespoons milk",
          "fresh fruit, to decorate",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "1 hr 20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["latest"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "116",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 37,
    title: "Honey Butter Beer Bread",

    description:
      "A fast and east bread without yeast using beer to help with the rise and the fantastic flavor!",
    link: "https://creative-culinary.com/honey-butter-beer-bread-recipe/",
    img: "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-1.jpg",

    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-2.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-3.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-butter-beer-bread-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "6 Tablespoons butter melted, divided",
          "3 cups all-purpose flour",
          "1 tablespoon baking powder",
          "1 teaspoon fine sea salt",
          "1 bottle 12 ounces beer",
          "¼ cup honey",
        ],
      },
    ],
    servings: "12 slices",
    estTimeComplete: "1 hr",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "117",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Browned “Butter” Chocolate Chip Cookies",
    description: "These vegan chocolate chip cookies are out of this world",

    link: "https://tasty.co/recipe/browned-butter-chocolate-chip-cookies",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/249914.jpg?resize=300:*&output-format=jpg&output-quality=auto",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/249914.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "3 oz chopped pecans, finely chopped",
          "¾ cup refined coconut oil, melted",
          "¾ cup dark brown sugar",
          "½ cup granulated sugar",
          "¼ cup unsweetened non-dairy milk, of choice",
          "1 teaspoon vanilla extract",
          "1 ½ cups all-purpose flour",
          "½ teaspoon baking soda",
          "1 ½ teaspoons kosher salt",
          "3.5 oz dark chocolate, chopped",
          "½ cup semisweet chocolate chunks",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured", "latest"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "118",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Cookies & Cream Truffles Easy Dessert",
    description:
      "These bite-sized truffles are an easy and impressive dessert for any occasion. The creamy white chocolate filling is studded with crushed Oreo cookies, making for a sweet and crunchy treat.",
    link: "https://tasty.co/recipe/cookies-cream-truffles-easy-dessert",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/33731c6a8f5f4704a9dce477f6d40237/BFV11286_3IngredientDesserts2.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/33731c6a8f5f4704a9dce477f6d40237/BFV11286_3IngredientDesserts2.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "36 chocolate sandwich cookies",
          "8 oz cream cheese, softened",
          "12 oz white chocolate, melted",
        ],
      },
    ],
    servings: "6",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: [FOOD_VALUE_TYPES[1], FOOD_VALUE_TYPES[7]],
    productId: "119",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 8,
    title: "Baileys Cupcakes",
    description:
      "Chocolate Baileys Cupcakes, topped with Baileys Buttercream Frosting, and a Baileys Truffle!",
    link: "https://www.janespatisserie.com/2015/12/31/baileys-cupcakes/",
    img: "https://www.janespatisserie.com/wp-content/uploads/2015/12/IMG_2280-1365x2048.jpg",
    imgSrc: [
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2015/12/IMG_2280-1365x2048.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2015/12/IMG_2280-1365x2048.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2015/12/IMG_2239-1365x2048.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2015/12/IMG_2239-1365x2048.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cupcakes",
        materialInfo: [
          "150 g Unsalted Butter",
          "150 g Light Brown Sugar",
          "3 Medium Eggs",
          "125 g Self Raising Flour",
          "50 g Cocoa Powder",
          "100 ml Baileys",
        ],
      },
      {
        section: "Buttercream",
        materialInfo: [
          "150 g Unsalted Butter",
          "400 g Icing Sugar",
          "125 ml Baileys",
        ],
      },
      {
        section: "Topping",
        materialInfo: ["Baileys Truffles", "Sprinkles"],
      },
    ],
    servings: "12",
    estTimeComplete: "30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["latest"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "120",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Cookies & Cream Brownie Cheesecake Bars",
    description:
      "These cookies & cream brownie cheesecake bars are the ultimate dessert mashup. With a rich chocolatey brownie base, creamy cheesecake filling, and chunks of crunchy cookies, these bars are an indulgent treat for any sweet tooth.",
    link: "https://tasty.co/recipe/cookies-cream-brownie-cheesecake-bars",
    img: "https://img.buzzfeed.com/video-api-prod/assets/b19a1dc5a43544d88b7da9d529e4a252/BFV14521_BrownieOreoCheesecakeBars-ThumbB1080.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/b19a1dc5a43544d88b7da9d529e4a252/BFV14521_BrownieOreoCheesecakeBars-ThumbB1080.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 box brownie mix, prepared according to package instructions",
          "24 oz cream cheese, softened",
          "¾ cup sugar",
          "2 eggs, beaten",
          "1 tablespoon vanilla extract",
          "36 chocolate sandwich cookies",
        ],
      },
    ],
    servings: "1",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "121",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 4,
    title: "Pull-Apart Brownie Heart",
    description:
      "Forget the box of candies—give your sweetie a treat they'll love to eat",
    link: "https://wilton.com/pull-apart-brownie-heart/wlproj-9106/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/320w/products/7454/19199/apif1vsqfsrkf35eldij__00802.1677142186.jpg?c=1",
    imgSrc: [
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/7454/19198/ctncnr1jmaqurtmhzmsl__57288.1677142185.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/7454/19198/ctncnr1jmaqurtmhzmsl__57288.1677142185.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/320w/products/7454/19199/apif1vsqfsrkf35eldij__00802.1677142186.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/320w/products/7454/19199/apif1vsqfsrkf35eldij__00802.1677142186.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Your Favorite Brownie Recipe or Mix",
          "Rose Icing Color, 1 oz.",
          "Blue Icing Color, 1 oz.",
          "White Decorator Icing - 4.5 lb.",
        ],
      },
      {
        section: "Tools",
        materialInfo: [
          "Large Round Tip 2A",
          "Bite-Size Brownie Squares Silicone Mold, 24-Cavity",
          "Extra Large Drop Flower Tip 1B",
          "12-Inch Disposable Decorating Bags, 24-Count Cake Piping Bags",
          "Large Drop Flower Tip 2C",
          "Cooling Rack",
          "Open Star Cake Decorating Tip 1M",
          "No. 4B Open Star Piping Tip",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: [FOOD_VALUE_TYPES[1], FOOD_VALUE_TYPES[7]],
    productId: "122",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 37,
    title: "Pina Colada Cupcakes with Rum Buttercream",
    description:
      "A cupcake with a cocktail connection. Trust me, no one at my Memorial Day Party complained about these Pina Colada Cupcakes with Rum Buttercream!",
    link: "",
    img: "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-3.jpg",
    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-3.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-2.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/pina-colada-cupcakes-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 cups all-purpose flour",
          "¾ teaspoon baking powder",
          "½ teaspoon baking soda",
          "½ teaspoon salt",
          "⅓ cup butter softened",
          "1 ¼ cups sugar",
          "1 teaspoon vanilla",
          "2 eggs",
          "½ cup dairy sour cream",
          "¼ cup milk",
          "¼ cup rum",
          "1 cup toasted coconut",
          "¼ cup chopped almonds",
        ],
      },
      {
        section: "Rum Buttercream Frosting",
        materialInfo: [
          "1 cup unsalted butter softened",
          "5 cups powdered sugar",
          "½ teaspoon salt",
          "2 tablespoons white rum",
          "2-4 tablespoons half and half",
        ],
      },
      {
        section: "Garnish",
        materialInfo: [
          "¼ cup Toasted coconut",
          "Grilled Pineapple chunks",
          "Maraschino Cherries",
        ],
      },
    ],
    servings: "24",
    estTimeComplete: "40 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["latest"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "123",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 8,
    title: "Galaxy Chocolate Cupcakes",
    description:
      "Yummy Galaxy chocolate cupcakes with a chocolate cupcake sponge, Galaxy chocolate buttercream frosting. GALAXY HEAVEN!",
    link: "https://www.janespatisserie.com/2020/01/29/galaxy-chocolate-cupcakes/",
    img: "https://www.janespatisserie.com/wp-content/uploads/2020/01/IMG_9656_1-1365x2048.jpg",
    imgSrc: [
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2020/01/IMG_9656_1-1365x2048.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2020/01/IMG_9656_1-1365x2048.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2020/01/IMG_9653_1-1365x2048.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2020/01/IMG_9653_1-1365x2048.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cupcakes",
        materialInfo: [
          "150 g Unsalted Butter/Stork",
          "150 g Light Brown Sugar",
          "120 g Self Raising Flour",
          "30 g Cocoa Powder",
          "3 Medium Eggs",
          "100 g Galaxy Chocolate (chopped)",
        ],
      },
      {
        section: "Buttercream",
        materialInfo: [
          "150 g Unsalted Butter (not stork)",
          "300 g Icing Sugar",
          "175 g Galaxy Chocolate (melted)",
        ],
      },
      {
        section: "Decoration",
        materialInfo: ["Galaxy Chocolate", "Galaxy Counters", "Sprinkles"],
      },
    ],
    servings: "12",
    estTimeComplete: "1 hr",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "124",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 20,
    title: "Mudslide",
    description:
      "Here's how to make a mudslide! This mudslide recipe makes that classic drink starring vodka, Kahlua and Irish cream.",
    link: "https://www.acouplecooks.com/mudslide-recipe/",
    img: "https://www.acouplecooks.com/wp-content/uploads/2021/01/Mudslide-012.jpg",
    imgSrc: [
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2021/01/Mudslide-012.jpg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "garnish",
        materialInfo: [
          "Shaved chocolate (optional)",
          "chocolate sauce for the rim (optional)",
        ],
      },
      {
        section: "Ingredients",
        materialInfo: [
          "1 ounce* vodka,",
          "1 ounce coffee liqueur (like Kahlua)",
          "1 ½ ounces Irish cream (like Baileys)",
          "1 ½ ounces heavy cream (or 2 scoops vanilla ice cream and ½ cup ice*)",
        ],
      },
    ],
    servings: "1 drink",
    estTimeComplete: "5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "125",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 21,
    title: "Red Sangria",
    description:
      "The perfect fruity summer beverage that’s adaptable and delicious!",
    link: "https://minimalistbaker.com/easy-traditional-red-sangria/",
    img: "https://minimalistbaker.com/wp-content/uploads/2015/07/Simple-Traditional-Red-Sangria-SQUARE-340x340.jpg",
    imgSrc: [
      {
        original:
          "https://minimalistbaker.com/wp-content/uploads/2015/07/Simple-Traditional-Red-Sangria-SQUARE-340x340.jpg",
        thumbnail:
          "https://minimalistbaker.com/wp-content/uploads/2015/07/Simple-Traditional-Red-Sangria-SQUARE-340x340.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://minimalistbaker.com/wp-content/uploads/2015/07/SIMPLE-Amazing-Traditional-Red-Sangria-6-ingredients-SO-flavorful-easy-and-delicious-sangria-recipe-wine-summer-recipe-drink.jpg",
        thumbnail:
          "https://minimalistbaker.com/wp-content/uploads/2015/07/SIMPLE-Amazing-Traditional-Red-Sangria-6-ingredients-SO-flavorful-easy-and-delicious-sangria-recipe-wine-summer-recipe-drink.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1/2 medium apple",
          "1/2 medium orange",
          "3-4 Tbsp organic brown sugar (or 3 Tbsp (37.5 g) organic cane sugar as original recipe is written)",
          "3/4 cup orange juice",
          "1/3 cup brandy",
          "750 ml bottle dry Spanish red wine",
          "1 cup Ice to chill",
        ],
      },
    ],
    servings: "4 cups",
    estTimeComplete: "5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "126",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Pineapple Rum Punch",
    description:
      "Why not have a beachy stay-cation any time you want with a pitcher of fruity Pineapple Rum Punch?! It's perfect for entertaining friends!",
    link: "https://realhousemoms.com/pineapple-rum-punch/",
    img: "https://realhousemoms.com/wp-content/uploads/Pineapple-Rum-Punch-Easy-Drink-Recipe-HERO.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Rum-Punch-Easy-Drink-Recipe-HERO.jpg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Mostly pineapple juice",
          "Orange juice",
          "Mango nectar",
          "Dark rum",
          "Pineapple rum",
          "Grenadine",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "2hours 5 mins",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "140",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 33,
    title: "Dirt Cake",
    description:
      "This dirt cake recipe is a great conversation piece at parties — adults love it as much as children do. Garnish the flower pot cake with gummy worms; display it with a new garden trowel and artificial flowers for the full effect.",
    link: "https://www.allrecipes.com/recipe/7255/dirt-cake-i/",
    img: "https://www.allrecipes.com/thmb/aS6zcFjNYONhQlHag8UsOmFs9pI=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/7255_Dirt-Cake-I_footballgrl16_SERP_1073531_original-1x1-1-85b80952518e4f739df7825126e14b36.jpg",
    imgSrc: [
      {
        original:
          "https://www.allrecipes.com/thmb/aS6zcFjNYONhQlHag8UsOmFs9pI=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/7255_Dirt-Cake-I_footballgrl16_SERP_1073531_original-1x1-1-85b80952518e4f739df7825126e14b36.jpg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "32 ounces chocolate sandwich cookies with creme filling",
          "1 (8 ounce) package cream cheese, softened",
          "½ cup butter, softened",
          "½ cup confectioners' sugar",
          "3 ½ cups milk",
          "1 (12 ounce) container frozen whipped topping, thawed",
          "2 (3.5 ounce) packages instant vanilla pudding mix",
        ],
      },
    ],
    servings: "10",
    estTimeComplete: "20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "127",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 23,
    title: "Passion Fruit Hurricane",
    description:
      "A super sweet and boozy New Orleans cocktail that is a super delicious way to celebrate Mardi Gras - or any day.",
    link: "https://spicysouthernkitchen.com/passion-fruit-hurricane/",
    img: "https://spicysouthernkitchen.com/wp-content/uploads/passion-fruit-hurricane-3.jpg",
    imgSrc: [
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/passion-fruit-hurricane-3.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/passion-fruit-hurricane-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/passion-fruit-hurricane-5.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/passion-fruit-hurricane-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 1/2 cups passion fruit juice",
          "1/2 cup sugar, use superfine sugar if you have it",
          "1/2 cup lime juice",
          "1/2 cup light rum",
          "1/2 cup dark rum",
          "2 tablespoons grenadine syrup",
          "Orange slices and maraschino cherries",
        ],
      },
    ],
    servings: "4",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "128",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 4,
    title: "Black and White Brownie Bites",
    description:
      "Give your mini brownies a little something extra with this Black and White Brownie Bites project.",
    link: "https://wilton.com/black-and-white-brownie-bites/wlproj-9197/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8849/21464/pwclcsvcbwemwyspbixa__91212.1677143702.jpg?c=1",
    imgSrc: [
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8849/21464/pwclcsvcbwemwyspbixa__91212.1677143702.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8849/21464/pwclcsvcbwemwyspbixa__91212.1677143702.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Decorator Preferred White Fondant, 4.4 oz.",
          "Chocolate Ready-To-Use Decorator Icing - 1 lb.",
          "Decorator Preferred Black Fondant, 4.4 oz.",
          "Favorite brownie mix or recipe",
        ],
      },
      {
        section: "Tools",
        materialInfo: [
          "Cooling Rack",
          "Fondant Roller",
          "Cake Decorating Tools, 5-Piece Brush Set",
          "Double-Sided Round Cut-Outs Set, 6-Piece",
          "Heart Fondant Cut-Outs Set",
          "Recipe Right Mini Muffin Pan, 24-Cavity",
        ],
      },
    ],
    servings: "24 treats",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "129",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 20,
    title: "Godfater Cocktail",
    description:
      "The Godfather cocktail is a simple Scotch and amaretto drink that’s full of flavor! It pairs nutty sweetness with a spicy whiskey finish.",
    link: "https://www.acouplecooks.com/godfather-cocktail/",
    img: "https://www.acouplecooks.com/wp-content/uploads/2020/06/Godfather-Cocktail-009.jpg",
    imgSrc: [
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Godfather-Cocktail-009.jpg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "garnish",
        materialInfo: ["Lemon peel"],
      },
      {
        section: "Ingredients",
        materialInfo: [
          "1 ½ ounces (3 tablespoons) blended scotch",
          "½ ounce (1 tablespoon) amaretto",
        ],
      },
    ],
    servings: "1 drink",
    estTimeComplete: "2 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "130",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 0,
    title: "Chocolate Ripple Cheesecake",
    description:
      "With its rich, creamy filling and delightful chocolate ripple base, this dessert is a chocolate lover's dream come true.",
    link: "https://tasty.co/recipe/chocolate-ripple-cheesecake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/104649.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/104649.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 24 chocolate sandwich cookies",
          "4 tablespoons unsalted butter, melted",
          "32 oz cream cheese, 4 packages",
          "¾ cup sugar",
          "1 teaspoon vanilla extract",
          "4 cups heavy cream",
          " 1 ½ tablespoons gelatin powder",
          " 2 cups semi sweet chocolate, melted, divided",
        ],
      },
    ],
    servings: "8",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "131",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 33,
    title: "Mom's Cucumber Salad",
    description:
      "This is the recipe that my mom made when I was small. It is still one of my favorites. Some friends add a little green onion to the recipe. My kids and I just aren't raw onion fans. Enjoy!",
    link: "https://www.allrecipes.com/recipe/65442/moms-cucumber-salad/",
    img: "https://www.allrecipes.com/thmb/wyldjVhdTaBPWxQ2s6Rfz1yjpt0=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Moms-Cucumber-Salad-2000-ea9503d1ba5e4ea6984f55ccd675a6bb.jpeg",
    imgSrc: [
      {
        original:
          "https://www.allrecipes.com/thmb/wyldjVhdTaBPWxQ2s6Rfz1yjpt0=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Moms-Cucumber-Salad-2000-ea9503d1ba5e4ea6984f55ccd675a6bb.jpeg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 cup mayonnaise",

          "¼ cup white sugar",

          "4 teaspoons distilled white vinegar",

          "½ teaspoon dried dill weed",

          "½ teaspoon seasoned salt",

          "4 medium cucumbers, peeled and sliced",
        ],
      },
    ],
    servings: "8",
    estTimeComplete: "15 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "132",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 4,
    title: "Rosy Special Celebration Cake",
    description:
      "Whether you're celebrating a wedding, baby shower, birthday or anniversary, this Rosy Special Celebration Cake is a beautiful and eye-catching treat.",
    link: "https://wilton.com/rosy-special-celebration-cake/wlproj-9593/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8894/21536/vsjwaglva6tnvel0qyc9__63998.1677143751.jpg?c=1",
    imgSrc: [
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8894/21536/vsjwaglva6tnvel0qyc9__63998.1677143751.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8894/21536/vsjwaglva6tnvel0qyc9__63998.1677143751.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/320w/products/8894/21537/s8c6xtpt2jvydcthntk1__10510.1677143752.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/320w/products/8894/21537/s8c6xtpt2jvydcthntk1__10510.1677143752.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Favorite cake mix or recipe",
          "Ready-to-Use Gum Paste",
          "Royal Blue Icing Color",
          "Black Icing Color",
          "Pink Icing Color",
          "Creamy Peach Icing Color",
          "Violet Icing Color",
          "Moss Green Icing Color",
          "Creamy White Decorator Icing – 4 lb. Tub",
          "White Decorator Preferred Fondant – 5 lb. Box",
          "Gum Paste Adhesive",
          "Solid vegetable shortening",
        ],
      },

      {
        section: "Tools",
        materialInfo: [
          "Gum Paste Flower Cutter Set",
          "Flower Impression Mold",
          "5-Piece Decorating Brush Set",
          "Floral Tape",
          "Gum Paste Wire",
          "Fondant Shaping Foam",
          "1 in. foam balls (3 needed)",
          "Flower Wave Drying Rack (2 needed)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "133",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 33,
    title: "Sangria! Sangria!",
    description:
      "The best sangria recipe I've been able to hone to perfection for summer parties! I like to use a fruity Spanish red wine, such as Garnacha.",
    link: "https://www.allrecipes.com/recipe/72612/sangria-sangria/",
    img: "https://www.allrecipes.com/thmb/DNris9xYVUH_s4AUkPdxJL3udTU=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/72612-SangriaSangria-mfs-2X3-1483-b3b44735141c46ff889807f0968388ee.jpg",
    imgSrc: [
      {
        original:
          "https://www.allrecipes.com/thmb/DNris9xYVUH_s4AUkPdxJL3udTU=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/72612-SangriaSangria-mfs-2X3-1483-b3b44735141c46ff889807f0968388ee.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/DNris9xYVUH_s4AUkPdxJL3udTU=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/72612-SangriaSangria-mfs-2X3-1483-b3b44735141c46ff889807f0968388ee.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.allrecipes.com/thmb/nwsjgtVAN4eixXWP89bLgAQ67bI=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/72612-SangriaSangria-mfs-Step2-1469-5d77cdff92bc4ce29b0da6141a9caebc.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/nwsjgtVAN4eixXWP89bLgAQ67bI=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/72612-SangriaSangria-mfs-Step2-1469-5d77cdff92bc4ce29b0da6141a9caebc.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 (750 milliliter) bottle dry red wine",
          "½ cup brandy",
          "½ cup triple sec",
          "⅓ cup frozen lemonade concentrate",
          "⅓ cup orange juice",
          "¼ cup lemon juice",
          "¼ cup white sugar (Optional)",
          "1 medium orange, sliced into rounds",
          "1 medium lemon, sliced into rounds",
          "1 medium lime, sliced into rounds",
          "8 maraschino cherries",
          "2 cups carbonated water (Optional)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "15 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "134",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "The Best Soft And Chewy Snickerdoodle Cookies",

    description:
      "Indulge in the ultimate comfort treat with these soft and chewy Snickerdoodle cookies. Experience the perfect blend of cinnamon and sugar in every delightful bite.",
    link: "https://tasty.co/recipe/the-best-soft-and-chewy-snickerdoodle-cookies",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/96041.jpg",

    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/96041.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cookie Dough",
        materialInfo: [
          "2 teaspoons ground cinnamon",
          "1 cup butter, softened",
          "2 teaspoons vanilla",
          "1 cup sugar",
          "⅓ cup brown sugar",
          "2 eggs",
          "3 cups flour",
          "2 teaspoons cream of tartar",
          "2 teaspoons baking soda",
          "½ teaspoon salt",
        ],
      },
      {
        section: "Cinnamon Sugar Mix",
        materialInfo: ["¼ cup sugar", "1 tablespoon cinnamon"],
      },
    ],
    servings: "24",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "135",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 4,
    title: "Wacky and Wild Mini Smash Cakes",
    description:
      "Give your little one a cake of his or her own on their first birthday with these Wacky and Wild Mini Smash Cakes. Made to look like a leopard, zebra or bear, these cakes are ready for one wild party!",
    link: "https://wilton.com/wacky-and-wild-mini-smash-cakes/wlproj-9412/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8672/21180/tiulju31fctno47xu2w8__15117.1677143510.jpg?c=1",
    imgSrc: [
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8672/21180/tiulju31fctno47xu2w8__15117.1677143510.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8672/21180/tiulju31fctno47xu2w8__15117.1677143510.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21181/z7umslindl45fvwtur9y__44181.1677143510.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21181/z7umslindl45fvwtur9y__44181.1677143510.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21182/f8vsuouichjyrmcpj2uw__70995.1677143510.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21182/f8vsuouichjyrmcpj2uw__70995.1677143510.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21183/ipaqezlnrbmncvwzn8lj__22429.1677143510.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/160w/products/8672/21183/ipaqezlnrbmncvwzn8lj__22429.1677143510.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Favorite Cake Mix or Recipe",
          "cornstarch",
          "Decorator Preferred White Fondant, 24 oz. Fondant Icing",
          "Creamy White Decorator Icing, 4 lb. Tub",
          "Teal Icing Color, 1 oz.",
          "Black Icing Color, 1 oz.",
          "Rose Icing Color",
          "Violet Icing Color",
          "Gum-Tex",
        ],
      },
      {
        section: "Ingredients",
        materialInfo: [
          "Cooling Rack",
          "Fondant Roller, 9-Inch",
          "Cake Decorating Tools, 5-Piece Brush Set",
          "Fondant Cutter Set, 3-Piece Fondant Tools",
          "3D Figure Modeling Fondant Tool Set, 8-Piece Cake Decorating Tool Set",
          "No. 6 Round Disposable Cake Decorating Tip Set, 4-Piece",
          "Black Angled Icing Spatula, 9-Inch",
          "Round Cake Decorating Tip 4",
          "Open Star Cake Decorating Tip 6B",
          "Perfect Results Non-Stick Mini Muffin Pan, 24-Cup",
          "Decorating Bags, 16-Inch Disposable Piping Bags, 12-Count",
          "Geometric Print and Solid Green Mini Cupcake Liners, 100-Count",
          "Mini Round Cake Pan Set, 4-Piece",
          "6-Inch Round Cake Boards, 10-Count",
          "Color Swirl, 3-Color Piping Bag Coupler, 9-Piece Cake Decorating Kit",
          "Cupcake Decorating Icing Tips, 12-Piece Set",
          "Plastic wrap",
          "Gum Paste Succulents Cut-Outs Set, 9-Piece",
          "Double-Sided Round Cut-Outs Set, 6-Piece",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [fondant],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "136",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 20,
    title: "Classic Blueberry Muffins",
    description:
      "This is the best blueberry muffins recipe! The muffins come out tall, fluffy and golden, with an irresistibly sweet fruity flavor.",
    link: "https://www.acouplecooks.com/blueberry-muffins/",
    img: "https://www.acouplecooks.com/wp-content/uploads/2022/06/Blueberry-Muffins-006.jpg",
    imgSrc: [
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2022/06/Blueberry-Muffins-006.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2022/06/Blueberry-Muffins-006.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2022/06/Blueberry-Muffins-013.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2022/06/Blueberry-Muffins-013.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 ½ cups fresh blueberries*",
          "2 cups all-purpose flour",
          "2/3 cup granulated sugar",
          "1 teaspoon cinnamon",
          "⅛ teaspoon nutmeg",
          "1 tablespoon baking powder",
          "½ teaspoon kosher salt",
          "2 eggs",
          "¼ cup honey",
          "½ cup vegetable oil",
          "½ cup Greek yogurt (or sour cream)",
          "½ cup milk",
          "1 teaspoon apple cider vinegar",
          "Turbinado sugar, for topping",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "137",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 0,
    title: "Strawberry Santas",
    description:
      "These adorable strawberry santas are a festive addition to any holiday party. They're made with juicy strawberries, whipped cream, and chocolate chips for eyes, making them both cute and delicious.",
    link: "https://tasty.co/recipe/strawberry-santas",
    img: "https://img.buzzfeed.com/video-api-prod/assets/a7809442c20a4d14b6b6cf15062f05ad/BFV4922_StrawberrySantas_ThumbA.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/a7809442c20a4d14b6b6cf15062f05ad/BFV4922_StrawberrySantas_ThumbA.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 box strawberry, frozen will work too if fresh are not available",
          "1 tube icing, or substitute whipped cream for a fluffier santa",
          "mini chocolate chip, as needed",
        ],
      },
    ],
    servings: "15 Santas",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "138",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 4,
    title: "Two-Tone Candy-Dipped Cookies",
    description: "Fabulous two-tone candy-dipped cookies that are easy to make",
    link: "https://wilton.com/two-tone-candy-dipped-cookies/wlproj-7854/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/8721/21272/zbyqupp9yxkgs15kn7th__25669.1677143565.jpg?c=1",
    imgSrc: [
      {
        original: "",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Roll-Out Cookie Dough",
          "Yellow Candy Melts® Candy",
          "Blue Candy Melts® Candy",
          "Orange Candy Melts® Candy",
          "Lavender Candy Melts® Candy",
          "Dark Green Candy Melts® Candy",
          "Bright Pink Candy Melts Candy",
          "White Sparkling Sugar",
        ],
      },
      {
        section: "Tools",
        materialInfo: [
          "Rolling Pin",
          "3 Inch Metal Circle Cutter",
          "14 x 20 in. Jumbo Aluminum Cookie Sheet",
          "Short Dipping Containers",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "153",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 18,
    title: "Easy Strawberries and Cream Recipe",
    description: "It's Such A Light And Refreshing Dessert.",
    link: "https://lollyjane.com/easy-strawberries-cream-recipe/",
    img: "https://lollyjane.com/wp-content/uploads/2015/05/easy-strawberries-and-cream-recipe.jpg",
    imgSrc: [
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2015/05/strawberries-and-cream-recipe-1.jpg",
        thumbnail:
          "https://lollyjane.com/wp-content/uploads/2015/05/strawberries-and-cream-recipe-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2015/05/easy-strawberries-and-cream-recipe.jpg",
        thumbnail:
          "https://lollyjane.com/wp-content/uploads/2015/05/easy-strawberries-and-cream-recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "4 cups  of strawberries",
          "1  cup of whipping cream or half and half",
          "1/2 cup of granulated sugar",
          "1 1/2 tsp. of vanilla extract",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[8],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "139",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 0,
    title: "Mint Chocolate Cookie Dome Cake",
    description:
      "The cookies give crunch on the outside, the brownie serves as a soft chewy base, and the mousse inside perfectly balances mint flavor and sweetness. ",
    link: "https://tasty.co/recipe/mint-chocolate-cookie-dome-cake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/205439.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/205439.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "10 oz brownie mix, 1 box, batter prepared according to package instructions",
          "3 ¼ cups heavy cream, plus 1/2 (120 ml) cup, hot, divided",
          "½ teaspoon peppermint extract",
          "½ cup granulated sugar",
          "3 drops green gel food coloring",
          " 1 cup white chocolate chip",
          "1 cup mini chocolate chips",
          "30 mint chocolate cookies, crushed plus 45 whole, divided",
          "fresh mint leaf, for garnish",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "141",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Cookies and Cream Cheesecake Cookies",
    description:
      "Indulge in these heavenly Cookies and Cream Cheesecake Cookies that'll make your taste buds dance with delight.",
    link: "https://tasty.co/recipe/cookies-and-cream-cheesecake-cookies",
    img: "https://img.buzzfeed.com/video-api-prod/assets/a789cfc789734873880b07df1eeab8c3/cheesecake_cookies_FB_thumb.jpg",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/video-api-prod/assets/a789cfc789734873880b07df1eeab8c3/cheesecake_cookies_FB_thumb.jpg?resize=300:*&output-format=jpg&output-quality=auto",
        thumbnail: "",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "crust",
        materialInfo: [
          "4 oz cream cheese, softened",
          "8 tablespoons salted butter, room temperature",
          "¾ cup sugar",
          "1 cup flour",
          "10 cookies, crushed",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "142",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 4,
    title: "Beautiful Blooming Cookies",
    description:
      "Great for gifting for Mother’s Day, birthdays, weddings, and showers, these lovely floral cookies are decorated using buttercream frosting and beginner piping techniques",
    link: "https://wilton.com/beautiful-blooming-cookies/wlproj-9604/",
    img: "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/7965/20031/bvozyaapdwyqdskqksoj__73350.1677142739.jpg?c=1",
    imgSrc: [
      {
        original:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/7965/20031/bvozyaapdwyqdskqksoj__73350.1677142739.jpg?c=1",
        thumbnail:
          "https://cdn11.bigcommerce.com/s-vm6doh2w4n/images/stencil/960w/products/7965/20031/bvozyaapdwyqdskqksoj__73350.1677142739.jpg?c=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Roll-Out Sugar Cookie Recipe",
          "Creamy White Decorator Icing - 4 lb. Tub",
          "Lemon Yellow Icing Color",
          "Orange Icing Color",
          "Christmas Red Icing Color",
          "Rose Icing Color",
          "Black Icing Color",
        ],
      },
      {
        section: "Ingredients",
        materialInfo: [
          "Rolling Pin",
          "Double Cut-Outs Set",
          "Cookie Sheet",
          "Cooling Grid",
          "9 in. Angled Spatula",
          "Disposable Decorating Bags",
          "Round Decorating Tip 2 (2 needed)",
          "Round Decorating Tip 3",
          "Round Decorating Tip 5",
          "Petal Decorating Tip 104 (2 needed)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "143",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 33,
    title: "The REAL Long Island Iced Tea",
    description:
      "There are a few impostors out there that claim to be Long Island Iced Teas. In actuality, there is only one correct way to make a LIIT... and this is it!",
    link: "https://www.allrecipes.com/recipe/228491/the-real-long-island-iced-tea/",
    img: "https://www.allrecipes.com/thmb/4LRIyHL_6hr-a0PjFv7D7VdquXs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/228491-The-Real-Long-Island-Iced-Tea-mfs-Step3_003-741cf9cc29524821931754466c3849eb.jpg",
    imgSrc: [
      {
        original:
          "https://www.allrecipes.com/thmb/4LRIyHL_6hr-a0PjFv7D7VdquXs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/228491-The-Real-Long-Island-Iced-Tea-mfs-Step3_003-741cf9cc29524821931754466c3849eb.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/4LRIyHL_6hr-a0PjFv7D7VdquXs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/228491-The-Real-Long-Island-Iced-Tea-mfs-Step3_003-741cf9cc29524821931754466c3849eb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.allrecipes.com/thmb/4vxoyeukExUQMexNNPHK63ZJ67I=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/228491-The-Real-Long-Island-Iced-Tea-DDMFS-4x3-73f1aea4b2ec4470a3fe69d1e437f71c.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/4vxoyeukExUQMexNNPHK63ZJ67I=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/228491-The-Real-Long-Island-Iced-Tea-DDMFS-4x3-73f1aea4b2ec4470a3fe69d1e437f71c.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "½ fluid ounce vodka",
          "½ fluid ounce rum",
          "½ fluid ounce gin",
          "½ fluid ounce tequila",
          "½ fluid ounce triple sec (orange-flavored liqueur)",
          "1 fluid ounce sweet and sour mix",
          "1 fluid ounce cola, or to taste",
          "1 lemon slice",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "144",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Java Chip Caffeinated Smoothie",
    description:
      "Wake up and energize with this Java Chip Caffeinated Smoothie, a delightful blend of coffee, banana, and chocolate chips.",
    link: "https://tasty.co/recipe/java-chip-caffeinated-smoothie",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/4803b46c17f3468e9c2c2e817caf945e/FB_FINAL.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/4803b46c17f3468e9c2c2e817caf945e/FB_FINAL.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 ½ cups coffee, brewed, cooled",
          "½ cup half & half",
          " ⅓ cup chocolate chips, plus more, crushed for topping",
          "2 tablespoons sugar",
          " 6 coffee beans",
          " whipped cream, for topping",
        ],
      },
    ],
    servings: "2",
    estTimeComplete: "Under 30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "145",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 14,
    title: "Dark Chocolate Dipped Oranges",
    description:
      "All you need are Cuties (or any clementine orange) and dark chocolate. My favorite dark chocolate for dipping is Ghiradelli Chocolate Melting Wafers but you can use any dark chocolate you’d like. If you’re not a fan of dark chocolate you can use milk chocolate too",
    link: "https://tastefullyfrugal.org/dark-chocolate-dipped-oranges/",
    img: "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-3.jpg",
    imgSrc: [
      {
        original:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-HERO.jpg",
        thumbnail:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-HERO.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-3.jpg",
        thumbnail:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-2.jpg",
        thumbnail:
          "https://i3.wp.com/tastefullyfrugal.org/wp-content/uploads/2016/01/Choc-Dipped-Oranges-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1/2 cup dark chocolate melting wafers",
          "4-5 Cuties clementine oranges",
        ],
      },
    ],
    servings: "3",
    estTimeComplete: "4 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[8],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "146",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "Ice Cream Sandwich Cake",
    description:
      "This cool and refreshing dessert is perfect for a hot summer day.",
    link: "https://tasty.co/recipe/ice-cream-sandwich-cake",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/541f974ff16b4db6b8cadc63f97847a6/BFV21152_Ice_Cream_Sandwich_Cake_FB.jpg?resize=600:*&output-format=auto&output-quality=auto",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/541f974ff16b4db6b8cadc63f97847a6/BFV21152_Ice_Cream_Sandwich_Cake_FB.jpg?resize=600:*&output-format=auto&output-quality=auto",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 12 ice cream sandwiches",
          "2 pt chocolate ice cream, softened",
          "8 oz whipped topping, 1 container",
          " 12 sandwich cookies, crushed",
        ],
      },
    ],
    servings: "9",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "147",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 14,
    title: "S'mores Apples",
    description:
      "Every bite brings a new mix of flavors and it is (almost) like biting in to a s'more hot out of the fire pit!",
    link: "https://tastefullyfrugal.org/when-summer-meets-fall-a-delicious-treat/",
    img: "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2014/10/SmoresApple1.jpg",
    imgSrc: [
      {
        original:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2014/10/SmoresApple1.jpg",
        thumbnail:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2014/10/SmoresApple1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2014/10/SmoresApple2.jpg",
        thumbnail:
          "https://i1.wp.com/tastefullyfrugal.org/wp-content/uploads/2014/10/SmoresApple2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Honey Crisp (or your favorite) Apples",
          "Jet Puffed Marshmallow Creme",
          "Chocolate Chips (about 1/4 cup per apple)",
          "Graham Crackers (1/2 a long cracker per apple)",
          "",
          "",
        ],
      },
      {
        section: "Tools",
        materialInfo: ["Lollipop Sticks"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[7],
    productId: "148",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Pineapple Bay Breeze",
    description:
      "Fresh and fruity Pineapple Bay Breeze is a perfect mix of pineapple, cranberry, and orange shaken with ice and finished with a twist of lime!",
    link: "https://realhousemoms.com/pineapple-breeze/",
    img: "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-IC-3.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-IC-2.jpg",

        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-IC-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-HERO-scaled.jpg",

        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-HERO-scaled.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-IC-3.jpg",

        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Pineapple-Bay-Breeze-IC-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 ounces pineapple rum",
          "1.5 ounces cranberry juice",
          "0.5 ounce orange juice",
          "1/2 lime",
        ],
      },
      {
        section: "Garnish",
        materialInfo: [
          "Lime slice",
          "Pineapple wedge",
          "Crushed ice",
          "Frozen pineapple chunks (optional)",
        ],
      },
    ],
    servings: "1 Cocktail",
    estTimeComplete: "2 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "149",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 0,
    title: "The Best Chewy Chocolate Chip Cookies",
    description:
      "There are a few secrets to the best classic, chewy chocolate chip cookies. Number one: Don’t use chips; instead, opt for a mix of milk or semisweet and dark chocolate chunks. The second is to let the dough rest overnight or longer for a more complex, toffee-like flavor. Lastly, use an ice cream scooper to get even-sized cookies every time. And that’s it! With these little tweaks, the result is a cookie that’s textured on the outside, and soft and gooey on the inside. Absolutely perfect!",
    link: "https://tasty.co/recipe/the-best-chewy-chocolate-chip-cookies",
    img: "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/62298.jpg",
    imgSrc: [
      {
        original:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/62298.jpg",
        thumbnail:
          "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/62298.jpg",
        originalHeight: "320px",

        thumbnailHeight: "90px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "½ cup granulated sugar",
          " ¾ cup brown sugar, packed",
          "1 teaspoon salt",
          "½ cup unsalted butter, melted",
          "1 egg",
          "1 teaspoon vanilla extract",
          "1 ¼ cups all-purpose flour",
          " ½ teaspoon baking soda",
          "4 oz milk or semi-sweet chocolate chunks",
          " 4 oz dark chocolate chunk, or your preference",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "150",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 18,
    title: "Summer Berry Smoothie recipe",
    description:
      "I used to just put one on top of the other, but I love putting a small layer of yogurt in the middle. This truly can work with any mixture of smoothies you and your family like.",
    link: "https://lollyjane.com/summer-berry-smoothie-recipe/",
    img: "https://lollyjane.com/wp-content/uploads/2016/07/06-18510-post/summer-berry-smoothie.jpg",
    imgSrc: [
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2016/07/06-18510-post/summer-berry-smoothie.jpg",
        thumbnail:
          "https://lollyjane.com/wp-content/uploads/2016/07/06-18510-post/summer-berry-smoothie.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Top Layer",
        materialInfo: [
          "1 cup vanilla Greek yogurt",
          "-1 1/2 cups mango nectar",
          "2 cups frozen blueberries",
          "1/2 large banana",
          "1/2 cup ice",
        ],
      },
      {
        section: "Middle",
        materialInfo: ["1 cup vanilla Greek yogurt"],
      },
      {
        section: "Bottom Layer",
        materialInfo: [
          "1 cup vanilla Greek yogurt",
          "1-1 1/2 cups orange juice",
          "2 cups frozen strawberries",
          "1/2 large banana",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: [FOOD_VALUE_TYPES[1], FOOD_VALUE_TYPES[7]],
    productId: "153",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 37,
    title: "Jack Daniels Honey Whiskey Cupcakes with a Boozy Drizzle",
    description:
      "A rich cupcake with Jack Daniels Honey Whiskey in the cake, the frosting, and the drizzle. They are out of this world!",
    link: "https://creative-culinary.com/jack-daniels-honey-whiskey-cupcakes-recipe/",
    img: "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes4-Rev1.jpg",
    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes4-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes4-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes2-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes2-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes1-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes1-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes1-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/honey-whiskey-cupcakes1-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "¾ cup butter softened",
          "1 cup sugar",
          "2 eggs",
          "1 tsp vanilla bean paste or vanilla extract",
          "1 ½ cups flour",
          "½ tsp salt",
          "1 ¼ tsp baking powder",
          "3 Tbsp Jack Daniels Honey Whiskey",
          "3 Tbsp Milk",
        ],
      },
      {
        section: "Boozy Drizzle",
        materialInfo: [
          "¾ cup brown sugar",
          "½ cup Jack Daniels Honey Whiskey",
          "1 tablespoon unsalted butter",
        ],
      },
      {
        section: "Buttercream Frosting",
        materialInfo: [
          "1 cup butter room temperature",
          "4 cups powdered sugar",
          "1 tsp vanilla bean paste or vanilla extract",
          "2 Tbsp Jack Daniels Honey Whiskey",
          "¼ cup heavy cream as much as needed to get correct consistency",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "40 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[4],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "154",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 18,
    title: "Buckeye Pretzels",
    description:
      "I am a sucker for all things peanut butter! If you are a peanut butter fan, I know these Buckeye Pretzels are going to put a big smile on your face. I mean come on, peanut butter sandwiched between two pretzels and then dipped in chocolate, I think it’s pretty much a no brainer!",
    link: "https://lollyjane.com/easy-buckeye-pretzels-recipe/",
    img: "https://lollyjane.com/wp-content/uploads/2015/10/19-16453-post/easy-buckeye-pretzels-recipe.jpg",
    imgSrc: [
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2015/10/19-16453-post/buckeye-pretzels-recipe.jpg",
        thumbnail:
          "https://lollyjane.com/wp-content/uploads/2015/10/19-16453-post/buckeye-pretzels-recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://lollyjane.com/wp-content/uploads/2015/10/19-16453-post/easy-buckeye-pretzels-recipe.jpg",
        thumbnail:
          "https://lollyjane.com/wp-content/uploads/2015/10/19-16453-post/easy-buckeye-pretzels-recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 cup of creamy peanut butter",
          "1 ½ cup of powder sugar",
          "½ tsp. of vanilla extract",
          "¼ cup of butter, softened",
          "1 bag of waffle pretzels",
          "2 cups of milk chocolate dipping chocolate",
        ],
      },
    ],
    servings: "Makes about 6 dozen",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "168",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 16,
    title: "Brown Sugar Frosting",
    description:
      "This brown sugar frosting is smooth, creamy, and perfect with any dessert! It's delicious and can be piped onto cakes, cupcakes, cookies, and more!",
    link: "https://chelsweets.com/brown-sugar-frosting/",
    img: "https://chelsweets.com/wp-content/uploads/2023/01/brown-sugar-frosting-v3.jpg.webp",
    imgSrc: [
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2023/01/brown-sugar-frosting-v3.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2023/01/brown-sugar-frosting-v3.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2023/01/bitten-into-slices-of-cookie-dough-cake-most.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2023/01/bitten-into-slices-of-cookie-dough-cake-most.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2023/01/brown-sugar-buttercream-in-glass-bowl.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2023/01/brown-sugar-buttercream-in-glass-bowl.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 cups unsalted butter, room temperature (454g)",
          "1 cup packed, light brown sugar (200g)",
          "1 Tbsp vanilla extract (12g)",
          "1/2 tsp fine salt (3g)",
          "7 cups powdered sugar (904g or a 2 lb. bag)",
          "1/4 cup heavy cream or whipping cream (60g)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "1 hr 5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "155",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 20,
    title: "Classic Strawberry Shortcake",
    description:
      "Tender, biscuit-like shortcakes, juicy sweet berries, and fluffy, homemade whipped cream.",
    link: "https://www.acouplecooks.com/strawberry-shortcake/",
    img: "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-006.jpg",
    imgSrc: [
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-003.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-003.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-006.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-006.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-023.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2020/06/Strawberry-Shortcake-023.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "For the strawberries",
        materialInfo: [
          "1 pound ripe strawberries",
          "3 tablespoons sugar",
          "1 ½ tablespoons lemon juice",
          "",
          "",
          "",
        ],
      },
      {
        section: "For the shortcakes",
        materialInfo: [
          "2 cups all-purpose flour",
          "3 tablespoons sugar, plus more for sprinkling",
          "2 teaspoons baking powder",
          "½ teaspoon baking soda",
          "1 teaspoon kosher salt",
          "1 teaspoon lemon zest (or 1 tablespoon orange zest*)",
          "6 tablespoons cold unsalted butter",
          "¾ cup plus 1 tablespoon cold buttermilk, plus more for brushing",
          "Optional: turbinado sugar for topping",
        ],
      },
    ],
    servings: "8",
    estTimeComplete: "",
    directions: [
      "Macerating the strawberries. Macerate means to let them sit with sugar so they break down.",
      "Making the shortcakes. You'll make lemon-scented, biscuit-like cakes (not spongy or fluffy).",
      "Making homemade whipped cream. A basic skill for any home cook to master!",
    ],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[4],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "156",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Funnel Cake",
    description:
      "Nothing smells more delectable and mouth-watering than a funnel cake!",
    link: "https://realhousemoms.com/funnel-cake-recipe/",
    img: "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-5.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-5.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-4.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-3.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Funnel-Cake-IC-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 cups all-purpose flour",
          "3 tablespoons granulated sugar",
          "1 1/2 teaspoons baking powder",
          "1/2 teaspoon salt",
          "2 large eggs at room tempature",
          "1 1/4 cups whole milk",
          "1 teaspoon vanilla extract",
          "4 cups neutral oil for frying",
          "Powdered sugar for serving",
        ],
      },
      {
        section: "Tools",
        materialInfo: [
          "Mixing Bowls",
          "Large Pot",
          "Funnel",
          "Canyd Thermometer",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "50 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "157",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 23,
    title: "The Best Jiffy Cornbread",
    description:
      "This is the BEST Jiffy Cornbread. So moist and delicious. Slather it with butter and you will think you are tasting a little bite of heaven.",
    link: "https://spicysouthernkitchen.com/the-best-jiffy-cornbread/",
    img: "https://spicysouthernkitchen.com/wp-content/uploads/Jiffy-Cornbread.jpg",
    imgSrc: [
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Jiffy-Cornbread.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Jiffy-Cornbread.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Jiffy-Cornbread-5.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Jiffy-Cornbread-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 cup sour cream",
          "2/3 cup milk",
          "2 large eggs",
          "1/2 cup butter, melted",
          "2 boxes Jiffy corn muffin mix",
          "1 tablespoon sugar",
        ],
      },
      {
        section: "Tools",
        materialInfo: ["9×13-inch Baking Pan", "Whisk"],
      },
    ],
    servings: "12",
    estTimeComplete: "30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: [FOOD_VALUE_TYPES[0], FOOD_VALUE_TYPES[2]],
    productId: "158",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 21,
    title: "Fluffy Cinnamon Swirl Muffins",
    description:
      "The satisfaction of a cinnamon roll with the ease of coffee cake. These FLUFFY cinnamon swirl muffins are perfectly moist, easy to make, and the ideal companion for your holiday or weekend mornings",
    link: "https://minimalistbaker.com/fluffy-cinnamon-swirl-muffins-vegan-gf/",
    img: "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-8.jpg",
    imgSrc: [
      {
        original:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-8.jpg",
        thumbnail:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-8.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-7.jpg",
        thumbnail:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-7.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-9.jpg",
        thumbnail:
          "https://minimalistbaker.com/wp-content/uploads/2022/10/Vegan-Gluten-Free-Cinnamon-Swirl-Muffins-9.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cinnamon Sugar",
        materialInfo: ["1/4 cup cane sugar", "2 tsp cinnamon"],
      },
      {
        section: "Muffins",
        materialInfo: [
          "2 Tbsp flaxseed meal (ground flax seeds)",
          "1 cup filtered water",
          "1/3 cup organic cane sugar (or sub coconut sugar, but the muffins will be darker and taste more wholesome)",
          "1/4 cup melted vegan butter",
          "1 ½ tsp vanilla extract",
          "2 cups almond flour ",
          "3/4 cup potato starch (NOT potato flour)",
          "1/3 cup brown rice flour",
          "2 ½ tsp baking powder",
          "1/2 tsp sea salt",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "45 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "159",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Peanut Butter Blossoms",
    description:
      "These classic cookies are always a treat and this is by far the best Peanut Butter Blossom cookie recipe out there!",
    link: "https://realhousemoms.com/peanut-butter-blossoms/#recipe",
    img: "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-6.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-6.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-6.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-8.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-8.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-5.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Peanut-Butter-Blossoms-IC-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 1/2 cups all-purpose flour",
          "1/2 teaspoon baking soda",
          "1/2 teaspoon salt",
          "1/2 cup unsalted butter at room temperature",
          "1 cup brown sugar lightly packed",
          "1 large egg",
          "1 cup creamy peanut butter",
          "1 teaspoon vanilla extract",

          "46 Hershey's kisses unwrapped",
        ],
      },
    ],
    servings: "46",
    estTimeComplete: "48 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: [FOOD_VALUE_TYPES[0], FOOD_VALUE_TYPES[6]],
    productId: "160",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 23,
    title: "Triple Chocolate Banana Bread",
    description:
      "Triple Chocolate Banana Bread is super moist and full of chocolate flavor. This is a chocolate lover’s banana bread for sure.",
    link: "https://spicysouthernkitchen.com/triple-chocolate-banana-bread/",
    img: "https://spicysouthernkitchen.com/wp-content/uploads/Triple-Chocolate-Banana-Bread.jpg",
    imgSrc: [
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/Triple-Chocolate-Banana-Bread.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/Triple-Chocolate-Banana-Bread.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://spicysouthernkitchen.com/wp-content/uploads/chocolate-banana-2.jpg",
        thumbnail:
          "https://spicysouthernkitchen.com/wp-content/uploads/chocolate-banana-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 1/2 cups all-purpose flour",
          "1/2 cup unsweetened cocoa powder",
          "1 teaspoon baking soda",
          "1/4 teaspoon salt",
          "1/2 cup butter, softened",
          "1/4 cup packed light brown sugar",
          "1/2 cup white sugar",
          "2 eggs",
          "1 1/3 cups mashed banana",
          "1 teaspoon vanilla extract",
          "1 cup semi-sweet chocolate chips",
        ],
      },
      {
        section: "Glaze",
        materialInfo: [
          "1/4 cup half-and-half",
          "2 tablespoons packed brown sugar",
          "1 teaspoon butter",

          "1/2 teaspoon vanilla",
          "1/3 cup semi-sweet chocolate chips",
        ],
      },
    ],
    servings: "10",
    estTimeComplete: "1 hr 5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "161",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Fruit Pizza",
    description:
      "Easy fruit pizza is a tasty treat made with a from-scratch sugar cookie crust, topped with a smooth cream cheese frosting and decorated with plenty of fresh fruit!",
    link: "https://realhousemoms.com/fruit-pizza/#recipe",
    img: "https://realhousemoms.com/wp-content/uploads/Easy-Fruit-Pizza-Recipe-Easy-Dessert-Recipe-IC2.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Easy-Fruit-Pizza-Recipe-Easy-Dessert-Recipe-IC2.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Easy-Fruit-Pizza-Recipe-Easy-Dessert-Recipe-IC2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Easy-Fruit-Pizza-Recipe-Easy-Dessert-Recipe-IC1.png",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Easy-Fruit-Pizza-Recipe-Easy-Dessert-Recipe-IC1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cookie Crust",
        materialInfo: [
          "1 cup unsalted butter softened to room temperature",
          "1 cup granulated sugar",
          "1 1/2 teaspoons vanilla extract",
          "1 large egg",
          "2 1/2 cups all-purpose flour",
          "3/4 teaspoon baking powder",

          "3/4 teaspoon salt",
        ],
      },
      {
        section: "Frosting",
        materialInfo: [
          "8 oz cream cheese",
          "4 Tablespoons unsalted butter",
          "¼ teaspoon salt",
          "½ teaspoon vanilla extract",
          "2 1/2 cups powdered sugar",
          "1-2 Tablespoon milk",
          "4 cups Assorted fruit (I used sliced strawberries peeled and sliced kiwis, blueberries, and blackberries)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "162",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 22,
    title: "Grilled Fruit Kabobs with Brown Sugar Dip",
    description:
      "Perfect summer dessert- fire up the grill, not the oven, and enjoy sweet summer flavors!",
    link: "https://realhousemoms.com/grilled-fruit-kabob-easy-sweet-dip/#recipe",
    img: "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-4.jpg",
    imgSrc: [
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-4.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-2.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-6.jpg",
        thumbnail:
          "https://realhousemoms.com/wp-content/uploads/Grilled-Fruit-Kabobs-with-Brown-Sugar-Dip-IC-6.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Grilled Fruit Kabobs",
        materialInfo: [
          "Watermelon rind removed",
          "Cantaloupe rind and seeds removed",
          "Pineapple rind and core removed",
          "Nectarines pitted",
          "Strawberries hulled",
          "Bananas peel removed",
        ],
      },
      {
        section: "Brown Sugar Dip",
        materialInfo: [
          "16 ounces light sour cream",
          "3/4 cup light brown sugar packed",
          "1/2 teaspoon vanilla extract",
        ],
      },
    ],
    servings: "10",
    estTimeComplete: "20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: [FOOD_VALUE_TYPES[2], FOOD_VALUE_TYPES[6]],
    productId: "163",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 20,
    title: "Chocolate Covered Bananas",
    description:
      "Frozen chocolate covered bananas are the perfect easy and healthy dessert! Store them in the freezer for whenever you crave a sweet bite.",
    link: "https://www.acouplecooks.com/chocolate-covered-bananas/",
    img: "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-009.jpg",
    imgSrc: [
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-010.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-010.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-002.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-002.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-019.jpg",
        thumbnail:
          "https://www.acouplecooks.com/wp-content/uploads/2021/02/Chocolate-Covered-Bananas-019.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 ripe medium bananas",
          "1 ½ cup dark chocolate chips* (5 ounces)",
          "1 tablespoon refined coconut oil",
          "1 pinch salt",
          "Toppings (optional): 2 tablespoons each crushed peanuts, crushed pistachios, and all natural sprinkles",
        ],
      },
    ],
    servings: "30-35 bites",
    estTimeComplete: "1 hr 30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "164",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 33,
    title: "Perfect Summer Fruit Salad",
    description:
      "This fruit salad recipe is bright, colorful, and incredibly delicious. It is tastier the longer you can let it soak in its juices. I prefer 3 to 4 hours in the refrigerator before I serve it",
    link: "https://www.allrecipes.com/recipe/214947/perfect-summer-fruit-salad/",
    img: "https://www.allrecipes.com/thmb/hz--Dm4jMRFfQVZynHfGG27fNJs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/2289556-0981629410f0446d9bec11f0a9ece43c.jpg",
    imgSrc: [
      {
        original:
          "https://www.allrecipes.com/thmb/hz--Dm4jMRFfQVZynHfGG27fNJs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/2289556-0981629410f0446d9bec11f0a9ece43c.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/hz--Dm4jMRFfQVZynHfGG27fNJs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/2289556-0981629410f0446d9bec11f0a9ece43c.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.allrecipes.com/thmb/hz--Dm4jMRFfQVZynHfGG27fNJs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/2289556-0981629410f0446d9bec11f0a9ece43c.jpg",
        thumbnail:
          "https://www.allrecipes.com/thmb/hz--Dm4jMRFfQVZynHfGG27fNJs=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/2289556-0981629410f0446d9bec11f0a9ece43c.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Sauce",
        materialInfo: [
          "⅔ cup fresh orange juice",
          "⅓ cup fresh lemon juice",
          "⅓ cup packed brown sugar",
          "½ teaspoon grated orange zest",
          "½ teaspoon grated lemon zest",
          "1 teaspoon vanilla extract",
        ],
      },
      {
        section: "Salad",
        materialInfo: [
          "2 cups cubed fresh pineapple",
          "2 cups strawberries, hulled and sliced",
          "3 kiwi fruit, peeled and sliced",
          "3 bananas, sliced",
          "2 oranges, peeled and sectioned",
          "1 cup seedless grapes",
          "2 cups blueberries",
        ],
      },
    ],
    servings: "10",
    estTimeComplete: "3 hrs 30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "165",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 11,
    title: "American Buttercream Frosting",
    description:
      "An American-style buttercream adjusted to be slightly softer and less sweet than traditional American buttercream",
    link: "https://bakingbutterlylove.com/simple-buttercream-frosting-thats-not-too-sweet/",
    img: "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2020/07/IMG_7231.jpg?w=600&ssl=1",
    imgSrc: [
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2020/07/IMG_7231.jpg?w=600&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2020/07/IMG_7231.jpg?w=600&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2020/07/IMG_7228-2.jpg?w=600&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2020/07/IMG_7228-2.jpg?w=600&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "330 grams unsalted butter, around 68F/20C",
          ".5 teaspoon kosher salt*",
          "495 grams confectioner's sugar or icing sugar, sifted",
          "45 grams full-fat Greek yogurt**",
          "1.5 teaspoons vanilla extract",
        ],
      },
    ],
    servings: "5 cups",
    estTimeComplete: "15 min",
    directions: [],
    tutorials: [buttercreamFilling],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "166",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 11,
    title: "Jewish Apple Cake",
    description:
      "Jewish Apple Cake is super easy to make and a real crowd pleasing recipe. It's simply apples and cinnamon in a moist bundt cake with addictively crispy edges.",
    link: "https://bakingbutterlylove.com/jewish-apple-cake/",
    img: "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3638-e1540244300527.jpg?resize=900%2C1125&ssl=1",
    imgSrc: [
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3638-e1540244300527.jpg?resize=900%2C1125&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3638-e1540244300527.jpg?resize=900%2C1125&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3619-683x1024.jpg?resize=683%2C1024",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3619-683x1024.jpg?resize=683%2C1024",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3625-683x1024.jpg?resize=683%2C1024",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2018/10/IMG_3625-683x1024.jpg?resize=683%2C1024",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "5 apples peeled, cored, and sliced (apples that are about 6 oz each or 20 oz of sliced apples)",
          "2 teaspoons cinnamon",
          "65 g or 5 tablespoons sugar",
          "360 grams or 3 cups all purpose flour",
          "500 grams or 2 1/2 cups sugar",
          "1 teaspoon kosher salt",
          "3 teaspoons baking powder",
          "200 grams or 1 cup vegetable oil",
          "200 grams or 4 large eggs",
          "1 tablespoon vanilla extract",
          "80 grams or 1/3 cup orange juice (about 1 large orange)",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "2 hrs",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "167",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 11,
    title: "How to Pipe Buttercream Cherry Blossoms",
    description:
      "Cherry blossoms are such sweet little flowers and always a welcome sight when spring comes around. They are relatively easy to pipe with buttercream and can be piped either directly on your cake or on parchment paper squares for more precise placement.",
    link: "https://bakingbutterlylove.com/how-to-pipe-buttercream-cherry-blossoms/",
    img: "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/IMG_4530-e1564435855684.jpg?resize=800%2C1000&ssl=1",
    imgSrc: [
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/IMG_4530-e1564435855684.jpg?resize=800%2C1000&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/IMG_4530-e1564435855684.jpg?resize=800%2C1000&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/IMG_4536.jpg?resize=683%2C1024&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/IMG_4536.jpg?resize=683%2C1024&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/piping-centers.jpg?resize=1024%2C576&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/07/piping-centers.jpg?resize=1024%2C576&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Tools",
        materialInfo: [
          "piping bags",
          "petal piping tip (I like a tip #104 for small, delicate petals)",
          "small round piping tip (#6 is great for piping small buds)",
          "coupler (if you want to use the same bag of icing for petals and buds)",
          "flower nail and parchment paper (if you are not piping directly on the cake)",
          "pink and yellow food coloring",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [pipeBlossom],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "167",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 16,
    title: "Peanut Butter Cream Cheese Frosting",
    description:
      "This delicious peanut butter cream cheese frosting recipe is delicious and so easy to make! It's perfect for frosting cakes or piping on cupcakes.",
    link: "https://chelsweets.com/peanut-butter-cream-cheese-frosting/",
    img: "https://chelsweets.com/wp-content/uploads/2022/07/peanut-butter-frosting-in-kitchen-aid-bowl-1638x2048.jpg.webp",
    imgSrc: [
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2022/07/peanut-butter-frosting-in-kitchen-aid-bowl-1638x2048.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2022/07/peanut-butter-frosting-in-kitchen-aid-bowl-1638x2048.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2021/12/cross-section-of-butterfinger-cake-3.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2021/12/cross-section-of-butterfinger-cake-3.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2022/07/finished-spice-cupcake-frosted-with-biscoff-buttercream-higher-angle.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2022/07/finished-spice-cupcake-frosted-with-biscoff-buttercream-higher-angle.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "  1 cup unsalted butter, room temperature (226g)",
          "1/2 cup creamy peanut butter (125g)",
          "1/2 cup full fat cream cheese, room temperature (113g)",
          "2 tsp vanilla extract (8g)",
          "1 tsp fine salt (6g)",
          "7 cups powdered sugar (907g or a 2 lb. bag)",

          "1/4 cup heavy cream or whipping cream (60g)",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[8],
    productId: "169",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 16,
    title: "Bubblegum Frosting",
    description:
      "This pink bubblegum frosting tastes just like bubblegum! It's perfect for frosting cakes, cupcakes, and cookies",
    link: "https://chelsweets.com/bubblegum-frosting/",
    img: "https://chelsweets.com/wp-content/uploads/2023/01/finished-finished-bubblegum-cake-off-to-right-2.jpg.webp",
    imgSrc: [
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2023/01/finished-finished-bubblegum-cake-off-to-right-2.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2023/01/finished-finished-bubblegum-cake-off-to-right-2.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2023/01/bubblegum-buttercream-frosting.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2023/01/bubblegum-buttercream-frosting.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://chelsweets.com/wp-content/uploads/2022/08/adding-bubblegum-balls-to-top-of-bubblegum-cake.jpg.webp",
        thumbnail:
          "https://chelsweets.com/wp-content/uploads/2022/08/adding-bubblegum-balls-to-top-of-bubblegum-cake.jpg.webp",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 2 cups unsalted butter, room temperature (452g)",
          "1 tsp bubblegum extract (4g)",
          "1 tsp vanilla extract (4g)",
          "1 tsp fine salt (6g)",
          "7 cups powdered sugar (904g or a 2 lb. bag)",
          "1/4 cup heavy cream or whipping cream (60g)",
          "Pink gel food coloring",
        ],
      },
    ],
    servings: "24",
    estTimeComplete: "1 hr 25 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "170",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 11,
    title: "How to Use Russian Piping Tips",
    description:
      "Russian piping tips are large piping tips with flat ends that have several openings. The openings come in different patterns and produce a full flower when you pipe.",
    link: "https://bakingbutterlylove.com/how-to-use-russian-piping-tips/",
    img: "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4892-1.jpg?w=600&ssl=1",
    imgSrc: [
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4892-1.jpg?w=600&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4892-1.jpg?w=600&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4919-1.jpg?w=600&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4919-1.jpg?w=600&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4680-1.jpg?resize=819%2C1024&ssl=1",
        thumbnail:
          "https://i0.wp.com/bakingbutterlylove.com/wp-content/uploads/2019/09/IMG_4680-1.jpg?resize=819%2C1024&ssl=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Tools",
        materialInfo: [
          "russian piping tips",
          "Icing with medium to stiff consistency ",
          "12 in piping bag or larger",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [russianTip],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[5],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "171",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 24,
    title: "Peanut Butter Cookie Dough Bars",
    description:
      "Quick and easy eggless peanut butter cookie dough bars that's made with 5 ingredients! This edible cookie dough is rich, fudgy and healthy no bake dessert.",
    link: "https://cakewhiz.com/peanut-butter-cookie-dough-bars-eggless/",
    img: "https://cakewhiz.com/wp-content/uploads/2017/04/Peanut-Butter-Cookie-Dough-Bars-Recipe.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2017/04/Peanut-Butter-Cookie-Dough-Bars-Recipe.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2017/04/Peanut-Butter-Cookie-Dough-Bars-Recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2017/04/Easy-Peanut-Butter-Cookie-Dough-Bars.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2017/04/Easy-Peanut-Butter-Cookie-Dough-Bars.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1¾ cup Peanut butter, Creamy",
          "⅓ cup Honey",
          "¾ cup Almond flour",
          "1 Packet collagen peptides, Optional",
          "⅓ cup Chocolate chips, Semisweet, Mini size",
          "1 cup Melted chocolate, Semisweet",
        ],
      },
    ],
    servings: "14",
    estTimeComplete: "40 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "172",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 8,
    title: "Shortbread Hearts",
    description:
      "Easy three ingredient shortbread hearts coated in white & milk chocolate - perfect easy bake!",
    link: "https://www.janespatisserie.com/2015/02/09/chocolate-shortbread-hearts/",
    img: "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts15-1024x1536.jpg",
    imgSrc: [
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts15-1024x1536.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts15-1024x1536.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts3-1024x1536.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts3-1024x1536.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts1-1024x1536.jpg",
        thumbnail:
          "https://www.janespatisserie.com/wp-content/uploads/2015/02/ShortbreadHearts1-1024x1536.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Shortbread",
        materialInfo: [
          "200 g unsalted butter",
          "100 g caster sugar (plus extra)",
          "300 g plain flour (plus extra)",
        ],
      },
      {
        section: "Decoration",
        materialInfo: [
          "75 g white chocolate",
          "75 g milk chocolate",
          "Sprinkles",
        ],
      },
    ],
    servings: "15",
    estTimeComplete: "1 hr 20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "173",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 24,
    title: "Two peas in a pod",
    description:
      "Learn how to make easy twins birthday cake with two peas in a pod fondant topper. No fancy tools required. Also great for baby showers.",
    link: "https://cakewhiz.com/two-peas-in-a-pod-cake/",
    img: "https://cakewhiz.com/wp-content/uploads/2023/03/Easy-Twins-Birthday-Cake.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2023/03/Easy-Twins-Birthday-Cake.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2023/03/Easy-Twins-Birthday-Cake.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2023/03/How-to-Make-Twins-Cake-Topper.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2023/03/How-to-Make-Twins-Cake-Topper.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2023/03/Easy-Twins-Cake-Topper.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2023/03/Easy-Twins-Cake-Topper.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2 Cherry cake, Round, Dimension: 8 x 2 each",
          "3 cups Buttercream icing, Colored yellow",
          "Marshmallow fondant , Green, blue, red",
          "2-3 tbsp Green sprinkles",
        ],
      },
    ],
    servings: "6",
    estTimeComplete: "1 hr 20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "174",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 24,
    title: "Chocolate Chip Pancakes",
    description:
      "Quick and easy chocolate chip pancakes recipe, homemade with simple ingredients in 20 minutes. Soft, fluffy and topped off with chocolate syrup.",
    link: "https://cakewhiz.com/chocolate-chip-pancakes-recipe/",
    img: "https://cakewhiz.com/wp-content/uploads/2019/08/Best-Homemade-Chocolate-Chip-Pancakes-Recipe.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2019/08/Best-Homemade-Chocolate-Chip-Pancakes-Recipe.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2019/08/Best-Homemade-Chocolate-Chip-Pancakes-Recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2019/08/Homemade-Chocolate-Chip-Pancakes.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2019/08/Homemade-Chocolate-Chip-Pancakes.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 cup All-purpose flour",
          "1.5 tsp Baking powder",
          "1/2 tsp Baking soda",
          "1 tbsp Granulated sugar",
          "1 tbsp Butter, Unsalted, Melted",
          "1 Egg, Large",

          "1 cup Milk, 2% or Buttermilk, May need to add a little more if batter is too thick",
          "1/2 cup Chocolate chips, Mini size",
        ],
      },
      {
        section: "Chocolate Syrup",
        materialInfo: [
          "1 1/3 cup Chocolate chips, Milk or Semisweet",
          "1 cup Heavy cream",
        ],
      },
    ],
    servings: "6",
    estTimeComplete: "20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "175",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 24,
    title: "Chocolate Chip Muffins",
    description:
      "Quick and easy chocolate chip muffins recipe, homemade with simple ingredients. Soft and fluffy and loaded with mini chocolate chips.",
    link: "https://cakewhiz.com/chocolate-chip-muffins-recipe/",
    img: "https://cakewhiz.com/wp-content/uploads/2020/07/Best-Easy-Homemade-Chocolate-Chip-Muffins-Recipe.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2020/07/Best-Easy-Homemade-Chocolate-Chip-Muffins-Recipe.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2020/07/Best-Easy-Homemade-Chocolate-Chip-Muffins-Recipe.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2020/07/Easy-Chocolate-Chip-Muffins.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2020/07/Easy-Chocolate-Chip-Muffins.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          " 1/2 cup Butter, Unsalted, Melted, Should not be boiling hot",
          "1 Egg, Large",
          "1 cup Milk, Whole or 2%",
          "2 tsp Vanilla extract",
          "2 cups All-purpose flour",
          "1/2 cup Granulated sugar",

          "1 tbsp Baking powder",
          "3/4 cup Mini chocolate chips, Semisweet",
        ],
      },
      {
        section: "Topping",
        materialInfo: [
          "1-2 tbsp Granulated sugar, Or sanding sugar",
          "2 tbsp Mini chocolate chips",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "176",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 24,
    title: "Caramelized Baked Peaches",
    description:
      "Quick and easy caramelized baked peaches recipe, homemade with simple ingredients in 20 minutes. Full of butter, brown sugar and cinnamon. Great dessert topping!",
    link: "https://cakewhiz.com/caramelized-baked-peaches-cinnamon/",
    img: "https://cakewhiz.com/wp-content/uploads/2022/08/Easy-Baked-Peaches-on-Stovetop.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2022/08/Easy-Baked-Peaches-on-Stovetop.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2022/08/Easy-Baked-Peaches-on-Stovetop.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2022/08/Easy-Cinnamon-Baked-Peaches.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2022/08/Easy-Cinnamon-Baked-Peaches.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "3 tbsp Butter, Unsalted",
          "1/4 cup Brown sugar, Can add up to 1/3 cup if you prefer very swet peaches",
          "1/4-1/2 tsp Cinnamon powder, Adjust according to your preference",
          "1/4 tsp Nutmeg powder",
          "3 Peaches, Semi-ripe, Large, Washed, Patted dry, Sliced to 1 inch thickness Can be peeled if you like",
        ],
      },
    ],
    servings: "4",
    estTimeComplete: "20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "177",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 37,
    title: "The Best Buttermilk Pancakes",
    description:
      "Absolutely fantastic buttermilk pancakes. Easy to make and so light, fluffy, and delicious!",
    link: "https://creative-culinary.com/best-buttermilk-pancakes-recipe/#wprm-recipe-container-56642",
    img: "https://creative-culinary.com/wp-content/uploads/buttermilk-pancakes-1-Rev1.jpg",
    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/buttermilk-pancakes-1-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/buttermilk-pancakes-1-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/buttermilk-pancakes-2-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/buttermilk-pancakes-2-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Pancakes",
        materialInfo: [
          "2 Eggs",
          "1 ½ C buttermilk If you don't have buttermilk on hand you can also add 1 Tbsp of lemon juice to regular milk and let it sit for at least 5 minutes as a substitution.",
          "¼ C oil",
          "3 Tbsp. sugar",
          "¾ tsp. salt",
          "1 ½ C sifted flour",

          "1 ½ tsp. baking powder",
          "1 tsp. baking soda",
        ],
      },
      {
        section: "Syrup",
        materialInfo: [
          "¾ c Buttermilk",
          "¾ c Butter",
          "1 ½ c Sugar",
          "1 t Vanilla",
          "1 ½ tsp Baking Soda",
          "¼ cup maple syrup optional",
        ],
      },
    ],
    servings: "18-20",
    estTimeComplete: "30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "178",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 37,
    title: "Strawberry and Watermelon Smoothie with Basil",
    description:
      "This Strawberry Watermelon Smoothie with Basil goes down easy AND it’s good for you! My favorite summer smoothie!",
    link: "https://creative-culinary.com/strawberry-watermelon-smoothie-recipe/#wprm-recipe-container-56130",
    img: "https://creative-culinary.com/wp-content/uploads/watermelon-strawberry-basil-smoothie1-Rev1.jpg",
    imgSrc: [
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/watermelon-strawberry-basil-smoothie1-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/watermelon-strawberry-basil-smoothie1-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://creative-culinary.com/wp-content/uploads/watermelon-strawberry-basil-smoothie2-Rev1.jpg",
        thumbnail:
          "https://creative-culinary.com/wp-content/uploads/watermelon-strawberry-basil-smoothie2-Rev1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "3 strawberries",
          "1 cup seedless watermelon",
          "3 basil leaves",
          "8 ounces coconut water",
          "1 tsp. of lemon juice",
          "1 tsp. of Agave syrup",

          "1 cup ice",
        ],
      },
    ],
    servings: "1",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "179",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Fudge-Striped Shortbread Cookies",
    description:
      "Crisp, buttery shortbread is coated with rich semi-sweet chocolate to make a delicious, beautiful treat.",
    link: "https://www.sugarhero.com/fudge-striped-shortbread-cookies/#wprm-recipe-container-21275",
    img: "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-4.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/01/fudge-striped-shortbread-cookies-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Zest of 1 orange",
          "3.5 oz granulated sugar, (1/2 cup)",
          "11.25 oz all-purpose flour, (2 1/2 cups)",
          "pinch of salt",
          "10 oz unsalted butter, cold and cubed",
          "1 lb semi-sweet chocolate",
          "Round cookie cutters",
          "Chocolate thermometer, optional, only if you want to temper the chocolate",
        ],
      },
    ],
    servings: "32",
    estTimeComplete: "42 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "180",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Doughnut Strawberry Shortcakes",
    description:
      "These Doughnut Strawberry Shortcakes have a combination of sweetly glazed yeasted doughnuts, juicy berries, and softly whipped cream, pretty much perfect, right?",
    link: "https://www.sugarhero.com/bumblebee-cake/#wprm-recipe-container-26247",
    img: "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-3_thumb.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-3_thumb.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-3_thumb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-1_thumb.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-1_thumb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-5_thumb.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2012/07/doughnut-shortcake-5_thumb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "8 oz strawberries, or mix of berries",
          "1-2 TBSP granulated sugar",
          "3 glazed yeast doughnuts",
          "1 cup heavy cream",
          "1/2 tsp vanilla extract",
          "Chocolate ganache, or chocolate syrup, optional",
        ],
      },
    ],
    servings: "3",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[2],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "180",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Mini Chocolate Doughnuts",
    description:
      "These Mini Chocolate Doughnuts have an intense chocolate taste that’s enhanced by the chocolate ganache glaze on top. They’re good for two or three bites and will disappear off the plate!",
    link: "https://www.sugarhero.com/mini-chocolate-doughnuts/#wprm-recipe-container-20794",
    img: "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-2-vertical.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-2-vertical.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-2-vertical.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-5-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-5-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-4-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2011/03/chocolate-doughnuts-4-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Doughnuts",
        materialInfo: [
          "1-1/4 cups all-purpose flour",
          "1/2 cup unsweetened cocoa powder",
          "1 teaspoon baking powder",
          "1/2 teaspoon salt",
          "2 large eggs",
          "3/4 cup granulated sugar",
          "1/3 cup buttermilk",
          "1.5 tablespoons unsalted butter, melted",
        ],
      },
      {
        section: "Ganache Glaze",
        materialInfo: ["1/2 cup heavy cream", "1/2 cup chocolate, chopped"],
      },
    ],
    servings: "48",
    estTimeComplete: "1 hr",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "189",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Lemon Tea Cake",
    description:
      "This Lemon Tea Cake is a drool-worthy recipe that has a triple hit of lemon flavor. Lemon zest and juice, lemon sugar syrup, tart lemon glaze…must I go on!?",
    link: "https://www.sugarhero.com/lemon-tea-cake/#wprm-recipe-container-20605",
    img: "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread2.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2008/09/lemonbread5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cake",
        materialInfo: [
          "8 oz unsalted butter, room temperature",
          "2 cups granulated sugar",
          "1/3 cup lemon zest, use a microplane for fine zest",
          "4 large eggs, room temperature",
          "3 cups all-purpose flour",
          "1/2 tsp baking powder",
          "1/2 tsp baking soda",
          "1 tsp kosher salt",
          "1/4 cup lemon juice, fresh squeezed and strained",
          "3/4 cup buttermilk, room temperature",
          "1 tsp vanilla extract",
        ],
      },
      {
        section: "Syrup",
        materialInfo: [
          "1/2 cup granulated sugar",
          "1/2 cup lemon juice, fresh squeezed and strained",
        ],
      },
      {
        section: "Frosting",
        materialInfo: [
          "2 cups powdered sugar, sifted",
          "3 tbsp lemon juice, fresh squeezed and strained",
        ],
      },
    ],
    servings: "16",
    estTimeComplete: "1 hr 20 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "190",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 38,
    title: "Fondant Rose Cupcakes",
    description:
      "If you’re new to working with fondant, this is the PERFECT tutorial to get you started! Learn to make fondant ribbon roses, the sweetest little flowers that are super easy and fast to make. They can be used to decorate cupcakes or cakes, and can be made in whatever size and color you’d like. These easy fondant flowers add the perfect romantic touch to any dessert",
    link: "https://www.sugarhero.com/easy-fondant-flower-cupcakes/#wprm-recipe-container-31132",
    img: "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-FEATURED-IMAGE.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-FEATURED-IMAGE.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-FEATURED-IMAGE.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },

      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-process5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-process5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-process3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-process3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/easy-fondant-flowers-valentines-day-cupcakes-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "12 cupcakes, baked and cooled",
          "4 cups buttercream frosting",
          "12 oz pink fondant",
          "2 oz green fondant",
        ],
      },
    ],
    servings: "12",
    estTimeComplete: "1 hr",
    directions: [],
    tutorials: [fondantCupcake],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: [FOOD_VALUE_TYPES[1], FOOD_VALUE_TYPES[5]],
    productId: "191",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 38,
    title: "Easter Bunny Cupcakes",
    description:
      "Make your Easter extra-special with these adorable Easter Bunny Cupcakes. Make an easy bunny face out of frosting, sprinkles, and marshmallows, and customize them by using your favorite cupcake and frosting flavors.",
    link: "https://www.sugarhero.com/easter-bunny-cupcakes/",
    img: "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-4.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-4.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-process-3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-process-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/02/easter-bunny-cupcakes-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cupcakes",
        materialInfo: [
          "1 box yellow cake mix, plus ingredients to prepare mix",
        ],
      },
      {
        section: "Frosting",
        materialInfo: [
          "6 fl oz pasteurized liquid egg whites (3/4 cup, see Note below)",
          "24 oz powdered sugar (6 cups)",
          "1/2 tsp salt",
          "24 oz unsalted butter, at room temperature (6 sticks)",
          "2 TBSP vanilla extract",
        ],
      },
      {
        section: "Decorating",
        materialInfo: [
          "1.5 cups white sparkling sugar",
          "8 large marshmallows",
          "Pink jimmies or pink sanding sugar",
          "Round pink sprinkles or pink sugar pearl or pink Sixlet",
          "Pink gel food coloring – deep pink, I used Americolor Soft Pink gel color",
        ],
      },
    ],
    servings: "24",
    estTimeComplete: "1 hr 12 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "192",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 38,
    title: "Easter Polka Dot Cake",
    description:
      "This Easter Polka Dot Cake is truly easy to make, don’t let it’s look be deceptive! Just combine a few simple steps and you’ll end up with this beautifully whimsical cake.",
    link: "https://www.sugarhero.com/easter-polka-dot-cake/",
    img: "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-1-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-1-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-1-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-4-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-4-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-5-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2014/04/easter-polka-dot-cake-5-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Polka dots",
        materialInfo: [
          "1 yellow cake mix, or white cake mix, plus ingredients to prepare mix",
          "Assorted gel food coloring",
          "2 cups prepared frosting",
        ],
      },
      {
        section: "Lemon Cake",
        materialInfo: [
          "9 large egg yolks",
          "1 1/2 cups milk",
          "1 tbsp lemon extract",
          "zest of 2 lemons",
          "15.75 oz granulated sugar, (2 ¼ cups)",
          "15.75 oz cake flour, (3 ⅔ cups)",
          "2 tbsp baking powder",
          "1 tsp salt",
          "9 oz unsalted butter, at room temperature",
        ],
      },
      {
        section: "Coconut Buttercream",
        materialInfo: [
          "9 large egg whites, at room temperature",
          "14 oz granulated sugar, (2 cups)",
          "1 lb unsalted butter, softened but still quite cool",
          "1/2 tsp salt",
          "2-3 tsp coconut extract, to taste",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "193",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 38,
    title: "Chocolate-Dipped Pretzel Cupcakes",
    description:
      "These delicious Chocolate-Dipped Pretzel Cupcakes have a moist chocolate cake, sweet-and-salty pretzel frosting, and a gooey scoop of salted caramel in the center!",
    link: "https://www.sugarhero.com/chocolate-dipped-pretzel-cupcakes/#wprm-recipe-container-20834",
    img: "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-7.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-7.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-7.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-4.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/09/chocolate-dipped-pretzel-cupcakes-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Cupcakes",
        materialInfo: [
          "6 oz unsalted butter, at room temperature",
          "4.75 oz granulated sugar (2/3 cup)",
          "5 oz brown sugar (2/3 cup), packed",
          "2 large eggs, at room temperature",
          "2 tsp vanilla extract",
          "8 oz buttermilk (1 cup), at room temperature",
          "4 oz sour cream (1/2 cup), at room temperature",
          "2 tbsp water, or brewed coffee",
          "7.8 oz all-purpose flour (1¾ cups)",
          "3 oz unsweetened cocoa powder (1 cup), sifted",
          "1½ tsp baking soda",
          "½ tsp salt",
        ],
      },
      {
        section: "Pretzel Frosting",
        materialInfo: [
          "4 oz small pretzel twists (about 3 cups)",
          "1 lb unsalted butter, at room temperature",
          "1 tsp vanilla extract",
          "1/8 tsp salt",
          "20 oz powdered sugar (5 cups)",
          "2 TBSP milk",
        ],
      },
      {
        section: "To Finish",
        materialInfo: [
          "2 cups small pretzel twists",
          "2 cups thick salted caramel sauce (about 20 oz, the thick kind in a jar)",
          "24 chocolate-covered pretzel twists",
        ],
      },
    ],
    servings: "20",
    estTimeComplete: "1 hr 22 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[3],
    productId: "194",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 38,
    title: "Bumbleblee Cake",
    description:
      "This Bumblebee Cake is a delicious lemon layer cake with lemon buttercream, and topped with fondant! It's the perfect cake for any occasion and all your bee needs!",
    link: "https://www.sugarhero.com/bumblebee-cake/#wprm-recipe-container-26247",
    img: "https://www.sugarhero.com/wp-content/uploads/2013/09/bee-cake-1-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2013/09/bee-cake-1-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2013/09/bee-cake-1-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2019/06/bee-cake-2.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2019/06/bee-cake-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2019/06/bee-cake-4.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2019/06/bee-cake-4.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: 'Lemon Layer 6" (Two 6" Cake Pans)',
        materialInfo: [
          "1 TBSP lemon zest (from approximately 1 large lemon)",
          "7 oz granulated sugar",
          "7 oz cake flour, sifted",
          "1 TBSP baking powder",
          "1/2 tsp salt",
          "4 oz unsalted butter, cubed and at room temperature",
          "2/3 cup whole milk, at room temperature",
          "4 large egg yolks",
          "2 tsp vanilla extract",
          "1 tsp lemon extract",
        ],
      },
      {
        section: 'Lemon Layer 10" (Two 10" Cake Pans)',
        materialInfo: [
          "2 TBSP lemon zest (from approximately 2 large lemons)",
          "17.5 oz granulated sugar",
          "17.5 oz cake flour, sifted",
          "6 2/3 tsp baking powder",
          "1 1/4 tsp salt",
          "10 oz unsalted butter, cubed and at room temperature",
          "1 2/3 cup whole milk, at room temperature",
          "10 large egg yolks",
          "4 tsp vanilla extract",
          "1 TBSP lemon extract",
        ],
      },
      {
        section:
          "Lemon Italian Meringue Buttercream (You Will Need to Make This Recipe Twice)",
        materialInfo: [
          "8 large egg whites, at room temperature",
          "21 oz granulated sugar (3 cups)",
          "1/3 cup water",
          "1/3 cup fresh lemon juice",
          "1/4 tsp cream of tartar",
          "1 lb unsalted butter, soft to the touch but still cool",
          "1-2 tsp lemon extract",
          "pinch of salt",
        ],
      },
      {
        section: "To Decorate",
        materialInfo: [
          "3 lbs fondant",
          "Orange gel food coloring (I use Americolor gel food coloring)",
          "Yellow gel food coloring – lemon yellow",
          "Green gel food coloring – leaf green",
          "Green gel food coloring – avocado",
          "Black gel food coloring – super black",
        ],
      },
    ],
    servings: "40",
    estTimeComplete: "1 hr 10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[6],
    productId: "195",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Hot Chocolate Floats",
    description:
      "Hot chocolate has never tasted so good! Regular hot chocolate gets an upgrade by adding ice cream, hot fudge sauce, and other gourmet toppings",
    link: "https://www.sugarhero.com/hot-chocolate-floats/#wprm-recipe-container-21281",
    img: "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2023/04/hot-chocolate-floats-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "1 cup heavy cream",
          "3 cups milk (I used whole milk)",
          "2-3 tbsp brown sugar (to taste)",
          "1 tsp vanilla extract",
          "pinch of salt",
          "6 oz bittersweet chocolate (good quality, finely chopped)",
          "4 oz milk chocolate (good quality, finely chopped)",
          "1 quart ice cream (vanilla, peppermint, and coffee all work well!)",
          "Fudge sauce (see Note below)",
          "Chocolate shavings or other decorations (optional)",
        ],
      },
    ],
    servings: "8",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[1],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "196",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Caramel Cheesecake-Topped Brownies",
    description:
      "These Caramel Cheesecake-Topped Brownies has smooth, creamy, and wonderfully light topping which is perfect contrast to the fudgy brownies underneath. Grab a glass of milk and go to town on these!",
    link: "https://www.sugarhero.com/caramel-cheesecake-topped-brownies/#wprm-recipe-container-23273",
    img: "https://www.sugarhero.com/wp-content/uploads/2012/09/caramel-cheesecake-topped-brownies-1_thumb.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2012/09/caramel-cheesecake-topped-brownies-1_thumb.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2012/09/caramel-cheesecake-topped-brownies-1_thumb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2012/09/caramel-cheesecake-topped-brownies-4_thumb.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2012/09/caramel-cheesecake-topped-brownies-4_thumb.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Brownies",
        materialInfo: [
          "5.5 oz unsalted butter (11 tbsp), cubed",
          "8 oz semi-sweet chocolate (1 ⅓ cup), finely chopped",
          "1/3 cup unsweetened cocoa powder (Dutch-processed, alkalized cocoa powder)",
          "4 large eggs (room temperature)",
          "1 1/3 cup granulated sugar",
          "2 tsp vanilla extract",
          "1 1/3 cup all-purpose flour",
          "1 cup pecans (toasted, coarsely chopped) (optional)",
        ],
      },
      {
        section: "Caramel Cheesecake Layer",
        materialInfo: [
          "8 oz cream cheese (at room temperature)",
          "2 oz unsalted butter (4 tbsp), at room temperature",
          "1/3 cup powdered sugar",
          "1/3 cup vanilla instant pudding mix (about half of a 5.1 oz package)",
          "1/2 cup caramel sauce",
          "1 tsp ground cinnamon",
          "1 tsp vanilla extract",
          "2/3 cup pecans (toasted, coarsely chopped)",
          "4 oz miniature chocolate chips (⅔ cup), finely chopped",
        ],
      },
      {
        section: "Chocolate Ganache Glaze",
        materialInfo: [
          "4 oz semi-sweet chocolate (⅔ cup), finely chopped",
          "1/2 cup heavy cream",
          "1 oz unsalted butter (2 tbsp), at room temperature",
        ],
      },
    ],
    servings: "16",
    estTimeComplete: "45 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "197",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Cookie Dough Frosting",
    description:
      "This easy frosting tastes just like chocolate chip cookie dough! Use it to frost your favorite cakes and cupcakes, as a filling for layer cakes, or even turn it into chocolate chip cookie dough dip!",
    link: "https://www.sugarhero.com/cookie-dough-frosting/#wprm-recipe-container-37750",
    img: "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-3.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-5.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-5.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-7.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2022/03/cookie-dough-frosting-7.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "2.25 oz all-purpose flour (1/2 cup)",
          "6 oz unsalted butter (3/4 cup), at room temperature",
          "4 oz brown sugar (1/2 cup), packed",
          "8 oz powdered sugar (2 cups)",
          "1 tsp salt",
          "2 tsp vanilla extract",
          "1 TBSP heavy cream or milk",
          "4.5 oz mini chocolate chips (3/4 cup), can increase to taste",
        ],
      },
    ],
    servings: "24",
    estTimeComplete: "20",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[0],
    categoryTypes: FOOD_VALUE_TYPES[5],
    productId: "198",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Rainbow Frosting",
    description:
      "This swirled rainbow frosting adds a bright, cheerful pop of color to any dessert. It’s perfect for birthday cakes, cupcakes, brownies, cookie sandwiches, and so much more.",
    link: "https://www.sugarhero.com/rainbow-frosting-swirls/",
    img: "https://www.sugarhero.com/wp-content/uploads/2020/03/rainbow-frosting-swirl-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2020/03/rainbow-frosting-swirl-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2020/03/rainbow-frosting-swirl-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/rainbow-frosting-swirl-9.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/02/rainbow-frosting-swirl-9.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "4 TBSP milk, at room temperature",
          "1 TBSP vanilla extract, or other flavoring",
          "½ tsp salt",
          "8 oz unsalted butter, at room temperature",
          "8 oz shortening, can replace with equal amount butter if desired",
          "28 oz powdered sugar (7 cups), sifted",
          "Gel food coloring in six rainbow colors, I recommend Americolor",
        ],
      },
    ],
    servings: "18",
    estTimeComplete: "30 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },

  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "199",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Oreo Milkshake",
    description:
      "Super fast and easy Oreo milkshakes with just 4 ingredients! Dress them up with whipped cream and a cherry on top, or keep it simple with a drizzle of chocolate sauce in the glass. However you make them, you’re gonna love this cookies and cream shake.",
    link: "https://www.sugarhero.com/oreo-milkshake/#wprm-recipe-container-32254",
    img: "https://www.sugarhero.com/wp-content/uploads/2021/07/oreo-milkshake-3.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/07/oreo-milkshake-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/07/oreo-milkshake-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2021/07/oreo-milkshake-ingredients.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2021/07/oreo-milkshake-ingredients.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "8 Oreo cookies, divided use",
          "12 oz vanilla ice cream (approx 7 scoops / 2 cups)",
          "3 TBSP milk",
          "1 tsp vanilla extract",
          "1 TBSP chocolate syrup",
          "1/2 cup whipped cream",
          "2 maraschino cherries",
        ],
      },
    ],
    servings: "2",
    estTimeComplete: "5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "200",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Epic Chocolate Peanut Butter Milkshake",
    description:
      "These are no ordinary milkshakes—these are Epic Chocolate Peanut Butter Milkshakes! They’re rich and creamy, and topped with an amazing selection of goodies like cookies, candy, whipped cream, and even a cupcake! These milkshakes are completely over-the-top…in the best way!",
    link: "https://www.sugarhero.com/epic-chocolate-peanut-butter-milkshakes/#wprm-recipe-container-20890",
    img: "https://www.sugarhero.com/wp-content/uploads/2016/05/epic-chocolate-peanut-butter-milkshakes-3.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/05/epic-chocolate-peanut-butter-milkshakes-3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/05/epic-chocolate-peanut-butter-milkshakes-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/05/epic-chocolate-peanut-butter-milkshakes-2.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/05/epic-chocolate-peanut-butter-milkshakes-2.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "4 cups chocolate ice cream",
          "1 1/4 cups milk",
          "1/4 cup creamy peanut butter",
          "Chocolate frosting",
          "8 peanut butter Oreo cookies, coarsely chopped",
          "Mini peanut butter cups",
          "Chocolate syrup",
          "Whipped cream",
          "Assorted toppings, chopped peanuts, pirouette cookies, large peanut butter cups, cupcakes – optional",
        ],
      },
    ],
    servings: "4",
    estTimeComplete: "5 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[7],
    categoryTypes: FOOD_VALUE_TYPES[2],
    productId: "201",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Unicorn Milkshakes",
    description:
      "These Unicorn Milkshakes are pure MAGIC! A rich, thick strawberry milkshake is topped with a rainbow of different sprinkles and candies to make a delicious drink fit for a unicorn. ",
    link: "https://www.sugarhero.com/unicorn-milkshakes/#wprm-recipe-container-20352",
    img: "https://www.sugarhero.com/wp-content/uploads/2017/04/unicorn-milkshakes-8.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2017/04/unicorn-milkshakes-8.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2017/04/unicorn-milkshakes-8.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2017/04/unicorn-milkshakes-3.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2017/04/unicorn-milkshakes-3.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "10 oz strawberry-coconut ice cream, (5 large scoops)",
          "1 cup strawberries, fresh or frozen",
          "½ cup milk",
          "½ cup fruity miniature marshmallows",
          "¼ cup vanilla frosting",
          "½ cup assorted sprinkles",
          "Whipped cream",
          "Assorted toppings, like lollipops, rock candy, wafer",
        ],
      },
    ],
    servings: "2",
    estTimeComplete: "10 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[1],
    productId: "202",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 38,
    title: "Peaches and Cream Trifle",
    description:
      "Peaches and Cream Trifle is an easy summertime dessert that's bursting with flavor! You'll love this combination of pound cake, fresh peaches, and whipped cream. It’s the best way to enjoy the taste of summer peaches.",
    link: "https://www.sugarhero.com/peaches-and-cream-trifle/",
    img: "https://www.sugarhero.com/wp-content/uploads/2016/08/peaches-and-cream-trifle-5_thumb-1.jpg",
    imgSrc: [
      {
        original:
          "https://www.sugarhero.com/wp-content/uploads/2016/08/peaches-and-cream-trifle-5_thumb-1.jpg",
        thumbnail:
          "https://www.sugarhero.com/wp-content/uploads/2016/08/peaches-and-cream-trifle-5_thumb-1.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "8 oz unsalted butter, at room temperature",
          "8 oz cream cheese, at room temperature",
          "1 3/4 cups granulated sugar",
          "5 large eggs, at room temperature",
          "1 tbsp vanilla extract",
          "2 1/4 cups all-purpose flour",
          "1 1/2 tsp baking powder",
          "1/2 tsp salt",
        ],
      },
      {
        section: "Trifle",
        materialInfo: [
          "5 large peaches, (about 3 ½ lbs)",
          "¼ cup granulated sugar",
          "1 TBSP lemon juice",
          "3 cups heavy cream",
          "½ cup powdered sugar",
          "2 tsp vanilla extract",
        ],
      },
    ],
    servings: "16",
    estTimeComplete: "2 hr",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[2].projectId,
    categoryId: ALL_FOOD_VALUES[6],
    categoryTypes: FOOD_VALUE_TYPES[0],
    productId: "203",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 24,
    title: "Caramelized Bananas",
    description:
      "Quick and easy pan fried caramelized bananas recipe, homemade with simple ingredients in 15 minutes. Flavored with cinnamon, vanilla. Great on ice cream, pancakes, waffles.",
    link: "https://cakewhiz.com/easy-caramelized-bananas-cinnamon/",
    img: "https://cakewhiz.com/wp-content/uploads/2022/04/Caramelized-Bananas.jpg",
    imgSrc: [
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2022/04/Caramelized-Bananas.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2022/04/Caramelized-Bananas.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://cakewhiz.com/wp-content/uploads/2022/04/Spoonful-of-Caramelized-Bananas.jpg",
        thumbnail:
          "https://cakewhiz.com/wp-content/uploads/2022/04/Spoonful-of-Caramelized-Bananas.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Ingredients",
        materialInfo: [
          "Bananas Peeled, Cut into thin circles that are about half an inch thick",

          "Butter 2 tbsp, Unsalted",

          "Brown sugar, 2 tbsp",

          "Cinnamon powder description 1/8 tsp, Can add up to 1/4 tsp if you want a stronger cinnamon flavor",
          "Vanilla extract description 1/2 tsp",
        ],
      },
    ],
    servings: "2",
    estTimeComplete: "15 min",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
];
