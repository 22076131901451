import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
// import { ProductView } from "./app/product/ProductView";
// import { HomePage } from "./app/home/HomePage";
// import { ProjectPage } from "./app/project/ProjectPage";
import { BitsAppBar } from "./bitsApp/bitsAppBar/AppBar";
// import { ShopPage } from "./bitsApp/bitsShop/ShopPage";
import { HomeLayout } from "./bitsApp/bitsHome/HomeLayout";
import { ProjectLayout } from "./bitsApp/bitsProjectPage/ProjectLayout";
import { ProductLayout } from "./bitsApp/bitsProductPage/ProductLayout";
// import { ShopSections } from "./bitsApp/bitsShop/ShopSections";
import { SearchLayout } from "./bitsApp/search/SearchLayout";
// import Dashboard from "./bitsApp/account/Dashboard";
// import { SavedProducts } from "./bitsApp/account/Saved";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <BitsAppBar />
        {/* <ProjectToolbar /> */}
        <Routes>
          <Route path="/:projectId" element={<ProjectLayout />}></Route>
          {/* <Route path="/shop" element={<ShopPage />}></Route> */}
          {/* <Route path="/shop/:projectId" element={<ShopSections />}></Route> */}

          <Route path="/search/:searchTerm" element={<SearchLayout />}></Route>
          {/* <Route path="/account/:userId" element={<Dashboard />}></Route> */}
          {/* <Route path="/account/:userId/saved" element={<SavedProducts />}></Route> */}

          {/* <Route path="/media/:projectId" element={<PhoneProjectPage />}></Route> */}
          {/* <Route path="/med/:projectId" element={<TabletProjectPage />}></Route> */}

          {/* <Route path="/category/:categoryId" element={<ProductSortPage />}></Route> */}

          {/* <Route
            path="/category/product/:productId"
            element={<ProductView />}
          ></Route>*/}
          <Route path="/product/:productId" element={<ProductLayout />}></Route>

          {/* <Route path="/product/:productId" element={<ProductView />}></Route> */}

          {/* <Route path="/category" element={<Products />}></Route> */}

          {/* <Route path="/" element={<BitsHome />}></Route> */}
          {/* <Route exact path="/login" element={<ProductLayout />}></Route> */}
          {/* fix with bits */}
          <Route path="/" element={<HomeLayout />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
