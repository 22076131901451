import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = responsiveFontSizes(
  createTheme({
    spacing: 3,

    palette: {
      background: {},
      primary: {
        // main: "#197982",
        // main: "#9b8262",
        main: "#5b401e",

        // main: "#61c017"
      },

      secondary: {
        // main: "#bf8f69", //tancolor
        // main: "#535957",

        main: "#9b8262",
        // main: "#6e5e49"
      },
      // error: {
      //   main: "#D72A2A", //red
      // },
      // warning: {
      //   main: "#FC7B09", //orange
      // },
      // info: {
      //   main: "#6B7D6A", //gray
      // },
      // success: {
      //   main: "#09FE00", //green
      // },
      text: {
        // primary: "#354453", //dark blue/black
        primary: "#4d4234", //dark brown
        
        secondary: "#565e4a",
        // secondary: "#354453",

      },
    },

    typography: {
      //   allVariants: {
      //     color: "#4b4c4e",
      //   },
      fontFamily: 'Poppins, sans-serif',

      //     h3: {
      //       color: "#313234",
      //     },
      //     h4: {
      //       color: "#3a3b3c",
      //     },
      //     h5: {
      //       color: "#3a3b3c",
      //     },
      //     h6: {
      //       color: "#3a3b3c",
      //     },

      //     subtitle1: {
      //       color: "#0a131c",
      //     },

      //     caption: {
      //       color: "#827e85",
      //     },

      //     custom: {
      //       listView: {
      //         start: {
      //           display: "flex",
      //           justifyContent: "flex-end",
      //         },
      //       },
      //     },
      //   },
      // props: {
      //     MuiButton: {
      //       variant: "contained",
      //     },
      body1: {
        color: "#5e4f3b"
      },
      body2: {
        fontSize: 16,
        // fontStyle: 'italic',
      },
      subtitle1: {
        fontSize: 18,
      },
    },

    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            justifyContent: "space-between",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // backgroundColor: "#f1f1f1",
            // backgroundColor: "#f6fcff",
            display: "flex",
            flexDirection: "column",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            padding: 2,
          },
        },
      },
      //     MuiCardMedia: {
      //       styleOverrides: {
      //         root: {
      //           objectFit: "fill",
      //           paddingBottom: 1,
      //         },
      //       },
      //     },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundColor: "#eff1f3",
            // backgroundColor: "#fcfcfc"
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            // backgroundColor: "#197982",
            backgroundColor: "#ccad95",
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            // backgroundColor: "#197982",
            color: "#ccad95",
          },
        },
      },
      //     MuiFormControlLabel: {
      //       styleOverrides: {
      //         root: {
      //           color: "#103964",
      //         },
      //       },
      //     },
      //     MuiFormGroup: {
      //       styleOverrides: {
      //         root: {
      //           marginLeft: 7,
      //         },
      //       },
      //     },
      //     MuiFormLabel: {
      //       styleOverrides: {
      //         root: {
      //           color: "#c36d00",
      //         },
      //       },
      //     },
      //     MuiListItemText: {
      //       styleOverrides: {
      //         primary: {
      //           color: "#171818",
      //           fontWeight: 700,
      //         },

      //         secondary: "",
      //       },
      //     },

      //     MuiBox: {
      //       styleOverrides: {
      //         root: {
      //           backgroundColor: "#f3f3f3",
      //         },
      //       },
      //     },

      //     MuiLink: {
      //       styleOverrides: {
      //         root: {
      //           textDecoration: "none",
      //           color: "primary",
      //           "&:hover": {
      //             color: "#c36d00",
      //           },
      //         },
      //       },
      //     },
      //     MuiSelect: {
      //       styleOverrides: {
      //         root: {
      //           background: "#f7f7f7",
      //           borderRadius: 5,
      //           minWidth: 10,
      //           marginTop: 3,
      //         },
      //       },
      //     },
      //     MuiButtonBase: {
      //       styleOverrides: {
      //         root: {
      //           height: "fit-content",
      //         },
      //       },
      //     },

      //     MuiButton: {
      //       defaultProps: {
      //         variant: "contained",
      //       },
      //       styleOverrides: {
      //         root: {
      //           color: "#f1f1f1",

      //           backgroundColor: "primary",
      //           "&:hover": {
      //             backgroundColor: "#578bc1",
      //           },
      //         },
      //         text: {
      //           color: "#103964",
      //           fontSize: 16,
      //           "&:hover": {
      //             backgroundColor: "#f1f1f1",
      //           },
      //         },
      //       },
      //     },

      //     MuiListItemIcon: {
      //       styleOverrides: {
      //         root: {
      //           color: "#103964",
      //         },
      //       },
      //     },

      //     MuiIconButton: {
      //       styleOverrides: {
      //         root: {
      //           color: "#fbfbfb",
      //         },
      //       },
      //     },

      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //     color: "#4b4c4e"
      //     }
      //   }
      // }
    },
  })
);

export default theme;
