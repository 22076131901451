import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useParams } from "react-router-dom";

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { rateOptionsData } from "../appState/appData/ratings";
import { getRatingForProduct, ratings } from "../appState/ratings";

export function Ratings({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const setRatingsItem = useSetRecoilState(ratings);
  const ratingInfo = useRecoilValue(getRatingForProduct(productId));
  const [mine, setMine] = useState(ratingInfo.mine);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    const avg = ratingInfo.avg === 0 ? mine : (ratingInfo.avg + mine) / 2;

    setRatingsItem((current) => ({
      ...current,
      [productId]: { avg, mine },
    }));

    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick} size="small" sx={{ pl: 0 }}>
        {rateOptionsData.map((value) =>
          value <= ratingInfo.avg ? (
            <StarIcon sx={{ color: "#719276" }} key={value} />
          ) : (
            <StarOutlineIcon sx={{ color: "#719276" }} key={value} />
          )
        )}
      </Button>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="column" mx={7} my={3}>
          <FormControl>
            <FormLabel sx={{ fontWeight: "bold", color: "secondary" }}>
              Rate this item
            </FormLabel>
            <FormGroup row>
              {rateOptionsData.map((item) =>
                item <= mine ? (
                  <StarIcon
                    sx={{ color: "#719276" }}
                    onClick={() => setMine(item)}
                    key={item}
                  />
                ) : (
                  <StarOutlineIcon
                    sx={{ color: "#719276" }}
                    onClick={() => setMine(item)}
                    key={item}
                  />
                )
              )}
            </FormGroup>
            <FormHelperText>Choose your rating</FormHelperText>
          </FormControl>

          <Button
            size="small"
            variant="contained"
            onClick={handleSubmit}
            type="submit"
            disabled={!mine}
          >
            Rate
          </Button>
        </Box>
      </Popover>
    </div>
  );
}
