import { ANIMALS, FLOWER  } from "./multiType";

//Food values
export const COOKIES = "Cookies";
export const MUFFINS = "Muffins";
export const CUPCAKES = "Cupcakes";
export const FINGERFOOD = "Finger Foods";
export const FRUIT = "Fruit";

export const CAKES = "Cakes";

export const COOKIESBROWNIES = "Cookies & Brownies";
export const CAKESCUPCAKE = "Cakes & Cupcakes";
export const BREADMUFFINS = "Breads & Muffins";

//is cake
export const BAKE = "Cake Baking";
export const POPS = "Cake Pops";
export const BROWNIES = "Brownies";
export const FILLING = "Filling";

// is cookie
export const GINGERBREAD = "Gingerbread";

//is cake and is cupcake
export const BUTTER = "Buttercream";
export const FONDANT = "Fondant";
export const PIPE = "Piping";
export const CHOCOLATE = "Chocolate";
export const VANI = "Vanilla";

// is muffin
export const BLUEBERRY = "Blueberry";
export const BANANA = "Banana Nut";
export const LEMONPOP = "Lemon Poppyseed";
export const APPLE = "Apple Cinnamon";

// is muffin and is cookie
export const CHIP = "Chocolate Chip";
export const PEA = "Peanut Butter";
export const OAT = "Oatmeal";
export const SUGAR = "Sugar";
export const LEMON = "Lemon";

// is muffin and is cake and is cupcake
export const CARROT = "Carrot";
export const COFFEE = "Coffee";
export const PUMPKIN = "Pumpkin Spice";
export const SUGARTOP = "Sugar Topping";
export const CRUMB = "Crumb Topping";
export const CREAM = "Cream Cheese Topping";

// is muffin and is cookie and is cake and is cupcake
export const TRIPLE = "Triple Chocolate";

// is cupcake

// is fingerfood
export const TOOTHPICK = "Toothpick appetizers";
export const SNACKS = "Snack dishes";
export const KABOBS = "Kabobs";
export const SKEWER = "Skewers";

// is chocolate
export const MODEL = "Modeling";
export const MOLD = "Molds";
export const BARS = "Bars";
export const BALLS = "Balls";
export const SPOON = "Spoons";

export const DESSERTS = "Desserts";
export const BREADS = "Breads";
export const PIES = "Pies";

// is fruit
export const VEG = "Vegetables";
export const EDIBALE = "Arrangements";
export const WATER = "Watermelon";
export const BOUQUET = "Fruit Bouquets";

export const JAR = "In a Jar";

export const DRINKS = "Drinks";
export const ICECREAM = "Ice Cream";

export const ALCOHOL = "Alcohol";

export const BREAKFAST = "Breakfast";
export const ICING = "Icing";

export const IS_CAKES_VALUES = [BUTTER, CHOCOLATE, FONDANT, PIPE, POPS, FLOWER];

export const IS_COOKIES_VALUES = [CHIP, PEA, OAT, SUGAR, LEMON, GINGERBREAD];

export const IS_MUFFINS_VALUES = [
  BLUEBERRY,
  BANANA,
  TRIPLE,
  PUMPKIN,
  APPLE,
  LEMONPOP,
  CARROT,
  CRUMB,
  SUGARTOP,
  CREAM,
];

export const IS_CUPCAKES_VALUES = [
  COFFEE,
  CHOCOLATE,
  LEMON,
  PEA,
  CARROT,
  FONDANT,
  FLOWER,
  BUTTER,
  VANI,
];

export const IS_FINGERFOOD_VALUES = [KABOBS, SKEWER, TOOTHPICK, SNACKS];

export const IS_CHOCOLATE_VALUES = [
  MODEL,
  JAR,
  BARS,
  BALLS,
  SPOON,
  CAKES,
  BROWNIES,
];
export const IS_FRUIT_VALUES = [ANIMALS, VEG, EDIBALE, WATER, BOUQUET];
export const ALL_FOOD_VALUES = [
  //0
  CAKESCUPCAKE,
  //1
  COOKIESBROWNIES,
  //2
  BREADMUFFINS,
  //3
  PIES,
  // 4
  FINGERFOOD,
  //5
  CHOCOLATE,
  //6
  FRUIT,
  //7
  DRINKS,
  //8
  ICECREAM,

];

export const FOOD_VALUE_TYPES = [
  //0
  BREAKFAST,
  //1
  DESSERTS,
  //2
  SNACKS,
  //3
  FONDANT,
  //4
  MODEL,
  //5
  ICING,
  //6
  CHOCOLATE,
  //7
  ALCOHOL,
  //8
  FILLING
];

export const ALL_BAKE_VALUE_TYPES = [
  ...IS_CAKES_VALUES,
  ...IS_COOKIES_VALUES,
  ...IS_MUFFINS_VALUES,
  ...IS_CUPCAKES_VALUES,
  ...IS_FINGERFOOD_VALUES,
  ...IS_CHOCOLATE_VALUES,
  ...IS_FRUIT_VALUES,
];
