import { Card, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { appProjects } from "../../appState/appProject";
import {
  NoShowSmall,
  ShowSmall,
} from "../../AppStyles";
import {
  CardButton,
  
} from "../HomeStyles";

export function HomeCategoriesCards() {
  const projectInfo = useRecoilValue(appProjects);

  return (
    <Box py={2}>
      {/* <StyledHomeCategoriesDivider
        variant="middle"
        sx={{ pb: 4, color: "text.secondary" }}
      >
        Explore
      </StyledHomeCategoriesDivider> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={4} sm={12} lg={10} alignSelf="center">
          {projectInfo.map((item) => (
            <Grid item xs={4} key={item.projectId} justifyContent="center">
              {/* <StyledCategoriesBox> */}

              <Card
                elevation={0}
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",

                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={item.categoryImg}
                  title={item.label}
                />

                <NoShowSmall>
                  <CardButton variant="outlined" title="Explore" {...item} />
                </NoShowSmall>

                <ShowSmall>
                  <CardButton title="All" {...item} />
                </ShowSmall>
              </Card>

              {/* </StyledCategoriesBox> */}
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
}
