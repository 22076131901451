import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
export function TrendingImages({ options, cols }) {
  return (
    <ImageList variant="woven" cols={cols} gap={2}>
      {options.slice(0, 10).map((image) => (
        <ImageListItem key={image.productId}>
          <img
            src={`${image?.img}?w=248&fit=crop&auto=format`}
            srcSet={`${image?.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={image.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={image.title}
            subtitle={<span>by: {image.postedBy}</span>}
            actionIcon={
              <IconButton
                href={`/product/${image?.productId}`}
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              >
                <ZoomInIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
