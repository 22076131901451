import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";

import { BitsAppBar } from "../bitsAppBar/AppBar";
import { HomeCategoriesCards } from "./homeImgsData/HomeCategoriesCards";

import { Footer } from "../bitsFooter/Footer";
import { BitsToolbar } from "../bitsToolbars/BitsToolbar";

import { useRecoilValue } from "recoil";
import { getProductsWithTag } from "../appState/productItems";
import { HomeDrawerImgGrid } from "./homeImgsData/HomeDrawerImgGrid";
import { TrendingGridCards } from "./homeImgsData/TrendingGridCards";
import { NoShowSmall, ShowSmall, StyledDivider } from "../AppStyles";
import { StyledHomeColoredPaper, StyledHomeImgGridItem } from "./HomeStyles";

//modal
import React, { useState } from "react";
import ResizeObserverContainer from "../../ResponsiveWidget/ResizeObserverContainer";
import ResponsiveComponent from "../../ResponsiveWidget/ResponsiveComponent";

// fix with bits
export function HomeLayout() {
  const featuredProducts = useRecoilValue(getProductsWithTag("featured"));
  const latestProducts = useRecoilValue(getProductsWithTag("latest"));
  const trendingProducts = useRecoilValue(getProductsWithTag("trending"));
  const exploreProducts = useRecoilValue(getProductsWithTag("explore"));

  
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <BitsAppBar />

      <br />
      <br />
      <div style={{ marginTop: 80 }}>
        <button onClick={toggleModal}>Open Modal</button>
        {showModal && (
          <ResizeObserverContainer isOpen={showModal} onClose={toggleModal}>
            {(width, height) => (
              <ResponsiveComponent
                containerWidth={width}
                containerHeight={height}
              />
            )}
          </ResizeObserverContainer>
        )}
      </div>

      {/* <ShowSmall> */}
      <CardMedia
        component="img"
        // image="/images/lbHome.png"
        image="/images/pagesHome.png"
        title="Welcome!"
        // sx={{ pt: 15 }}
        sx={{ objectFit: "cover", maxHeight: 500, pt: 12 }}
      />
      {/* </ShowSmall>*/}

      <NoShowSmall>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        > */}
        {/* <CardMedia
            component="img"
            // image="/images/helloSun.png"
            image="/images/homeimg.png"
            title="Welcome!"
            sx={{ pt: 15, pb: 3, maxWidth: 875 }}
          /> */}
        {/* </div> */}

        <BitsToolbar />
      </NoShowSmall>

      <ShowSmall>
        <Box sx={{ pt: 2 }}>
          <StyledDivider sx={{ pb: 3 }}>Featured Items</StyledDivider>
        </Box>

        <StyledHomeColoredPaper sx={{ pb: 3 }} elevation={0}>
          <HomeDrawerImgGrid py={1} options={featuredProducts} isFeatured />
        </StyledHomeColoredPaper>
      </ShowSmall>

      <NoShowSmall>
        <StyledHomeColoredPaper elevation={0}>
          <Grid container columns={16}>
            <StyledHomeImgGridItem item sm={3} display="flex">
              <Box display="flex" flexGrow={1} justifyContent="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "x-large",
                    color: "#6a583f",
                  }}
                >
                  Featured Items
                </Typography>
              </Box>
            </StyledHomeImgGridItem>

            <Grid item sm={13}>
              <HomeDrawerImgGrid py={2} options={featuredProducts} isFeatured />
            </Grid>
          </Grid>
        </StyledHomeColoredPaper>
      </NoShowSmall>

      <HomeCategoriesCards />

      <ShowSmall>
        <Box sx={{ pt: 2 }}>
          <StyledDivider sx={{ pb: 3 }}>Latest Items</StyledDivider>
        </Box>

        <StyledHomeColoredPaper sx={{ pb: 3 }} elevation={0}>
          <HomeDrawerImgGrid py={1} options={latestProducts} isFeatured />
        </StyledHomeColoredPaper>
      </ShowSmall>

      <NoShowSmall>
        <StyledHomeColoredPaper elevation={0}>
          <Grid container columns={16}>
            <Grid item sm={13}>
              <HomeDrawerImgGrid py={2} options={latestProducts} isFeatured />
            </Grid>
            <StyledHomeImgGridItem item sm={3} display="flex">
              <Box display="flex" flexGrow={1} justifyContent="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "x-large",
                    color: "#6a583f",
                  }}
                >
                  Latest Items
                </Typography>
              </Box>
            </StyledHomeImgGridItem>
          </Grid>
        </StyledHomeColoredPaper>
      </NoShowSmall>

      {/* <StyledDivider sx={{ pb: 4, pt: 5, color: "text.secondary" }}>
        Latest Items
      </StyledDivider> */}
      {/* 
      <StyledHomeColoredPaper elevation={0}>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <HomeDrawerImgGrid py={1} options={latestProducts} />
          </Grid>
        </Grid>
      </StyledHomeColoredPaper> */}

      <TrendingGridCards options={trendingProducts} />

      <StyledDivider
        sx={{ pt: 4, color: "text.secondary", backgroundColor: "#f7f4f0" }}
      >
        Others You Might Like
      </StyledDivider>

      <Card elevation={0} sx={{ backgroundColor: "#f7f4f0", pb: 4 }}>
        {/* <CardHeader title="More to Explore" />
        <Divider /> */}

        <HomeDrawerImgGrid pt={2} options={exploreProducts} />
      </Card>
      <Footer />
    </>
  );
}
