import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { styled } from "@mui/material/styles";

export const StyledBreadcrumbStack = styled(Stack)({
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export const StyledChipStack = styled(Stack)({
  justifyContent: "space-between",
  flexWrap: "wrap",
  paddingBottom: 5,
  minHeight: "45px",
});

export const StyledChipFilterStack = styled(Stack)({
  alignItems: "center",
  flexWrap: "wrap",
});

export const StyledProductViewListBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // margin: 2,
});

export const StyledDetailsBorder = styled(Box)({
  border: "1px solid rgba(0, 0, 0, 0.12)"
})

export const StyledProductDetailsDivider = styled(Divider)({
  fontWeight: "bold",
  fontSize: "large",
  py: 5,
  textAlign: "center"
});

export const StyledViewMoreBox = styled(Box)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  flexGrow: 1,
});

export const StyledProductViewImgBox = styled(Box)({
  display:"flex", justifyContent:"center", flexGrow:1
});

//bullet list
export function ProductBulletList({ item, isLg }) {
  return (
    <ListItem key={item} alignItems="center">
      <ListItemIcon sx={{ minWidth: "25px", mt: 1, pb: 1 }}>
        <CircleIcon
          color="primary"
          sx={{ fontSize: isLg ? "small" : "x-small" }}
        />
      </ListItemIcon>
      <ListItemText
        primary={item}
        primaryTypographyProps={{ fontSize: isLg ? "medium" : "small" }}
      />
    </ListItem>
  );
}

export function ProductImgBar({icon, link, label }) {
  return (
    <ListItem alignItems="center">
    <ListItemButton href={link} target="_blank">
      {label}
      <ListItemIcon
        sx={{
          pl: 2,
        }}
      >
       {icon}
      </ListItemIcon>
    </ListItemButton>
  </ListItem>
  )
}

export function ProductMoreFromList({options}) {
  return (
    <List disablePadding>
    {options?.map((item, index) => (
      <ListItem key={index} alignItems="flex-start">
        <ListItemButton href={`/product/${item.productId}`}>
          <ListItemIcon sx={{ mt: 1, pb: 1 }}>
            <img src={item.img} alt={index} height="40" width="40" />
          </ListItemIcon>

          <ListItemText
            primary={item.title}
            primaryTypographyProps={{ fontSize: "small" }}
          />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
  )
}