import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

export function Footer() {
  return (
    <AppBar
      position="static"
      color="primary"
      sx={{ top: "auto", bottom: 0, mt: 3 }}
    >
      <Toolbar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <Box>
            <Typography variant="h4" color="#729276" textAlign="center">
              Lovebug Bits
            </Typography>

            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign="center"
            >
              Create something beautiful
            </Typography>

            <Stack direction="row" justifyContent="space-evenly" py={2}>
              <YouTubeIcon color="primary" sx={{ fontSize: "xx-large" }} />

              <InstagramIcon color="primary" sx={{ fontSize: "xx-large" }} />

              <PinterestIcon color="primary" sx={{ fontSize: "xx-large" }} />

              <TwitterIcon color="primary" sx={{ fontSize: "xx-large" }} />
            </Stack>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
