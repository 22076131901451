import { Autocomplete, TextField } from "@mui/material";
import { React } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProductsForProject } from "../appState/productItems";

export function ProjectSearchBox() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const searchMe = useRecoilValue(getProductsForProject(projectId));

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        options={searchMe}
        onChange={(e, item) => {
          if (item) {
            navigate(`/product/${item.productId}`);
          }
        }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField {...params} label={`Search in ${projectId}`} />
        )}
      />
    </div>
  );
}
