import { Divider } from "@mui/material";

import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { StyledDetailsBorder } from "../bitsProductPage/ProductStyles";
import {
  ContentList,
  ExperienceList,
  SkillList,
} from "../checkboxes/ProjectCheckboxes";
import { ProjectCollapse } from "../checkboxes/ProjectCollapse";
import { ProjectSearchBox } from "../search/Search";

export function BitsProjectFilter() {
  const { projectId } = useParams();

  return (
    <Box>
      <StyledDetailsBorder>
        <Box p={0.5}>
          <ProjectSearchBox />
        </Box>
        <Divider />
        <ProjectCollapse label="Skill">
          <SkillList projectIdToUse={projectId} />
          <Divider />
        </ProjectCollapse>
        <ProjectCollapse label="Category">
          <ContentList projectIdToUse={projectId} />
          <Divider />
        </ProjectCollapse>
        <ProjectCollapse label="Experience">
          <ExperienceList projectIdToUse={projectId} />
        </ProjectCollapse>
      </StyledDetailsBorder>
    </Box>
  );
}
