import { appSections } from "../bitsAppSections";

import { ALL_SKILL_TYPES } from "../bitsCategoryTypes/multiType";
import {
  ALL_PATTERN_VALUES,
  PATTERN_VALUE_TYPES,
} from "../bitsCategoryTypes/pattern";
import { rateOptionsData } from "../ratings";
import {
  garterKnit,
  sewingBasics,
  scarf,
  eightStitchEmbroidery,
} from "../tutorials";

export const bitsPatterns = [
  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[3],
  //   categoryTypes: [PATTERN_VALUE_TYPES[0], PATTERN_VALUE_TYPES[2]],
  //   productId: "200",
  //   link: "",
  //   img: "https://flamingotoes.com/wp-content/uploads/2014/01/P1013816.jpg",

  //   imgSrc: [
  //     {
  //       original:
  //         "https://flamingotoes.com/wp-content/uploads/2014/01/P1013816.jpg",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://www.skiptomylou.org/wp-content/uploads/2013/11/gusset-coin-purse.jpg",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         " https://flamingotoes.com/wp-content/uploads/2014/01/embroidering-coin-purse-750x562.jpg",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "Embroidered Coin Purse",
  //   postedBy: 25,
  //   description: "if it has desc goes here",

  //   materials: [
  //     {
  //       section: "You will need",
  //       materialInfo: [
  //         "Fabric for outside of coin purse – about 8″ x 18″",
  //         "Fabric for lining of purse – same size",
  //         "Fusible Fleece – same size",
  //         "Coin purse frame – 8cm half round frames is used here",
  //         "Pattern for Coin purse with gusset - follow link",
  //         "E-6000 glue",
  //       ],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [claspPurse],

  //   rating: { rateOptions: rateOptionsData },
  // },
  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[1],
  //   categoryTypes: [PATTERN_VALUE_TYPES[0], PATTERN_VALUE_TYPES[2]],
  //   productId: "213",
  //   link: "https://www.lovecrafts.com/en-us/p/summer-vibes-beach-tote-bag-crochet-pattern-by-northern-lights-trail",
  //   img: "https://isv.prod.lovecrafts.co/v1/images/f01c1dda997db266958bd486b6203259/eb029328-1367-444d-956c-861733d0517f.jpg/0/-/144x144",
  //   imgSrc: [
  //     {
  //       original:
  //         "https://isv.prod.lovecrafts.co/v1/images/f01c1dda997db266958bd486b6203259/eb029328-1367-444d-956c-861733d0517f.jpg/0/-/144x144",
  //       thumbnail:
  //         "https://isv.prod.lovecrafts.co/v1/images/f01c1dda997db266958bd486b6203259/eb029328-1367-444d-956c-861733d0517f.jpg/0/-/144x144",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://isv.prod.lovecrafts.co/v1/images/d28469b8be64624487ee0a0811467d36/10fc99d2-ce40-4e2e-a3b3-1f11633bb5cc.jpg/0/-/144x144",
  //       thumbnail:
  //         "https://isv.prod.lovecrafts.co/v1/images/d28469b8be64624487ee0a0811467d36/10fc99d2-ce40-4e2e-a3b3-1f11633bb5cc.jpg/0/-/144x144",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://isv.prod.lovecrafts.co/v1/images/795c371c0debace11e45bbafe1ae480c/739e92a0-9b81-4db1-90bc-3ef9c2e26f16.jpg/0/-/144x144",
  //       thumbnail:
  //         "https://isv.prod.lovecrafts.co/v1/images/795c371c0debace11e45bbafe1ae480c/739e92a0-9b81-4db1-90bc-3ef9c2e26f16.jpg/0/-/144x144",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "Summer Vibes beach tote bag",
  //   postedBy: 34,
  //   description:
  //     "If you are looking for a crochet tote bag, shoulder bag, summer bag, beach bag, drawstring bag, market bag, shopping bag or handbag featuring nicely textured pattern, you are at the right place! This bag is so versatile!",
  //   skillTypeId: ALL_SKILL_TYPES[1],
  //   materials: [
  //     {
  //       section: "",
  //       materialInfo: [""],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  //   tags: ["trending"],
  // },
  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[1],
  //   categoryTypes: [
  //     PATTERN_VALUE_TYPES[0],
  //     PATTERN_VALUE_TYPES[5],
  //     PATTERN_VALUE_TYPES[6],
  //   ],
  //   productId: "215",
  //   link: "https://www.thesprucecrafts.com/make-stitching-sparkle-with-beads-sequins-4109696",
  //   img: "https://www.thesprucecrafts.com/thmb/qFyIOgtK2E3qb6eLVESdySXS3rY=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/BeadsAndSequins1-581cce425f9b581c0b6358f7.jpg",
  //   imgSrc: [
  //     {
  //       original: "",
  //       thumbnail: "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original: "",
  //       thumbnail: "",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   title: "",
  //   postedBy: 28,
  //   description: "If you are looking for a crochet tote bag, shoulder bag, summer bag, beach bag, drawstring bag, market bag, shopping bag or handbag featuring nicely textured pattern, you are at the right place! This bag is so versatile!",

  //   materials: [
  //     {
  //       section: "",
  //       materialInfo: [
  //         "",
  //       ],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  //   tags: ["trending"],
  // },
  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[1],
  //   categoryTypes: [PATTERN_VALUE_TYPES[2], PATTERN_VALUE_TYPES[0]],
  //   productId: "213",
  //   img: "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_02.jpg~w=1000,h=1000,v=1",

  //   link: "https://www.crochet.com/barefoot-yoga-bag/p/58189",
  //   imgSrc: [
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_02.jpg~w=1000,h=1000,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_02.jpg~w=1000,h=1000,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_05.jpg~w=1000,h=1000,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_05.jpg~w=1000,h=1000,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_04.jpg~w=1000,h=1000,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_04.jpg~w=1000,h=1000,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_03.jpg~w=1000,h=1000,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_03.jpg~w=1000,h=1000,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     // "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_02.jpg~w=1000,h=1000,v=1",
  //     // "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_05.jpg~w=1000,h=1000,v=1",
  //     // "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_04.jpg~w=1000,h=1000,v=1",
  //     // "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58189220_03.jpg~w=1000,h=1000,v=1",
  //   ],
  //   title: "Barefoot Yoga Bag",
  //   postedBy: 31,
  //   description: "if it has desc goes here",

  //   skillTypeId: ALL_SKILL_TYPES[2],
  //   materials: [
  //     {
  //       section: "Yarn Weight:",
  //       materialInfo: ["Lace"],
  //     },

  //     {
  //       section: "Fiber Type ",
  //       materialInfo: ["Cotton"],
  //     },
  //     {
  //       section: "Hook",
  //       materialInfo: [" F/3.75mm crochet hook"],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [round],

  //   rating: { rateOptions: rateOptionsData },
  // },

  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[1],
  //   categoryTypes: [PATTERN_VALUE_TYPES[2], PATTERN_VALUE_TYPES[0]],
  //   productId: "214",
  //   img: "https://d2q9kw5vp0we94.cloudfront.net/i/_/regular/18788220.jpg~w=146,h=146,q=85,v=1",

  //   link: "https://www.crochet.com/bermuda-tote/p/18788D",
  //   imgSrc: [
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/regular/18788220.jpg~w=146,h=146,q=85,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/regular/18788220.jpg~w=146,h=146,q=85,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/18788220.jpg~w=1000,h=1000,v=1",
  //       thumbnail:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/18788220_02.jpg~w=1000,h=1000,v=1",
  //       thumbnailHeight: "90px",
  //       originalHeight: "320px",
  //     },
  //   ],

  //   title: "Bermuda Tote",

  //   postedBy: 31,
  //   description: "if it has desc goes here",

  //   materials: [
  //     {
  //       section: "Yarn",
  //       materialInfo: [
  //         "Weight: Bulky, Worsted",
  //         "Yardage: 1230",
  //         "Fiber Type: Acrylic",
  //       ],
  //     },
  //     {
  //       section: "Hook",
  //       materialInfo: ["G/4.0mm crochet hook"],
  //     },
  //     {
  //       section: "",
  //       materialInfo: [""],
  //     },
  //   ],
  //   servings: "",
  //   skillTypeId: ALL_SKILL_TYPES[2],
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [treble, sc2Tog, slip, double],

  //   rating: { rateOptions: rateOptionsData },
  // },
  // {
  //   projectId: appSections[1].projectId,

  //   categoryId: ALL_PATTERN_VALUES[1],
  //   categoryTypes: [PATTERN_VALUE_TYPES[2], PATTERN_VALUE_TYPES[0]],
  //   productId: "215",
  //   link: "https://www.crochet.com/darcy-tote-bag/p/56287",

  //   img: "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/56287220.jpg~w=1000,h=1000,v=1",
  //   title: "Darcy Tote Bag",
  //   imgSrc: [
  //     {
  //       original:
  //         "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/56287220.jpg~w=1000,h=1000,v=1",
  //       originalHeight: "320px",
  //     },
  //   ],
  //   postedBy: 31,
  //   description: "if it has desc goes here",

  //   skillTypeId: ALL_SKILL_TYPES[2],
  //   materials: [
  //     {
  //       section: "Yarn",
  //       materialInfo: [" Weight: Worsted", "Yardage: 436"],
  //     },
  //     {
  //       section: "Hook",
  //       materialInfo: ["G/4.0mm crochet hook"],
  //     },
  //   ],
  //   servings: "",
  //   estTimeComplete: "",
  //   directions: [],
  //   tutorials: [],

  //   rating: { rateOptions: rateOptionsData },
  // },

  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[3],
    categoryTypes: PATTERN_VALUE_TYPES[2],
    productId: "201",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 9,
    title: "Fold-Up Market Tote",
    description:
      "Finished Dimensions: 18 ¾ inches wide X 15 inches tall, plus a 16-inch handle drop",
    link: "https://www.purlsoho.com/create/2016/08/05/fold-up-market-tote/",
    img: "https://www.purlsoho.com/create/wp-content/uploads/2016/08/fold-up-market-tote-600-4.jpg",
    imgSrc: [
      {
        original:
          "https://www.purlsoho.com/create/wp-content/uploads/2016/08/fold-up-market-tote-600-4.jpg",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "A fabric marker",
          "1 yard of fabric",
          "1 yard of ribbon",
          "thread",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [sewingBasics],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: PATTERN_VALUE_TYPES[11],
    productId: "202",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 26,
    title: "Easter bunny toy",
    description:
      "When using similar materials, the size of the finished toy is 10 cm  4",
    link: "https://ravel.me/easter-bunny-toy-2",
    img: "/images/productImgs/bunnyToy1.png",
    imgSrc: [
      {
        original: "/images/productImgs/bunnyToy1.png",
        thumbnail: "/images/productImgs/bunnyToy1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },

      {
        original: "/images/productImgs/bunnyToy2.png",
        thumbnail: "/images/productImgs/bunnyToy2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/bunnyToy3.png",
        thumbnail: "/images/productImgs/bunnyToy3.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/bunnyToy4.png",
        thumbnail: "/images/productImgs/bunnyToy4.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "What you will need",
        materialInfo: [
          "Gray and white yarn I used YarnArt Jeans Plus 100 g-160 m 4 worsted & aran",
          "Hook (I used 3 mm), it’s better to take a smaller size than the one recommended for yarn.",
          "Toy stuffing – Hollofiber filling",
          "Beads for eyes 5 mm",
          "Scissors",
          "Needle for details sewing",
          "Pink yarn for nose",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: PATTERN_VALUE_TYPES[0],
    productId: "203",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 26,
    title: "Easy Mesh Bottle Holder",
    description: "Cute bottle holder",
    link: "https://ravel.me/easy-mesh-bottle-holder",
    img: "/images/productImgs/meshBottle1.png",
    imgSrc: [
      {
        original: "/images/productImgs/meshBottle1.png",
        thumbnail: "/images/productImgs/meshBottle1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/meshBottle2.png",
        thumbnail: "/images/productImgs/meshBottle2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/meshBottle3.png",
        thumbnail: "/images/productImgs/meshBottle3.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Gauge",
        materialInfo: ["6 stitches and 7 rows = 1 inch"],
      },
      {
        section: "Hook size",
        materialInfo: ["3.5 mm (E)"],
      },
      {
        section: "Yardage",
        materialInfo: ["300 - 320 yards (274 - 293 m)"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: PATTERN_VALUE_TYPES[1],
    productId: "204",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 27,
    title: "Love Notes Afghan",
    description: "if it has desc goes here",
    link: "https://crochetml.com/free-crochet-pattern-love-notes-afghan/",
    img: "/images/productImgs/loveNotesblanket1.png",
    imgSrc: [
      {
        original: "/images/productImgs/loveNotesblanket1.png",
        thumbnail: "/images/productImgs/loveNotesblanket1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/loveNotesblanket2.png",
        thumbnail: "/images/productImgs/loveNotesblanket2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["featured", "latest"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: [PATTERN_VALUE_TYPES[0], PATTERN_VALUE_TYPES[5]],
    productId: "205",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 28,
    title: "Fortune’s Hat",
    description: "if it has desc goes here",
    link: "https://www.mooglyblog.com/fortunes-hat/",
    img: "/images/productImgs/fortuneHat1.png",
    imgSrc: [
      {
        original: "/images/productImgs/fortuneHat1.png",
        thumbnail: "/images/productImgs/fortuneHat1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/fortuneHat2.png",
        thumbnail: "/images/productImgs/fortuneHat2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "* US – L, 8.0 mm hook",
          "US – J 6.00 mm hook",
          "150 yds DK / 8 ply",
          "306 yds /280 m",
          "3.53 oz in Wild Berry",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[0],
    categoryTypes: PATTERN_VALUE_TYPES[8],
    productId: "206",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 30,
    title: "Knit baby hat, vest and booties",
    description: "if it has desc goes here",
    link: "https://imknitting.com/knitting-lessons/free-knitting-patterns-for-baby-2000.html",
    img: "https://imknitting.com/wp-content/uploads/2019/06/knit-baby-hat-vest-booties.jpg",
    imgSrc: [
      {
        original:
          "https://imknitting.com/wp-content/uploads/2019/06/knit-baby-hat-vest-booties.jpg",
        thumbnail:
          "https://imknitting.com/wp-content/uploads/2019/06/knit-baby-hat-vest-booties.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://imknitting.com/wp-content/uploads/2019/06/knit-baby-vest-hat-booties.jpg",
        thumbnail:
          "https://imknitting.com/wp-content/uploads/2019/06/knit-baby-vest-hat-booties.jpg",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Supplies",
        materialInfo: ["Needle 3,5", "Hook  3", "4 balls yarn"],
      },
      {
        section: "Accessories",
        materialInfo: ["2 buttons", "ribbon"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [garterKnit],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[2],
    categoryTypes: PATTERN_VALUE_TYPES[3],
    productId: "207",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 35,
    title: "Happily Ever After Stitched",
    description:
      "Put a ring on it with a beautiful marriage between family photo and embroidery floss, cleverly framed in your hoop. The secret to lasting happiness? Iron on transfer paper to place the picture on fabric. The rest is a piece of cake!",
    link: "https://www.hobbylobby.com/DIY-Projects-Videos/Yarn-Needle-Arts/Happily-Ever-After-Stitched/p/17_VND08_Happily",
    img: "https://imgprd19.hobbylobby.com/sys-master/migrated/hd7/h02/h00/9459100090398/embroidered%20photo685x366.jpg",
    imgSrc: [
      {
        original:
          "https://imgprd19.hobbylobby.com/sys-master/migrated/hd7/h02/h00/9459100090398/embroidered%20photo685x366.jpg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Supplies",
        materialInfo: [
          "Round wood embroidery hoop 8",
          "Cotton fabric 9 x 9",
          "Embroidery scissors",
          "Scissors",
          "DMC embroidery needle size 3",
          "Iron-on transfer paper",
          "Iron",
          "Glue",
        ],
      },
      {
        section: "DMC Six Strand Embroidery Floss",
        materialInfo: [
          "one skein each: 550, 209, 702, 704, 972, 3846, 608, 892, BLANC",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [eightStitchEmbroidery],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: PATTERN_VALUE_TYPES[2],
    productId: "208",
    skillTypeId: ALL_SKILL_TYPES[0],
    postedBy: 35,
    title: "Crochet Tote",
    description:
      "Handmade style is in the bag with this crocheted tote! Follow along with the pattern as we walk you through the process of making it. And don’t forget to head to our YouTube channel for even more creative inspiration!",
    link: "https://www.hobbylobby.com/DIY-Projects-Videos/Yarn-Needle-Arts/DIY-Crochet-Yarn-Bag/p/22-4671-Crochet-Yarn-Bag",
    img: "https://imgprd19.hobbylobby.com/sys-master/root/hca/hf5/h00/10418073600030/22-4671_ProjectCard-DTLR_006.jpeg",
    imgSrc: [
      {
        original:
          "https://imgprd19.hobbylobby.com/sys-master/root/hca/hf5/h00/10418073600030/22-4671_ProjectCard-DTLR_006.jpeg",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "",
        thumbnail: "",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "",
        materialInfo: [
          "Yarn Bee® Soft & Sleek Super Bulky™, 8 oz (227 g) skein of 70 yds (64 m): – Viridescent – 3 skeins",
          "Yarn needle",
          "Stitch markers",
          "Stitch markers",
          "US size N/15 (10mm) crochet hook",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["trending"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: [PATTERN_VALUE_TYPES[1], PATTERN_VALUE_TYPES[11]],
    productId: "209",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 32,
    title: "Little square Doll Blanket",
    description: "if it has desc goes here",
    link: "https://attic24.typepad.com/weblog/2013/02/little-squares.html",
    img: "https://attic24.typepad.com/.a/6a00e551101c548834017d40d95156970c-500wi",
    imgSrc: [
      {
        original:
          "https://attic24.typepad.com/.a/6a00e551101c548834017d40d98380970c-500wi",
        thumbnail:
          "https://attic24.typepad.com/.a/6a00e551101c548834017d40d98380970c-500wi",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://attic24.typepad.com/.a/6a00e551101c548834017d40d95156970c-500wi",
        thumbnail:
          "https://attic24.typepad.com/.a/6a00e551101c548834017d40d95156970c-500wi",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://attic24.typepad.com/.a/6a00e551101c548834017c36aad79f970b-500wi",
        thumbnail:
          "https://attic24.typepad.com/.a/6a00e551101c548834017c36aad79f970b-500wi",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Supplies",
        materialInfo: [
          "Scraps of yarn different colors",
          "Hook size H or size of your liking",
          "Scissors",
        ],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [
      "Chain 4, sl st into 1st chain to form a ring. Chain 3 (counts as 1 tr), 2 tr's into ring, chain 2, 3 tr's into ring, chain 2, 3 tr's into ring, chain 2, 3 tr's into ring, chain 2, then sl st into 3rd chain of initial ch-3 to close round. Fasten off leaving long tail end for stitching to neighbouring granny!",
      "This is written in UK terms...for those of you in the US, you need to work DC stitches!",
    ],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[1].projectId,
    //   categoryId: ALL_PATTERN_VALUES[1],
    //   categoryTypes: [PATTERN_VALUE_TYPES[2], PATTERN_VALUE_TYPES[0]],
    productId: "210",
    skillTypeId: ALL_SKILL_TYPES[2],
    postedBy: 31,
    title: "Diamond Bucket Bag",
    description: "if it has desc goes here",
    link: "https://www.crochet.com/diamond-bucket-bag/p/58058",
    img: "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220.jpg~w=1000,h=1000,v=1",
    imgSrc: [
      {
        original:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220.jpg~w=1000,h=1000,v=1",
        thumbnail:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220.jpg~w=1000,h=1000,v=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220_2_crochet.jpg~w=1000,h=1000,v=1",
        thumbnail:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220_2_crochet.jpg~w=1000,h=1000,v=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220_3_crochet.jpg~w=1000,h=1000,v=1",
        thumbnail:
          "https://d2q9kw5vp0we94.cloudfront.net/i/_/big/58058220_3_crochet.jpg~w=1000,h=1000,v=1",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Yarn",
        materialInfo: ["Weight: Bulky", "Yardage: 680"],
      },
      {
        section: "Hook",
        materialInfo: ["Sizes I/9 (5.5mm) & K/10.5 (6.5mm) Crochet hooks"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: [
      PATTERN_VALUE_TYPES[5],
      PATTERN_VALUE_TYPES[6],
      PATTERN_VALUE_TYPES[0],
    ],
    productId: "211",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 28,
    title: "Ups and Downs Crochet Slouchy Beanie",
    description: "if it has desc goes here",
    link: "https://mooglyblog.com/ups-and-downs-crochet-slouchy-beanie/",
    img: "/images/productImgs/upDownBean1.png",
    imgSrc: [
      {
        original: "/images/productImgs/upDownBean1.png",
        thumbnail: "/images/productImgs/upDownBean1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/upDownBean2.png",
        thumbnail: "/images/productImgs/upDownBean2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],

    materials: [
      {
        section: "",
        materialInfo: [
          "US – I, 5.5mm hook",
          "150-200 yds worsted weight yarn",
          "100% Merino Wool 164 yds/100g",
          "2 buttons (1.25″/32mm",
          "2 stitch markers",
          "tapestry or yarn needle",
        ],
      },
      {
        section: "Gauge",
        materialInfo: ["12 sts x 7 rows = 4″ in dc"],
      },
      {
        section: "Sizes",
        materialInfo: ["S (18-20″); M (20-22″); L (22-24″)"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [],
    rating: { rateOptions: rateOptionsData },
    tags: ["explore"],
  },
  {
    projectId: appSections[1].projectId,
    categoryId: ALL_PATTERN_VALUES[1],
    categoryTypes: [PATTERN_VALUE_TYPES[5], PATTERN_VALUE_TYPES[6]],
    productId: "212",
    skillTypeId: ALL_SKILL_TYPES[1],
    postedBy: 27,
    title: "Everlasting Frost Cowl",
    description: "if it has desc goes here",
    link: "https://crochetml.com/free-crochet-pattern-everlasting-frost-cowl/",
    img: "/images/productImgs/frostCowl1.png",
    imgSrc: [
      {
        original: "/images/productImgs/frostCowl1.png",
        thumbnail: "/images/productImgs/frostCowl1.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
      {
        original: "/images/productImgs/frostCowl2.png",
        thumbnail: "/images/productImgs/frostCowl2.png",
        thumbnailHeight: "90px",
        originalHeight: "320px",
      },
    ],
    materials: [
      {
        section: "Yarn weight",
        materialInfo: ["Aran (8 wpi)"],
      },
      {
        section: "Hook size",
        materialInfo: ["5.0 mm (H)"],
      },
    ],
    servings: "",
    estTimeComplete: "",
    directions: [],
    tutorials: [scarf],
    rating: { rateOptions: rateOptionsData },
    tags: [],
  },
];
