import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { CardMedia, Divider, List, Stack } from "@mui/material";
import { Box } from "@mui/system";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../../appState/productItems";
import { ProductBulletList, ProductImgBar } from "../ProductStyles";
import { ShowSmall } from "../../AppStyles";

export function ProductHowTo({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const product = useRecoilValue(getProduct(productId));

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" flexWrap={"wrap"}>
        <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
          <List disablePadding>
            {product?.directions?.map((name) => (
              <ProductBulletList item={name} isLg />
            ))}
          </List>
          {product?.tutorials?.map((item) => (
            <Box p={2} key={item}>
              <iframe title={item.title} {...item.iframe} />
              <ProductImgBar
                icon={<YouTubeIcon />}
                link={item.link}
                label={item.label}
              />
            </Box>
          ))}
          <Box p={1}>
            <Box display="flex" p={1} justifyContent="center" flexGrow={1}>
              <List>
                <CardMedia
                  component="img"
                  image={product.img}
                  title={product.productId}
                  height="215"
                  width="300"
                  
                  sx={{ objectFit: "contain" }}
                />
                <ProductImgBar
                  icon={<LabelImportantIcon />}
                  link={product.link}
                  label={product.title}
                />
              </List>
            </Box>
          </Box>
        </Box>
      </Stack>
      <ShowSmall>
        <Divider sx={{ mb: 1 }} />
      </ShowSmall>
    </div>
  );
}
