import { Box, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledGalleryImgCardWrapper = styled(Box)({
  flexDirection: "column",
  minWidth: "220px",
  padding: 3,
  display: "flex",
});

export const StyledGalleryCard = styled(Card)({
  display: "flex",
  alignItems: "stretch",
  border: "1px solid rgba(0, 0, 0, 0.12)",
});

export const StyledGalleryCardTitle = styled(Typography)({
  flexWrap: "noWrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: 200,
  paddingLeft: 5,

})

export const StyledGalleryCardListedBy = styled(Typography)({
  fontWeight:"bold",
  paddingLeft: 5,
})

//grid
export function GalleryProductsGrid({children}) {
  return (
    <Grid
    item
    xs={12}
    sm={10}
    md={6}
    lg={4}
    xl={3}
    sx={{
      alignItems: "stretch",
      // border: "1px solid rgba(0, 0, 0, 0.12)",
    }}
  >
    {children}
    </Grid>
  )
}