//appProjects

// import { ALL_PROJECT_FILTERED_VALUES } from "./bitProjectTypes";
import { ALL_FOOD_VALUES, FOOD_VALUE_TYPES } from "./bitsCategoryTypes/bake";
import { ALL_CRAFT_VALUES, CRAFT_CATEGORY_TYPES } from "./bitsCategoryTypes/craft";
import { ALL_PATTERN_VALUES, PATTERN_VALUE_TYPES } from "./bitsCategoryTypes/pattern";

export const appSections = [
  {
    projectId: "Crafting",
    label: "Crafting",
    src: "/images/pagesCraft.png",
    mediaSrc: "/images/categories/sectionCraftSmImg.png",
    categoryImg: "/images/categories/catCrafts.png",

    // projectTypes: ALL_PROJECT_FILTERED_VALUES,

    categories: ALL_CRAFT_VALUES,
    categoryTypes: CRAFT_CATEGORY_TYPES,
    // categoryTypes: ALL_CRAFT_VALUE_TYPES,
  },
  {
    projectId: "Patterns",
    label: "Patterns",
    // projectTypes: ALL_PROJECT_FILTERED_VALUES,
    src: "/images/pagesPattern.png",
    mediaSrc: "/images/categories/sectionPatternSmImg.png",
    categoryImg: "/images/categories/catPatterns.png",

    categories: ALL_PATTERN_VALUES,
    categoryTypes: PATTERN_VALUE_TYPES,
    // categoryTypes: ALL_PATTERN_VALUE_TYPES,
  },
  {
    projectId: "Baking",
    label: "Baking",
    // projectTypes: ALL_PROJECT_FILTERED_VALUES,
    src: "/images/pagesBake.png",
    mediaSrc: "/images/categories/sectionBakeSmImg.png",
    categoryImg: "/images/categories/catBaking.png",

    categories: ALL_FOOD_VALUES,
    categoryTypes: FOOD_VALUE_TYPES,
    // categoryTypes: ALL_BAKE_VALUE_TYPES,
  },
];
