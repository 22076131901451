import { Avatar, Button, Stack } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useRecoilValue } from "recoil";

import { useParams } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box } from "@mui/system";
import { getProduct } from "../appState/productItems";

export function Share({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const product = useRecoilValue(getProduct(productId) || []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button endIcon={<ShareIcon />} variant="contained" onClick={handleClick}>
        Share
      </Button>

      {/* <IconButton onClick={handleClick}>
        <ShareIcon />
      </IconButton> */}
      <Popover
        key={product?.productId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} color="#565e4a">
          Share on
          <Stack direction="row" gap={2} p={1}>
            <Avatar>
              <img
                src="/images/whiteLeaf.png"
                alt="us"
                height={25}
                width={25}
              />
            </Avatar>
            <Avatar>
              <FacebookIcon />
            </Avatar>
            <Avatar>
              <InstagramIcon />
            </Avatar>
            <Avatar>
              <PinterestIcon />
            </Avatar>
            <Avatar>
              <TwitterIcon />
            </Avatar>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
}
