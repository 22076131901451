import { List, ListSubheader } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../../appState/productItems";
import { ProductBulletList } from "../ProductStyles";

export function ProductMaterialsList({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const product = useRecoilValue(getProduct(productId))?.materials.map(
    (item) => {
      return item;
    }
  );

  return (
    <div>
      <Box>
        <List>
          {product?.map((item) => (
            <div key={item}>
              <ListSubheader>{item.section}</ListSubheader>
              {item.materialInfo.map((item) => (
                <ProductBulletList item={item} isLg />
              ))}
            </div>
          ))}
        </List>
        {/* <Stack direction="column">
        <div>{product.categoryId}</div>

        {product?.skillTypeId}
        {product?.servings}
        {product?.estTimeComplete}
      </Stack> */}
      </Box>
    </div>
  );
}
