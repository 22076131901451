import { AppBar, Button, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const StyledAppBarColor = styled(AppBar)({
  backgroundColor: "#f2e8e0db",
  justifyContent: "space-between",
});

export const StyledLogoText = styled(Typography)({
  alignSelf: "flex-end",
  paddingBottom: 1,
  paddingLeft: 3,
  // fontWeight: "bold",
  fontFamily: "Poppins, san-serif",
  color: "#729276",
  // textAlign: "center",
});

export const StyledDrawerLogoText = styled(Typography)({
  paddingLeft: 3,
  fontFamily: " 'Poppins', sans-serif",
  color: "#729276",
  textAlign: "center",
  fontSize: "x-large",
});

export const StyledCenterStack = styled(Stack)({
  alignSelf: "center",
  alignItems: "center",
});

export const StyledBitsToolbarButton = styled(Button)({
  fontSize: "large",
  color: "#6e5e49",
  size: "large",
  fontFamily: "'Poppins', sans-serif",
});

export const StyledBitsToolbarStack = styled(Stack)({
  justifyContent: "space-evenly",
  padding: 3,
  // marginBottom: 2,
  // marginTop: 3,
  backgroundColor: "#f6efe952",
});

export const StyledSearchDrawerStack = styled(Stack)({
  padding: 8,
  paddingBlock: 18,
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledCategoryListDrawer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledDivider = styled(Divider)({
  fontWeight: "bold",
  fontSize: "x-large",
  fontFamily: "Poppins, san-serif",
});

// show boxes
export function NoShowXSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function NoShowSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowXSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "none",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowSmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowOnlySmall({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowOnlyXsMd({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}

export function ShowLg({ children }) {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {children}
    </Box>
  );
}
