//and craft  and bake
export const FLOWER = "Flowers";
export const ANIMALS = "Animals";

//and craft  and bake and shop
export const INTERIOR = "Home Decor";
export const ACCESSORIES = "Accessories";
export const WEARABLE = "Clothing";
export const OTHER = "Other";

export const CELEBRATE = "Holidays and Events";

export const PETS = "Pets";

export const MANDALA = "Mandala Stencil";

export const JUSTADDED = "Just Added";
export const HIGHEST = "Popular";
export const HOLIDAY = "Holiday";

//shop filters
// export const SALES = "Sales";
// export const CLEARANCE = "Clearance";
// export const POSTER = "By:";
export const BESTSELLERS = "Bestsellers";

export const CRAFT = "Crafting";
export const BAKE = "Baking";
export const PATTERN = "Patterns";

//skillType
export const BEGINNER = "Beginners";
export const EASY = "Easy";
export const INTERMEDIATE = "Intermediate";
export const ADVANCED = "Advanced";

export const ALL_SKILL_TYPES = [EASY, INTERMEDIATE, ADVANCED];

export const ALL_SEARCH_TYPES = [
  { type: "categoryTypes", label: INTERIOR },
  { type: "categoryTypes", label: ACCESSORIES },
  { type: "projectId", label: CRAFT },
  { type: "projectId", label: BAKE },
  { type: "projectId", label: PATTERN },
];
