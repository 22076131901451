import { Divider, List, ListItem, ListItemText, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../../appState/productItems";
import { ShowSmall } from "../../AppStyles";

export function ProductDescription({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();

  const product = useRecoilValue(getProduct(productId));

  return (
    <div>
      <Stack direction="column">
        <List disablePadding>
          {!!product?.description.length ? (
            <ListItem key={product.productId} alignItems="center">
              <ListItemText
                primary={product?.description}
                primaryTypographyProps={{ fontSize: "small" }}
              />
            </ListItem>
          ) : null}
          <ListItem>
            <ListItemText
              primary={`Skill Type: ${product?.skillTypeId}`}
              primaryTypographyProps={{ fontSize: "small" }}
              secondary={
                <Stack>
                  {product.categoryId}
                  {product?.estTimeComplete}
                </Stack>
              }
            />
          </ListItem>
        </List>
      </Stack>
      <ShowSmall>
        <Divider sx={{ mb: 1 }} />
      </ShowSmall>
    </div>
  );
}
