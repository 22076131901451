import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ALL_SKILL_TYPES } from "../appState/appData/bitsCategoryTypes/multiType";
import {
  getProject,
  productSkillTypesFilters,
  projectCategoryFilters,
  projectCategoryTypesFilters,
} from "../appState/appProject";
import { CheckboxGroup } from "./CheckboxGroup";

export function ExperienceList({ projectIdToUse }) {
  const { projectId = projectIdToUse } = useParams;
  const [selectedSkills, setSelectedSkills] = useRecoilState(
    productSkillTypesFilters(projectId)
  );

  return (
    <CheckboxGroup
      options={ALL_SKILL_TYPES}
      selectedValues={selectedSkills}
      setValues={setSelectedSkills}
    />
  );
}

export function SkillList({ projectIdToUse }) {
  const { projectId = projectIdToUse } = useParams;
  const [selectedSkills, setSelectedSkills] = useRecoilState(
    projectCategoryFilters(projectId)
  );

  const project = useRecoilValue(getProject(projectId));
  return (
    <CheckboxGroup
      options={project.categories}
      selectedValues={selectedSkills}
      setValues={setSelectedSkills}
    />
  );
}

export function ContentList({ projectIdToUse }) {
  const { projectId = projectIdToUse } = useParams;
  const [selectedSkills, setSelectedSkills] = useRecoilState(
    projectCategoryTypesFilters(projectId)
  );

  const project = useRecoilValue(getProject(projectId));
  return (
    <CheckboxGroup
      options={project.categoryTypes}
      selectedValues={selectedSkills}
      setValues={setSelectedSkills}
    />
  );
}
