import { atom, selectorFamily } from "recoil";
// import { groupBy, orderBy } from "lodash";
// import { productItems } from "./productItems";
// import { rateOptions } from "./appData/bitsCategoryTypes/shop";
import { ratingsData } from "./appData/ratings";
import { localStorageEffect } from "./localStorage/localStorageEffect";

export const ratings = atom({
  key: "ratings",
  default: {},
  effects_UNSTABLE: [
    localStorageEffect("ratings", { '228': { avg: 4, mine: 2 } }, ratingsData),
  ],
});

export const getRatingForProduct = selectorFamily({
  key: "getRatingForProduct",
  get:
    (productId) =>
    ({ get }) =>
      get(ratings)[productId] || { avg: 0, mine: 0 },
});



export const getRatingsForProduct = selectorFamily({
  key: "getVotesForPoll",
  get:
    (productId) =>
    ({ get }) =>
      get(ratings).filter((item) => item.productId === productId),
});




// from bitsproductitems
//q&a
// qAnswers: [
//   {
//     qId: "1",
//     postedBy: 1,
//     displayName: "jj",
//     timestamp: 1652928063591,
//     question: "hello",
//     answers: [
//       {
//         answerId: "1",
//         postedBy: 1,
//         displayName: "jj",
//         timestamp: 1652928063591,
//         answer: "idk",
//       },
//       {
//         answerId: "2",
//         postedBy: 2,
//         displayName: "rr",
//         timestamp: 1652928063591,
//         answer: "its good",
//       },
//     ],
//   },
// ],


//reviews
// reviews: [
//   {
//     reviewId: "1",
//     postedBy: 1,
//     displayName: "jj",
//     timestamp: 1652928063591,
//     review: "hello",
//     userRating: rateOptionsData[2],
//   },
//   {
//     reviewId: "2",
//     postedBy: 2,
//     displayName: "jppj",
//     timestamp: 1652928063591,
//     review: "heyyy",
//     userRating: rateOptionsData[3],
//   },
//   {
//     reviewId: "3",
//     postedBy: 1,
//     displayName: "jiiij",
//     timestamp: 1652928063591,
//     review: "hello snnakka",
//     userRating: rateOptionsData[4],
//   },
//   {
//     reviewId: "4",
//     postedBy: 1,
//     displayName: "jlllj",
//     timestamp: 1652928063591,
//     review: "hello snnakka bubbb",
//     userRating: rateOptionsData[1],
//   },
//   {
//     reviewId: "4",
//     postedBy: 1,
//     displayName: "jlllj",
//     timestamp: 1652928063591,
//     review: "hello snnakka bubbb",
//     userRating: rateOptionsData[4],
//   },
//   {
//     reviewId: "4",
//     postedBy: 1,
//     displayName: "jlllj",
//     timestamp: 1652928063591,
//     review: "hello snnakka bubbb",
//     userRating: rateOptionsData[4],
//   },
//   {
//     reviewId: "4",
//     postedBy: 1,
//     displayName: "jlllj",
//     timestamp: 1652928063591,
//     review: "hello snnakka bubbb",
//     userRating: rateOptionsData[1],
//   },
// ],