import { useRecoilValue } from "recoil";
import { appProjects } from "../appState/appProject";
import { StyledBitsToolbarButton, StyledBitsToolbarStack } from "../AppStyles";

export function BitsToolbar() {
  const projectInfo = useRecoilValue(appProjects);

  return (
    <StyledBitsToolbarStack direction="row">
      {projectInfo.map((item) => (
        <StyledBitsToolbarButton key={item.projectId} href={`/${item.label}`}>
          {item.label}
        </StyledBitsToolbarButton>
      ))}

      {/* <StyledBitsToolbarButton href={"/shop"}>Shopp</StyledBitsToolbarButton> */}
    </StyledBitsToolbarStack>
  );
}
