import {
  Button,
  CardMedia,
  Drawer,
  Grid,
  Link,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useRecoilState } from "recoil";
import { productCardState } from "../../appState/search";
import { NoShowSmall, ShowSmall } from "../../AppStyles";
import { GalleryImgCard } from "../../bitsCardData/GalleryImgCard";
import { Ratings } from "../../ratings/Ratings";
import {
  GoLeftIconButton,
  GoRightIconButton,
  SmallScrollIconButtons,
  StyledHomeImgGridItem,
} from "../HomeStyles";

export function HomeDrawerImgGrid({ py, pt, options, isFeatured }) {
  const containerRef = useRef();

  const [currentProductState, setProductCardState] =
    useRecoilState(productCardState);

  const onClose = () => setProductCardState(undefined);

  const scrollLeft = (value) => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + value,
      behavior: "smooth",
    });
  };

  console.log("options", options);
  return (
    <>
      <Grid container columns={16} pb={1}>
        <StyledHomeImgGridItem item xs={1} display="flex">
          <NoShowSmall>
            <GoRightIconButton onClick={() => scrollLeft(-200)} />
          </NoShowSmall>
        </StyledHomeImgGridItem>

        <StyledHomeImgGridItem item xs={14}>
          <Box
            ref={containerRef}
            display="flex"
            py={py}
            pt={pt}
            overflow="scroll"
          >
            {isFeatured
              ? options.slice(0, 20).map((item) => (
                  <Box key={item.productId}>
                    <Link
                      href={`/product/${item.productId}`}
                      sx={{ textDecoration: "none" }}
                    >
                      <GalleryImgCard
                        title={item.title}
                        image={item.img}
                        listedBy={item.postedBy}
                      />
                    </Link>
                  </Box>
                ))
              : options.slice(0, 24).map((item) => (
                  <Box key={item.productId}>
                    <Button
                      // {...props}
                      onClick={() => setProductCardState(item)}
                    >
                      <GalleryImgCard
                        title={item.title}
                        image={item.img}
                        // listedBy={item.postedBy}
                      />
                    </Button>
                  </Box>
                ))}
          </Box>

          <ShowSmall>
            <SmallScrollIconButtons
              onRightClick={() => scrollLeft(-200)}
              onLeftClick={() => scrollLeft(200)}
            />
          </ShowSmall>
        </StyledHomeImgGridItem>

        <StyledHomeImgGridItem item xs={1} display="flex">
          <NoShowSmall>
            <GoLeftIconButton onClick={() => scrollLeft(200)} />
          </NoShowSmall>
        </StyledHomeImgGridItem>
      </Grid>

      {currentProductState && (
        <Drawer anchor="right" open={!!currentProductState} onClose={onClose}>
          <Box sx={{ width: 385 }} role="presentation">
            <Box>
              <CardMedia
                component="img"
                image={currentProductState?.img}
                title={currentProductState?.title}
                height={160}
              />

              <ListItemText
                sx={{ px: 2 }}
                // primary={capitalLetterFormatter({ value: product.title })}
                // secondary={capitalLetterFormatter({ value: product.sellerName })}
                primary={currentProductState?.title}
                secondary={
                  <div>
                    <Stack direction="row" gap={2}>
                      <Ratings
                        productIdToUse={currentProductState?.productId}
                      />
                      <Typography alignSelf="flex-end" pb={1}>
                        # of ratings
                      </Typography>
                    </Stack>
                    {currentProductState?.postedBy}
                  </div>
                }
                // secondary={currentProductState?.postedBy}
                primaryTypographyProps={{ fontSize: "large" }}
              />

              {/* <Divider /> */}
              <Box display="flex" justifyContent="center" pt={2}>
                <Button
                  href={`/product/${currentProductState?.productId}`}
                  variant="contained"
                >
                  View Details
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
}
