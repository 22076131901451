import React, { useState, useEffect, useRef } from "react";
import "./ResizableContainer.css";

const ResizeObserverContainer = ({ children, isOpen, onClose }) => {
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(200);
  const [isResizing, setIsResizing] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const currentContainerRef = containerRef.current; 

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        const { width: newWidth, height: newHeight } = entry.contentRect;
        setWidth(newWidth);
        setHeight(newHeight);
      }
    });

  if (currentContainerRef) {
    resizeObserver.observe(currentContainerRef);
  }

  return () => {
    if (currentContainerRef) {
      resizeObserver.unobserve(currentContainerRef);
    }
  };
}, []);

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResize = (e) => {
    if (!isResizing) return;

    const newWidth = e.clientX;
    const newHeight = e.clientY;
    setWidth(newWidth);
    setHeight(newHeight);
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  return (
    <div className={`modal${isOpen ? " open" : ""}`}>
      <div
        className="resizable-container"
        style={{ width, height }}
        onMouseMove={handleResize}
        onMouseUp={handleResizeEnd}
        ref={containerRef}
      >
        <div className="resize-handle" onMouseDown={handleResizeStart}></div>
        {children(width, height)}
        <button className="popup-close" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ResizeObserverContainer;