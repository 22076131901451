import { Card, CardContent, CardMedia, Link, Typography } from "@mui/material";

import { GalleryProductsGrid } from "./HomeGalleryImgCardStyles";

export function GridProductCards({ productInfo }) {
  return (
    <>
      {productInfo.map((item) => (
        <GalleryProductsGrid key={item.productId}>
          <Card sx={{ borderRadius: 0, margin:4  }} elevation={0}>
            <Link href={`/product/${item.productId}`}>
              <CardMedia
                component="img"
                image={item?.img}
                alt={item.productId}
                height={270}
                sx={{ objectFit: "cover" }}
              />
            </Link>

            <CardContent sx={{ minHeight: 45, pb: 3 }}>
              <Link
                href={`product/${item.productId}`}
                sx={{ textDecoration: "none" }}
              >
                <Typography variant="subtitle1">{item.title}</Typography>
                <Typography variant="body2" gutterBottom>
                  {item.postedBy}
                </Typography>
              </Link>
            </CardContent>
            {/* <CardActions sx={{justifyContent:"flex-end"}}>

                <BookmarkCheck productIdToUse={item.productId} />
              <FavoriteCheck productIdToUse={item.productId} />
              {!!item.link ? (
                <IconButton href={item.link}>
                  <ExitToAppIcon />
                </IconButton>
              ) : null}
              
            </CardActions> */}
          </Card>
        </GalleryProductsGrid>
      ))}
    </>
  );
}
