import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct, productItems } from "../../appState/productItems";
import { getUser } from "../../appState/user";
import { ProductMoreFromList } from "../ProductStyles";
import { NoShowSmall } from "../../AppStyles";

export function ProductMoreFrom({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();

  const product = useRecoilValue(getProduct(productId));
  const userBio = useRecoilValue(getUser(product.postedByUserId));

  const products = useRecoilValue(productItems)
    .filter((item) => item.postedBy === product.postedBy)
    .sort(function () {
      return 0.5 - Math.random();
    });
  var n = 5;
  var moreFrom = products.slice(0, n);

  return (
    <Box>
      <NoShowSmall>
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              p: 3,
              maxWidth: 500,
              minWidth: 400,
              alignSelf: "center",
            }}
          >
            <Typography textAlign="center">{`About ${userBio?.displayName}`}</Typography>
            <Typography>{userBio.bio}</Typography>
          </Box>
        </Box>
      </NoShowSmall>

      <ProductMoreFromList options={moreFrom} />
    </Box>
  );
}
