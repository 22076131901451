import { CardMedia, Tooltip } from "@mui/material";
import {
  StyledGalleryCard,
  StyledGalleryCardListedBy,
  StyledGalleryCardTitle,
  StyledGalleryImgCardWrapper,
} from "./HomeGalleryImgCardStyles";

export function GalleryImgCard({ image, title, listedBy }) {
  return (
    <StyledGalleryImgCardWrapper>
      <StyledGalleryCard elevation={0}>
        <CardMedia component="img" image={image} title={title} height={180} />

        <Tooltip title={title}>
          <StyledGalleryCardTitle variant="body2">
            {title}
          </StyledGalleryCardTitle>
        </Tooltip>

        <StyledGalleryCardListedBy variant="body2" gutterBottom>
          {listedBy}
        </StyledGalleryCardListedBy>
      </StyledGalleryCard>
    </StyledGalleryImgCardWrapper>
  );
}
