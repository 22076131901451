import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, OutlinedInput, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { React, useState } from "react";

export function ShittyBox() {
  const [query, setQuery] = useState("");

  // const filteredProducts = (productInfo, query) => {
  //   if (!query) {
  //     return productInfo;
  //   }

  //   return productInfo.filter((product) => {
  //     const productTitle = product.title.toLowerCase();
  //     return productTitle.includes(query);
  //   });
  // };

  // const filteredProducts = allProjects.filter((productInfo) => {
  //   console.log("s", productInfo);
  //   return (
  //     productInfo?.title?.includes(query) ||
  //     //   .toLowerCase()
  //     //   .includes(searchField.toLowerCase()) ||
  //     productInfo?.postedBy?.includes(query)
  //     //   .toLowerCase()
  //     //   .includes(searchField.toLowerCase())
  //   );
  // });

  // const handleChange = (e) => {
  //   setQuery(e.target.value);
  //   if (e.target.value === "") {
  //     setSearchShow(false);
  //   } else {
  //     setSearchShow(true);
  //   }
  // };

  return (
    <Stack direction="row" gap={3} pr={2}>
      <OutlinedInput
        sx={{ borderColor: "primary" }}
        startAdornment={
          query ? (
            <IconButton onClick={() => setQuery("")}>
              <CloseIcon color="secondary" />
            </IconButton>
          ) : (
            <SearchIcon color="primary" />
          )
        }
        type="text"
        size="small"
        value={query}
        onInput={(e) => setQuery(e.target.value)}
        // onChange={handleChange}
        placeholder="Search Site"
        onChange={(e, item) => {
          setQuery(e.target.value);
        }}
      >
        search
      </OutlinedInput>

      <Box alignSelf="center">
        <Button
          disabled={!query}
          href={`/search/${query}`}
          type="submit"
          variant="contained"
        >
          Search
        </Button>
      </Box>

      {/* {searchShow && (
          <Scroll>
            <SearchList filteredProducts={filteredProducts} />
          </Scroll>
        )} */}
    </Stack>
  );
}
