import {
  ACCESSORIES,
  ANIMALS,
  FLOWER,
  INTERIOR,
  MANDALA,
  PETS,
  WEARABLE,
  OTHER,
} from "./multiType";

//Pattern values
export const KNIT = "Knit";
export const CROCHET = "Crochet";
export const EMBROIDERY = "Embroidery";
export const QUILTING = "Quilting";
export const SEWING = "Sewing";

// is knit and is crochet and is embroidery
// and is sew
export const AMIGURUMI = "Amigurumi";
export const APPLIQUÉS = "Appliqués";
export const BAGS = "Bags & Purses";
export const BABY = "Baby";
export const BLANKETS = "Afghans & Blankets";
export const PILLOWS = "Pillows";
export const BASKETS = "Baskets";
export const SHOES = "Slippers & Booties";
export const WINTER = "Hats, Gloves & Neckwear";
export const HASBEADING = "Beading";
export const HANDEMB = "Hand Embroidery";
export const MACHINEEMB = "Machine Embroidery";
export const GRANNY = "Granny Squares";
export const TOYS = "Toys";

export const IN_ALL_PATTERN_TYPES_VALUE = [
  ACCESSORIES,
  BLANKETS,
  BAGS,
  INTERIOR,
  PILLOWS,
  WEARABLE,
  WINTER,
  PETS,
  BABY,
];

export const IS_YARNING_VALUES = [
  APPLIQUÉS,
  BASKETS,
  APPLIQUÉS,
  SHOES,
  WINTER,
  ANIMALS,
  TOYS,
  FLOWER,
  MANDALA,
];

export const IS_CROCHET_VALUES = [
  GRANNY,
  AMIGURUMI,
  ...IN_ALL_PATTERN_TYPES_VALUE,
  ...IS_YARNING_VALUES,
];

export const IS_KNIT_VALUES = [
  ...IN_ALL_PATTERN_TYPES_VALUE,
  ...IS_YARNING_VALUES,
];

export const IS_QUILT_VALUES = [...IN_ALL_PATTERN_TYPES_VALUE];

export const IS_EMBROIDERY_VALUES = [
  HASBEADING,
  HANDEMB,
  MACHINEEMB,
  ...IN_ALL_PATTERN_TYPES_VALUE,
];

export const IS_SEWING_VALUES = [
  ...IN_ALL_PATTERN_TYPES_VALUE,
  ...IS_YARNING_VALUES,
];

export const ALL_PATTERN_VALUES = [
  //0
  KNIT,
  //1
  CROCHET,
  //2
  EMBROIDERY,
  // QUILTING,
  //3
  SEWING,
  //4
  OTHER,
];

export const PATTERN_VALUE_TYPES = [
  //0
  ACCESSORIES,
  //1
  BLANKETS,
  //2
  BAGS,
  //3
  INTERIOR,
  //4
  PILLOWS,
  //5
  WEARABLE,
  //6
  WINTER,
  //7
  PETS,
  //8
  BABY,
  //9
  BASKETS,
  //10
  ANIMALS,
  //11
  TOYS,
  //12
  FLOWER,
  //13
  MANDALA,
  //14
  AMIGURUMI,
  //15
  OTHER,
];

export const ALL_PATTERN_VALUE_TYPES = [
  ...IS_CROCHET_VALUES,
  ...IS_KNIT_VALUES,
  ...IS_QUILT_VALUES,
  ...IS_EMBROIDERY_VALUES,
  ...IS_SEWING_VALUES,
];
