import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import { useState } from "react";
import {
  NoShowXSmall,
  ShowXSmall,
  StyledCenterStack,
  StyledDrawerLogoText,
  StyledSearchDrawerStack,
} from "../AppStyles";
import { ShittyBox } from "../search/AppBarSearch";
import { AppBarDrawers } from "./AppBarDrawers";

export function AppBarSearchButton() {
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <AppBarDrawers
      selectedValue={openSearch}
      setValue={setOpenSearch}
      icon={<SearchIcon />}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShowXSmall>
          <StyledCenterStack direction="row" gap={1}>
            {/* <Avatar> */}
            <img src="/images/lovelogo.png" alt="us" height={25} width={25} />
            <StyledDrawerLogoText>Lovebug Bits</StyledDrawerLogoText>
          </StyledCenterStack>
        </ShowXSmall>
      </div>

      <StyledSearchDrawerStack direction="row">
        <NoShowXSmall>
          <StyledCenterStack direction="row" gap={1}>
            {/* <Avatar> */}
            <img src="/images/lovelogo.png" alt="us" height={25} width={25} />
            {/* </Avatar> */}
            <StyledDrawerLogoText>Lovebug Bits</StyledDrawerLogoText>
          </StyledCenterStack>
        </NoShowXSmall>

        <ShittyBox />

        <div>
          <IconButton onClick={() => setOpenSearch(false)}>
            <ArrowDropUpIcon fontSize="larger" />
          </IconButton>
        </div>
      </StyledSearchDrawerStack>
    </AppBarDrawers>
  );
}
