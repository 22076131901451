import { Box, Divider, List, ListSubheader } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../../appState/productItems";
import { ProjectCollapse } from "../../checkboxes/ProjectCollapse";
import { ProductDescription } from "../productDetails/ProductDescription";
import { ProductHowTo } from "../productDetails/ProductHowTo";
import { ProductMoreFrom } from "../productDetails/ProductMoreFrom";
import { ProductBulletList } from "../ProductStyles";
import { ProductCollapseDetails } from "./ProductCollapseDetails";
import { ShowSmall } from "../../AppStyles";

export function ProductDetailsCollapse({ productIdToUse }) {
  const { productId = productIdToUse } = useParams();
  const product = useRecoilValue(getProduct(productId));

  const answers = product.qAnswers?.map((item) => item?.answers || []);

  console.log("lli", product, answers);

  return (
    <>
      <ProjectCollapse label="About">
        <ProductCollapseDetails
          listItemInfo={<ProductDescription productIdToUse={productId} />}
        />
      </ProjectCollapse>

      <ProjectCollapse label="You will need">
        <ProductCollapseDetails
          listItemInfo={
            <Box>
              <List disablePadding>
                {product?.materials.map((item) => (
                  <div key={item}>
                    <ListSubheader>{item.section}</ListSubheader>
                    {item.materialInfo.map((item) => (
                      <ProductBulletList item={item} />
                    ))}
                  </div>
                ))}
              </List>

              {/* <Stack direction="column">
                <div>{product.categoryId}</div>

                {product?.skillTypeId}
                {product?.servings}
                {product?.estTimeComplete}
              </Stack> */}
              <ShowSmall>
                <Divider sx={{ mb: 1 }} />
              </ShowSmall>
            </Box>
          }
        />
      </ProjectCollapse>

      <ProjectCollapse label="How To">
        <ProductCollapseDetails
          listItemInfo={<ProductHowTo productIdToUse={productId} />}
        />
      </ProjectCollapse>

      <ProjectCollapse label={`More from ${product.postedBy}`}>
        <ProductCollapseDetails
          listItemInfo={<ProductMoreFrom productIdToUse={productId} />}
        />
      </ProjectCollapse>

      {/* <ProjectCollapse label="Q & A">
        <ProductCollapseDetails
          listItemInfo={
            <CommentsList
              title={"Questions"}
              button={<QuestionButton key={product} productIdToUse={product} />}
            >
              <QuestionDisplayInfo options={product?.qAnswers}  />
            </CommentsList>
          }
        />
      </ProjectCollapse> */}
    </>
  );
}
