import { Box, Grid, Stack } from "@mui/material";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProduct } from "../appState/productItems";
import { BitsAppBar } from "../bitsAppBar/AppBar";
import { ProductBreadcrumb } from "../breadcrumb/ProductBreadcrumb";

import { NoShowSmall, ShowSmall } from "../AppStyles";
import { Footer } from "../bitsFooter/Footer";
import { ProductView } from "./BitsProductView";
import { ProductDetailsCollapse } from "./productDetailsContainer/BitsProductDetailsCollapse";
import { ProductTabDetails } from "./productDetailsContainer/ProductTabDetails";
import { StyledDetailsBorder } from "./ProductStyles";

export function ProductLayout() {
  const { productId } = useParams();
  const product = useRecoilValue(getProduct(productId));

  return (
    <>
      <BitsAppBar />
      <Box mt={8}>
        <ProductBreadcrumb />
      </Box>
      <ProductView />

      <Grid>
        <Stack gap={4} pt={5}>
          {/* <StyledProductDetailsDivider variant="middle">
              {/* ABOUT THIS ITEM 
              DETAILS
            </StyledProductDetailsDivider> */}
          <StyledDetailsBorder>
            <ShowSmall mt={6}>
              <ProductDetailsCollapse productIdToUse={product} />
            </ShowSmall>

            <NoShowSmall>
              <ProductTabDetails />
            </NoShowSmall>
          </StyledDetailsBorder>

          {/* <StyledDetailsBorder> */}

          {/* <CommentsList
            isReview
            title={"Reviews"}
            button={<ReviewButton productIdToUse={product} />}
          >
            <ReviewDisplayInfo options={product?.reviews} />
          </CommentsList> */}

          {/* </StyledDetailsBorder> */}
        </Stack>
      </Grid>

      <Footer />
    </>
  );
}
