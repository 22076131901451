import { CardMedia, Grid, Stack, Typography } from "@mui/material";
import { StyledProductViewImgBox } from "../bitsProductPage/ProductStyles";

export function NoProductView() {
  return (
    <Grid container item xs={12} sm={12} md={12}>
      <StyledProductViewImgBox>
        <Stack alignItems="center">
          <CardMedia
            component="img"
            image="/images/tag.png"
            alt="products"
            sx={{ height: 150, objectFit: "contain" }}
          />
          <Typography>No products to show</Typography>
        </Stack>
      </StyledProductViewImgBox>
    </Grid>
  );
}
