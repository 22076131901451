import { List, ListItem, ListItemText } from "@mui/material";

export function ProductCollapseDetails({ listItemInfo }) {
  return (
    <List component="div" disablePadding>
      <ListItem disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary={listItemInfo}
            primaryTypographyProps={{
              color: "primary",
              variant: "button",
            }}
          />
        </ListItem>
      </ListItem>
    </List>
  );
}
