import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProject } from "../appState/appProject";
import { getFilteredProductsForProject } from "../appState/productItems";
import { BitsAppBar } from "../bitsAppBar/AppBar";
import { GridProductCards } from "../bitsCardData/GridProductCards";
import { BitsProjectFilter } from "./BitsProjectFilters";

import { useState } from "react";
import { Footer } from "../bitsFooter/Footer";
import { BitsToolbar } from "../bitsToolbars/BitsToolbar";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import {
  StyledBreadcrumbStack,
  StyledChipStack,
  StyledViewMoreBox,
} from "../bitsProductPage/ProductStyles";
import { NoProductView } from "./NoProducts";
import {
  ChipCategoryFilter,
  ChipCategoryTypeFilter,
} from "../chipCategoryFilters/ChipCategoryFilter";

const PAGE_SIZE = 28;

export function ProjectLayout() {
  const { projectId } = useParams();

  const project = useRecoilValue(getProject(projectId));
  const products = useRecoilValue(getFilteredProductsForProject(projectId));

  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const productView = products?.slice(0, pageSize);

  const hideShowMore = (amountToChangeBy) => {
    setPageSize((current) =>
      Math.min(
        Math.max((current += amountToChangeBy), PAGE_SIZE),
        products.length
      )
    );
  };

  const canViewMore = productView.length < products.length;

  console.log("liik", products);

  return (
    <>
      <BitsAppBar />
      <Box mt={9}>
        <StyledBreadcrumbStack direction="row">
          <Breadcrumb />

          <Box alignSelf="center" sx={{ display: "none" }}>
            <Button variant="contained" href={`/shop/${projectId}`}>
              Shop {projectId}
            </Button>
          </Box>
        </StyledBreadcrumbStack>
      </Box>

      <Divider />

      <CardMedia
        component="img"
        image={project.src}
        title={project.projectId}
        sx={{ objectFit: "cover", maxHeight: 500 }}
      />
      <BitsToolbar />

      <Grid container spacing={1} columns={16} justifyContent="center">
        <Grid item xs={16} sm={16} md={4}>
          <BitsProjectFilter />
        </Grid>
        <Grid item xs={16} sm={16} md={12}>
          <StyledChipStack direction="row">
            <Stack sx={{ flexWrap: "wrap", pl: 2, pt: 2 }}>
              <ChipCategoryFilter projectIdToUse={project.projectId} />

              <ChipCategoryTypeFilter projectIdToUse={project.projectId} />
            </Stack>

            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
              <Typography textAlign="flex-end" alignSelf="flex-end" pr={2}>
                {productView?.length} of {products.length} results
              </Typography>
            </Box>
          </StyledChipStack>

          <Divider sx={{ mb: 2 }} />

          {!products.length ? (
            <NoProductView />
          ) : (
            <>
              <Grid container columns={12}  >
                <GridProductCards productInfo={productView} key={productView} />
              </Grid>

              <StyledViewMoreBox>
                <Button
                  disabled={!canViewMore}
                  onClick={() => hideShowMore(PAGE_SIZE)}
                  sx={{ fontSize: "medium", my: 2 }}
                  // variant= "contained"
                >
                  view more
                </Button>
              </StyledViewMoreBox>
            </>
          )}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
