import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { getProduct } from "../../appState/productItems";

import { ProductDescription } from "../productDetails/ProductDescription";
import { ProductHowTo } from "../productDetails/ProductHowTo";
import { ProductMaterialsList } from "../productDetails/ProductMaterialsList";
import { ProductMoreFrom } from "../productDetails/ProductMoreFrom";

export function ProductTabDetails() {
  const { productId } = useParams();

  const product = useRecoilValue(getProduct(productId));

  const [value, setValue] = useState("tab1");

  const handleChange = (event, newValue) => {
    setValue(String(newValue));
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="About" value="tab1" />

            <Tab label="You will need" value="tab2" />
            <Tab label="How To" value="tab3" />
            <Tab label={`More from ${product.postedBy}`} value="tab4" />
            {/* <Tab label="Q & A" value="tab5" /> */}
          </TabList>

          <Divider />
        </Box>
        <TabPanel value="tab1">
          <ProductDescription productIdToUse={productId} />
        </TabPanel>
        <TabPanel value="tab2">
          <ProductMaterialsList productIdToUse={productId} />
          <Box>
            {/* <Stack direction="column">
              <div>{product.categoryId}</div>

              {product?.skillTypeId}
              {product?.servings}
              {product?.estTimeComplete}
            </Stack> */}
          </Box>
        </TabPanel>
        <TabPanel value="tab3">
          <ProductHowTo productIdToUse={productId} />
        </TabPanel>
        <TabPanel value="tab4">
          <ProductMoreFrom productIdToUse={productId} />
        </TabPanel>
        {/* <TabPanel value="tab5">

          <Divider
        variant="middle"
        textAlign="center"
        sx={{ fontWeight: "bold", fontSize: "larger", py: 2 }}
      >
        Questions
      </Divider>
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap={"wrap"}
          >
             
            <CommentsList
              // title={"Questions"}
              button={<QuestionButton key={product} productIdToUse={product} />}
            >
              <QuestionDisplayInfo options={product?.qAnswers} />
            </CommentsList>
          </Stack>
        </TabPanel> */}
      </TabContext>
    </Box>
  );
}
